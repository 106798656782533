import { statisticPageController } from "service/shared/singletones/statisticPageService/statisticPage.controller";
import { statisticPageState } from "service/shared/singletones/statisticPageService/statisticPage.state";

class Service {
  public readonly state = statisticPageState;

  public readonly controller = statisticPageController;
}

export const statisticPageService = new Service();
