import { Row } from "components/Row/Row.component";
import { createComponent } from "utils/libExtend/createComponent";

import { PaymentRowProps } from "./PaymentRow.props";

export const PaymentRow = createComponent<PaymentRowProps>("PaymentRow", (props) => {
  const { title, value } = props;

  return <Row title={title} value={value} type="big" />;
});
