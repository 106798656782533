import { css, setFlexDFFDC, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SInvoiceDetailsPageContentTable = styled.div<Props>`
  ${setFlexDFFDC};
  ${isArabicLang};
`;
