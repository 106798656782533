import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { createComponent } from "../utils";
import { SSwitchLangApp, SSwitchLangAppBtn } from "./AppStyled/SwitchLangApp.styled";

interface Native {
  nativeName: "Русский" | "English" | "Arabic";
}
interface Langs {
  ru: Native;
  en: Native;
  ar: Native;
}
const langs: Langs = {
  ru: { nativeName: "Русский" },
  en: { nativeName: "English" },
  ar: { nativeName: "Arabic" },
};

const langsKeys: Array<keyof typeof langs> = ["ru", "en", "ar"];

export const AppSwitchLang = createComponent("AppSwitchLang", () => {
  const isFinOn = process.env.REACT_APP_FIN === "fin";
  const { i18n } = useTranslation();

  if (!isFinOn) return null;

  const handlerChangeLang = (lng: "en" | "ru" | "ar"): void => {
    i18n.changeLanguage(lng);
  };

  const renderLangs = (lng: keyof typeof langs): ReactNode => {
    return (
      <SSwitchLangAppBtn type="submit" key={lng} onClick={(): void => handlerChangeLang(lng)} disabled={i18n.resolvedLanguage === lng}>
        {langs[lng].nativeName}
      </SSwitchLangAppBtn>
    );
  };

  return <SSwitchLangApp>{langsKeys.map(renderLangs)}</SSwitchLangApp>;
});
