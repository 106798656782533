import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const cardHolderFilterModel = new FilterModel({
  id: 23,
  type: "text",
  title: t("cardHolderFilterModel.title"),
  name: "CARD_HOLDER",
  options: {},
  langPath: "cardHolderFilterModel.title",
});
