import { UsersChangeRowController } from "service/shared/others/UsersChangeRowService/UsersChangeRow.controller";
import { UsersChangeRowState } from "service/shared/others/UsersChangeRowService/UsersChangeRow.state";

type InputType = "phoneNumber" | "email" | "number" | "common";

type UsersChangeRowServiceParams = {
  type?: InputType;
};

export class UsersChangeRowService {
  public readonly state: UsersChangeRowState;

  public readonly controller: UsersChangeRowController;

  public constructor(initialValue: string, updateUserCallBack: () => void, params?: UsersChangeRowServiceParams) {
    this.state = new UsersChangeRowState(initialValue, updateUserCallBack, params);
    this.controller = new UsersChangeRowController(this.state, params);
  }
}
