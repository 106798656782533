import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { statisticPageService } from "service/shared/singletones/statisticPageService/statisticPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SStatisticErrorMessage } from "./StatisticErrorMessageStyled/SStatisticErrorMessage.styled";
import { SStatisticErrorMessageContainer } from "./StatisticErrorMessageStyled/SStatisticErrorMessageContainer.styled";
import { SStatisticErrorMessageTitle } from "./StatisticErrorMessageStyled/SStatisticErrorMessageTitle.styled";

export const StatisticErrorMessage = createComponent("StatisticErrorMessage", () => {
  const { t } = useTranslation();
  const showStatisticErrorMessage = useDefaultObservableBoolean(statisticPageService.state.showStatisticErrorMessage);

  if (!showStatisticErrorMessage) {
    return null;
  }

  return (
    <SStatisticErrorMessage>
      <SStatisticErrorMessageContainer>
        <SStatisticErrorMessageTitle>{t("statisticPage.errorMessage")}</SStatisticErrorMessageTitle>
      </SStatisticErrorMessageContainer>
    </SStatisticErrorMessage>
  );
});
