import { useFiltersContext } from "context/Filter.context";
import { FilterCheckboxDataModel } from "model/FilterCheckboxData.model";
import { FilterFetchedMultipleSelectDataModel } from "model/FilterFetchedMultipleSelectData.model";
import { FilterFetchedSelectDataModel } from "model/FilterFetchedSelectData.model";
import { FilterInputDataModel } from "model/FilterInputData.model";
import { FilterRangeDataModel } from "model/FilterRangeData.model";
import { FilterSelectDataModel } from "model/FilterSelectData.model";
import { useDefaultObservableFilterModelTypeArray } from "observables/FilterModelTypeArrayObservable";
import { FilterModelType } from "types/business/FilterModelType";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { CheckboxFilter } from "../CheckboxFilter/CheckboxFilter.component";
import { FetchedFilterMultipleSelect } from "../FetchedFilterMultipleSelect/FetchedFilterMultipleSelect.component";
import { FetchedFilterSelect } from "../FetchedFilterSelect/FetchedFilterSelect.component";
import { FilterInput } from "../FilterInput/FilterInput.component";
import { FilterSelect } from "../FilterSelect/FilterSelect.component";
import { RangeInput } from "../RangeInput/RangeInput.component";

export const ActiveFilterList = createComponent("ActiveFilterList", () => {
  const { service } = useFiltersContext();

  const activeFilterList = useDefaultObservableFilterModelTypeArray(service.state.activeFilterList);

  const renderFilter: RenderFunctionType<FilterModelType> = (filterService, index: number) => {
    if (filterService.filterModel.type === "text") {
      return <FilterInput service={filterService as FilterInputDataModel} key={filterService.filterModel.id} />;
    }
    if (filterService.filterModel.type === "range") {
      return <RangeInput service={filterService as FilterRangeDataModel} key={filterService.filterModel.id} />;
    }
    if (filterService.filterModel.type === "select") {
      return <FilterSelect service={filterService as FilterSelectDataModel} key={filterService.filterModel.id} />;
    }
    if (filterService.filterModel.type === "fetched-select") {
      return <FetchedFilterSelect service={filterService as FilterFetchedSelectDataModel} key={filterService.filterModel.id} />;
    }
    if (filterService.filterModel.type === "checkbox") {
      return <CheckboxFilter service={filterService as FilterCheckboxDataModel} key={filterService.filterModel.id} />;
    }
    if (filterService.filterModel.type === "fetched-multiple-select") {
      return <FetchedFilterMultipleSelect service={filterService as FilterFetchedMultipleSelectDataModel} key={filterService.filterModel.id} />;
    }
    return null;
  };

  return <>{activeFilterList.map(renderFilter)}</>;
});
