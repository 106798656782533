import { Link } from "components";
import { setColor_4183c4, setFontSize_20px, setFontWeight400, setTextRightArabic, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SDetailsLayoutHeader = styled(Link)<Props>`
  text-decoration: none;

  ${setFontSize_20px};
  ${setFontWeight400};
  ${setColor_4183c4};
  ${setTextRightArabic()};
`;
