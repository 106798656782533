import { css, setFlexDFAIC, setProps, styled } from "theme";

interface SFilterSelectContainerProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterSelectContainerProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SFilterSelectContainer = styled.div<SFilterSelectContainerProps>`
  ${setFlexDFAIC};
  ${isArabicLang};
`;
