import { css, setProps, styled } from "theme";

interface SFetchedFilterMultipleSelectClearProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFetchedFilterMultipleSelectClearProps>(({ isArabic }) => isArabic)(css`
  left: 4px;
`);

const isNotArabicLang = setProps<SFetchedFilterMultipleSelectClearProps>(({ isArabic }) => !isArabic)(css`
  right: 4px;
`);

export const SFetchedFilterMultipleSelectClear = styled.button<SFetchedFilterMultipleSelectClearProps>`
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  ${isNotArabicLang};
  ${isArabicLang};
`;
