import { css, setColor_25476d, setFontSize_13px, setFontWeight600, setProps, styled } from "theme";

interface SCheckboxFilterTitleProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SCheckboxFilterTitleProps>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SCheckboxFilterTitle = styled.div<SCheckboxFilterTitleProps>`
  margin-bottom: 8px;
  margin-top: 8px;

  ${isArabicLang};
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_25476d};
`;
