import { setFlexDFJCSB, setRowReverse, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SCompareYear = styled.div<Props>`
  width: 400px;

  ${setFlexDFJCSB};
  ${setRowReverse()};
`;
