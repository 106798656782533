import { Row } from "components/Row/Row.component";
import { useDefaultObservableRewardTypeNull } from "observables/RewardTypeNullObservable";
import { useTranslation } from "react-i18next";
import { rewardDetailsPageService } from "service/shared/singletones/rewardDetailsPageService/rewardDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SRewardDetailsInformationContent } from "./RewardDetailsInformationContentStyled/SRewardDetailsInformationContent.styled";

export const RewardDetailsInformationContent = createComponent("RewardDetailsInformationContent", () => {
  const { t } = useTranslation();
  const operationAggregate = useDefaultObservableRewardTypeNull(rewardDetailsPageService.operationAggregate);

  if (!operationAggregate) {
    return <SRewardDetailsInformationContent>{t("rewardDetailsPage.informationContentNotFound")}</SRewardDetailsInformationContent>;
  }

  return (
    <>
      <Row title={t("rewardDetailsPage.informationContentIncomeAmountTitle")} value={operationAggregate.incomeAmount} />
      <Row title={t("rewardDetailsPage.informationContentFeeAmountTitle")} value={operationAggregate.feeAmount} />
      <Row title={t("rewardDetailsPage.informationContentSettlementAmountTitle")} value={operationAggregate.settlementAmount} />
    </>
  );
});
