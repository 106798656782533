import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { auditPageService } from "service/shared/singletones/auditPageService/auditPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SAuditTable } from "./AuditTableStyled/SAuditTable.styled";
import { SAuditTableContainer } from "./AuditTableStyled/SAuditTableContainer.styled";

export const AuditTable = createComponent("AuditTable", () => {
  const loaded = useDefaultObservableBoolean(auditPageService.state.loaded);
  const tableService = useDefaultObservableTableService(auditPageService.tableService);

  return (
    <SAuditTable>
      <SAuditTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SAuditTableContainer>
    </SAuditTable>
  );
});
