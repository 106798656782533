import { t } from "i18n";
import { ToastService } from "service/shared/others/ToastService/Toast.service";
import { toastContainerState } from "service/shared/singletones/toastContainerService/toastContainer.state";

class Controller {
  public readonly state = toastContainerState;

  public readonly createErrorToast = (title?: string | null): void => {
    this.state.toasts.value.push(
      new ToastService(this.state.nextId, title || t("toastContainerService.errorTitle"), this.deleteToast, {
        type: "error",
      })
    );
    this.state.nextId += 1;
    this.state.toasts.next([...this.state.toasts.value]);
  };

  public readonly createInfoToast = (title?: string | null): void => {
    this.state.toasts.value.push(
      new ToastService(this.state.nextId, title || t("toastContainerService.noInfoTitle"), this.deleteToast, {
        type: "info",
      })
    );
    this.state.nextId += 1;
    this.state.toasts.next([...this.state.toasts.value]);
  };

  public readonly createSuccessToast = (title?: string | null): void => {
    this.state.toasts.value.push(
      new ToastService(this.state.nextId, title || t("toastContainerService.successTitle"), this.deleteToast, {
        type: "success",
      })
    );
    this.state.nextId += 1;
    this.state.toasts.next([...this.state.toasts.value]);
  };

  private readonly deleteToast = (service: ToastService): void => {
    const toast = this.state.toasts.value.filter((toastService: ToastService) => toastService !== service);
    this.state.toasts.next(toast);
  };
}

export const toastContainerController = new Controller();
