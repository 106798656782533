import { styled } from "theme/default/styled";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const SOpenUserFormButton = styled.button`
  margin-left: auto;
  gap: 8px;

  ${setFlexDF};
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_0083f5};
`;
