import { groupTableBodyState } from "service/shared/singletones/groupTableBodyService/groupTableBody.state";
import { ConfigModel } from "types/business/ConfigModel";
import { DragDivType } from "types/libExtend/DragDivType";

class Controller {
  public readonly state = groupTableBodyState;

  public readonly onDrag = (event: DragDivType, config: ConfigModel): void => {
    const { clientX } = event;

    if (config.lastWidth) {
      if (config.initialClientX) {
        if (clientX && config.width && config.initialWidth && config.lastWidth) {
          const nextWidth = config.lastWidth + clientX - config.initialClientX;
          if (config.initialWidth < nextWidth) {
            config.width = nextWidth;
            this.state.configList.next([...this.state.configList.value]);
          }
        }
      } else {
        config.initialClientX = clientX;
        this.state.configList.next([...this.state.configList.value]);
      }
    } else if (config.width) {
      config.lastWidth = config.width;
      this.state.configList.next([...this.state.configList.value]);
    }
  };

  public readonly onDragEnd = (event: DragDivType, config: ConfigModel): void => {
    delete config.initialClientX;
    if (config.width) {
      config.lastWidth = config.width;
    }
  };
}

export const groupTableBodyController = new Controller();
