import { SvgImage } from "components/SvgImage/SvgImage.component";
import { RolesSelectOptionModel } from "model/RolesSelectOption.model";
import { useTranslation } from "react-i18next";
import { createUserRolesSelectFormService } from "service/shared/singletones/createUserRolesSelectFormService/createUserRolesSelectForm.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";

import { RolesSelectOptionProps } from "./RolesSelectOption.props";
import { SRolesSelectOption } from "./RolesSelectOptionStyled/SRolesSelectOption.styled";
import { SRolesSelectOptionCheckBox } from "./RolesSelectOptionStyled/SRolesSelectOptionCheckBox.styled";
import { SRolesSelectOptionDescription } from "./RolesSelectOptionStyled/SRolesSelectOptionDescription.styled";
import { SRolesSelectOptionTitle } from "./RolesSelectOptionStyled/SRolesSelectOptionTitle.styled";

export const RolesSelectOption = createComponent<RolesSelectOptionProps>("RolesSelectOption", (props) => {
  const { t } = useTranslation();
  const { rolesSelectOptionModel, isFirst } = props;

  const selectedOptions = useDefaultObservable<RolesSelectOptionModel[]>(createUserRolesSelectFormService.state.selectedOptions);

  const active = !!selectedOptions.find((element) => element.title === rolesSelectOptionModel.title);

  return (
    <SRolesSelectOption isFirst={isFirst} onClick={createUserRolesSelectFormService.controller.onClickOption(rolesSelectOptionModel)}>
      <SRolesSelectOptionTitle>{rolesSelectOptionModel.title}</SRolesSelectOptionTitle>
      <SRolesSelectOptionDescription>
        {rolesSelectOptionModel?.langPath ? t(rolesSelectOptionModel.langPath) : rolesSelectOptionModel.description}
      </SRolesSelectOptionDescription>

      <SRolesSelectOptionCheckBox>{active && <SvgImage name="Check" />}</SRolesSelectOptionCheckBox>
    </SRolesSelectOption>
  );
});
