import { FiveNewNineType } from "./FiveNewNineType";
import { StatisticValue } from "./StatisticValue";

export const getStatisticRatioValue = (statistic: StatisticValue[]): FiveNewNineType[] => {
  const newStatistic: FiveNewNineType[] = [];

  for (let index = 0; index < statistic.length; index++) {
    const statisticValue = statistic[index];

    const successQuantity = +statisticValue.successQuantity;
    const failQuantity = +statisticValue.failQuantity;

    newStatistic.push({
      datePart: statisticValue.datePart,
      successQuantity,
      failQuantity,
    });
  }

  return newStatistic;
};
