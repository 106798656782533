import { combineLatest, map } from "packages/rxjs";
import { BehaviorSubject } from "types/libReplace/BehaviorSubject";
import { convertObservableToBehaviorSubject } from "utils/libExtend/convertObservableToBehaviorSubject";

export const setCombinedBehaviorSubject = <HandlerReturnType>(
  handler: (...params: any[]) => HandlerReturnType,
  ...subjects: BehaviorSubject<any>[]
): BehaviorSubject<HandlerReturnType> => {
  return convertObservableToBehaviorSubject(
    combineLatest(subjects).pipe(map((params) => handler(...params))),
    handler(...subjects.map((subject) => subject.value))
  );
};
