import { styled } from "theme/default/styled";
import { color_rgba_34_36_38_15 } from "theme/setter/opacityColorsValues/color_rgba_34_36_38_15";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setColor_rgba_0_0_0_87 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_87";

export const SControlsSelectSelect = styled.select`
  position: relative;
  width: 60px;
  padding: 4px 8px 4px 4px;
  background-color: transparent;

  border: 1px solid ${color_rgba_34_36_38_15};

  ${setColor_rgba_0_0_0_87};
  ${setBorderRadius_4px};
`;
