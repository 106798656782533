import { css, setFlexDF, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  margin-left: 16px;
  flex-direction: row-reverse;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  margin-left: 16px;
`);

export const STerminalsFiltersContainer = styled.div<Props>`
  padding: 16px 0;
  gap: 32px;

  ${setFlexDF};
  ${isNotArabicLang};
  ${isArabicLang};
`;
