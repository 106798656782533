/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const ContentCopy = createIcon("ContentCopy", (props) => {
  const { width = 24, height = 24, color = "#0083f5" } = props;

  return (
    <svg height={height} viewBox="0 0 24 24" width={width} fill={color}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
    </svg>
  );
});
