import { styled } from "theme/default/styled";

export const SHeaderCellDraggablePointer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  width: 10px;
  cursor: col-resize;
`;
