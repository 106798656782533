import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const cardNumberFilterModel = new FilterModel({
  id: 22,
  type: "text",
  title: t("cardNumberFilterModel.title"),
  name: "PAN",
  options: {},
  langPath: "cardNumberFilterModel.title",
});
