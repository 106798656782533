import { ControlCellModel } from "model/ControlCell.model";
import { FiltersNavigationState } from "service/shared/others/FiltersNavigationService/FiltersNavigation.state";

export class FiltersNavigationController {
  public readonly state: FiltersNavigationState;

  public constructor(state: FiltersNavigationState) {
    this.state = state;
  }

  public readonly switchCell = (cell: ControlCellModel): void => {
    this.state.currentPage.next(cell.id);
  };

  public readonly onClickDoubleLeftArrowIcon = (): void => {
    this.state.currentPage.next(this.state.currentPage.value - 10);
  };

  public readonly onClickLeftArrowIcon = (): void => {
    this.state.currentPage.next(this.state.currentPage.value - 1);
  };

  public readonly onClickRightArrowIcon = (): void => {
    this.state.currentPage.next(this.state.currentPage.value + 1);
  };

  public readonly onClickDoubleRightArrowIcon = (): void => {
    this.state.currentPage.next(this.state.currentPage.value + 10);
  };

  public readonly onChangeArabic = (value: boolean): void => {
    this.state.isArabic.next(value);
  };
}
