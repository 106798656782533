import { styled } from "theme/default/styled";
import { setColor_00346e } from "theme/setter/setColorsValues/setColor_00346e";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";

export const SGroupUserCellTitle = styled.div`
  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_00346e};
`;
