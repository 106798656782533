import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { PaymentsDetailsRefundCellProps } from "detailsCell/PaymentsDetailsRefundCell/PaymentsDetailsRefundCell.props";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";
import { useParams } from "utils/libReplace/useParams";

export const PaymentsDetailsRefundCell = createComponent<PaymentsDetailsRefundCellProps>("PaymentsDetailsRefundCell", (props) => {
  const { cellData } = props;

  const params = useParams<{ paymentId: string }>();

  const to = replacePath(routerPaths.paymentRefundDetails, {
    refundId: cellData.refundId,
    paymentId: params.paymentId,
  });

  return <DetailsCell to={to} />;
});
