import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";

export const SCreateArticleEmailInput = styled.input`
  padding: 4px 8px;
  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};
`;
