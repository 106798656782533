import { SvgImage } from "components/SvgImage/SvgImage.component";
import { createComponent } from "utils/libExtend/createComponent";

import { SwitchedRowProps } from "./SwitchedRow.props";
import { SSwitchedRow } from "./SwitchedRowStyled/SSwitchedRow.styled";
import { SSwitchedRowTitle } from "./SwitchedRowStyled/SSwitchedRowTitle.styled";
import { SSwitchedRowValue } from "./SwitchedRowStyled/SSwitchedRowValue.styled";

export type RowType = "medium" | "big";

export const SwitchedRow = createComponent<SwitchedRowProps>("SwitchedRow", (props) => {
  const { title, value, type = "medium" } = props;

  return (
    <SSwitchedRow isMedium={type === "medium"} isBig={type === "big"}>
      <SSwitchedRowTitle>{title}</SSwitchedRowTitle>
      <SSwitchedRowValue>
        <SvgImage name={value ? "Check" : "Close"} />
      </SSwitchedRowValue>
    </SSwitchedRow>
  );
});
