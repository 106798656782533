import { ChartValue } from "./ChartValue";

export const getCountOfSystem = (statistic: ChartValue[], title: string): number => {
  const newStatistic = statistic.filter((el) => el.paymentSystem === title);

  return newStatistic.reduce((acc: number, chartValue: ChartValue) => {
    acc += +chartValue.successQuantity;
    return acc;
  }, 0);
};
