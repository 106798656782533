import { createArticleFormController } from "service/shared/singletones/createArticleFormService/createArticleForm.controller";
import { createArticleFormState } from "service/shared/singletones/createArticleFormService/createArticleForm.state";

class Service {
  public readonly state = createArticleFormState;

  public readonly controller = createArticleFormController;
}

export const createArticleFormService = new Service();
