import { InvoicePayment } from "types/business/InvoicePayment";
import { convertFetchedDateToTableShowDate } from "utils/business/convertFetchedDateToTableShowDate";

export class InvoiceDetailsModel {
  public readonly terminalId: string;

  public readonly currency: string;

  public readonly creationDate: string;

  public readonly status: string;

  public readonly email: string;

  public readonly phone: string;

  public readonly amount: string;

  public readonly formUrl: string;

  public readonly description: string;

  public readonly title: string;

  public readonly hasCopyAction: boolean;

  public readonly hasDeleteAction: boolean;

  public readonly hasSendAction: boolean;

  public readonly payments: InvoicePayment[];

  public constructor(dataItem: any) {
    this.terminalId = dataItem?.terminalId || "";
    this.creationDate = dataItem?.creationDate ? convertFetchedDateToTableShowDate(dataItem?.creationDate) : "";
    this.status = dataItem?.status || "";
    this.email = dataItem?.customerInfo?.email || "";
    this.phone = dataItem?.customerInfo?.phone || "";
    this.amount = dataItem?.amount || "";
    this.formUrl = dataItem?.formUrl || "";
    this.description = dataItem?.description || "";
    this.title = dataItem?.title || "";
    this.currency = dataItem?.currency || "";
    this.hasCopyAction = dataItem?.operationAvailability.COPY || false;
    this.hasDeleteAction = dataItem?.operationAvailability.DELETE || false;
    this.hasSendAction = dataItem?.operationAvailability.SEND || false;
    this.payments = dataItem?.payments || [];
  }
}
