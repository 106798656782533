import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { signInFormService } from "service/shared/singletones/signInFormService/signInForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SSignInCapsLockErrorForm } from "./SignInCapsLockErrorFormStyled/SSignInCapsLockErrorForm.styled";
import { SSignInCapsLockErrorFormTitle } from "./SignInCapsLockErrorFormStyled/SSignInCapsLockErrorFormTitle.styled";

export const SignInCapsLockErrorForm = createComponent("SignInCapsLockErrorForm", () => {
  const { t, i18n } = useTranslation();
  const showCapsLockError = useDefaultObservableBoolean(signInFormService.state.showCapsLockError);

  const hideForm = !showCapsLockError;

  if (hideForm) {
    return null;
  }
  return (
    <SSignInCapsLockErrorForm $isArabic={i18n.language === "ar"}>
      <SSignInCapsLockErrorFormTitle>{t("signInPage.capsLockError")}</SSignInCapsLockErrorFormTitle>
    </SSignInCapsLockErrorForm>
  );
});
