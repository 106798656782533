import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SResetPasswordBackButton } from "./ResetPasswordBackButtonStyled/SResetPasswordBackButton.styled";

export const ResetPasswordBackButton = createComponent("ResetPasswordBackButton", () => {
  const { t } = useTranslation();
  return (
    <SResetPasswordBackButton onClick={usersDetailsPageService.controller.onClickResetBackButton}>
      {t("usersDetailsPage.resetPasswordBackButton")}
    </SResetPasswordBackButton>
  );
});
