import { styled } from "theme/default/styled";

import { setColor_2b3e50 } from "../../../../theme/setter/setColorsValues/setColor_2b3e50";
import { setFontSize_12px } from "../../../../theme/setter/setFontSizeValues/setFontSize_12px";
import { setFontWeight500 } from "../../../../theme/setter/setFontWeightValues/setFontWeight500";

export const SDataValue = styled.span`
  ${setFontSize_12px};
  ${setFontWeight500};
  ${setColor_2b3e50};
`;
