import { FlattenSimpleInterpolation } from "packages/styled-components";
import { css } from "theme/default/css";
import { styled } from "theme/default/styled";
import { setColor_00346e } from "theme/setter/setColorsValues/setColor_00346e";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";

type SBodyDataCellProps = {
  width: number | null;
};

const setWidth = ({ width }: SBodyDataCellProps): FlattenSimpleInterpolation => {
  return css`
    width: ${width ?? 0}px;
  `;
};

export const SBodyDataCell = styled.div<SBodyDataCellProps>`
  overflow: hidden;
  padding-right: 26px;
  padding-left: 22px;

  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_00346e};
  ${setWidth};
`;
