import { css, setColor_25476d, setFlexF1, setFontSize_13px, setFontWeight600, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  text-align: right;
  padding-right: 10px;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const SGroupHeaderCellTitle = styled.div<Props>`
  ${setFlexF1};
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_25476d};
  ${isArabicLang};
  ${isNotArabicLang};
`;
