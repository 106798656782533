import { GroupTable } from "components/GroupTable/GroupTable.component";
import { useDefaultObservableGroupModelNull } from "observables/GroupModelNullObservable";
import { usersInfoPageService } from "service/shared/singletones/usersInfoPageService/usersInfoPage.service";
import { createComponent } from "utils/libExtend/createComponent";

export const UsersInfoContent = createComponent("UsersInfoContent", () => {
  const userData = useDefaultObservableGroupModelNull(usersInfoPageService.state.userData)!;

  return <GroupTable userData={userData} />;
});
