import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { t } from "i18n";
import { HeaderCellModel } from "model/HeaderCell.model";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const statisticPage = (): HeaderCellModel[] => {
  return [
    getInitialHeaderCell(initialHeaderCellKey.datePart, t("statisticPageConfig.datePart"), "statisticPageConfig.datePart", { width: tableWidths.width120 }),
    getInitialHeaderCell(initialHeaderCellKey.terminalId, t("statisticPageConfig.terminalId"), "statisticPageConfig.terminalId", {
      width: tableWidths.width300,
    }),
    getInitialHeaderCell(initialHeaderCellKey.successQuantity, t("statisticPageConfig.successQuantity"), "statisticPageConfig.successQuantity", {
      width: tableWidths.width120,
    }),
    getInitialHeaderCell(initialHeaderCellKey.failQuantity, t("statisticPageConfig.failQuantity"), "statisticPageConfig.failQuantity", {
      width: tableWidths.width120,
    }),
    getInitialHeaderCell(initialHeaderCellKey.refundQuantity, t("statisticPageConfig.refundQuantity"), "statisticPageConfig.refundQuantity", {
      width: tableWidths.width120,
    }),
    // getInitialHeaderCell(initialHeaderCellKey.successSum, t("statisticPageConfig.successSum"), { width: tableWidths.width120 }),
  ];
};
