import { setZero } from "./setZero";

export const getOffset = (value: number): string => {
  const newValue = Math.abs(value);

  const hours = setZero((newValue - (newValue % 60)) / 60);
  const minutes = setZero(newValue % 60);

  if (value > 0) return `-${hours}:${minutes}`;
  return `+${hours}:${minutes}`;
};
