import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { ToastService } from "service/shared/others/ToastService/Toast.service";

type ToastOptions = {
  type: string;
};

export class ToastState {
  public readonly service: ToastService;

  public readonly id: number;

  public readonly title: string;

  public readonly type: string;

  public readonly show: BehaviorSubjectBoolean;

  public readonly close: BehaviorSubjectBoolean;

  public readonly animationDelay: number;

  public readonly timeOut?: ReturnType<typeof setTimeout>;

  public animationTimeOut?: ReturnType<typeof setTimeout>;

  public readonly deleteToastCallBack: (service: ToastService) => void;

  private readonly timeOutDelay: number;

  public constructor(service: ToastService, id: number, title: string, deleteToastCallBack: (rootService: ToastService) => void, options: ToastOptions) {
    this.service = service;
    this.show = new BehaviorSubjectBoolean(false);
    this.close = new BehaviorSubjectBoolean(true);
    this.timeOutDelay = 5000;
    this.animationDelay = 1000;
    this.id = id;
    this.deleteToastCallBack = deleteToastCallBack;
    this.title = title;
    this.type = options.type;

    this.show.next(true);
    this.close.next(false);

    this.timeOut = setTimeout(() => {
      this.animationTimeOut = setTimeout(() => {
        this.close.next(true);
        this.deleteToastCallBack(this.service);
      }, this.animationDelay);

      this.show.next(false);
    }, this.timeOutDelay);
  }
}
