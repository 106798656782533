import { t } from "i18n";

import { dayFullLength } from "./getStatisticData/dayFullLength";

type OptionsParams = {
  firstValueArray: string[];
  secondValueArray: string[];
};

export const getOptions = (options: OptionsParams): any => {
  return {
    tooltip: { trigger: "axis" },
    grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
    xAxis: [
      {
        name: t("dynamicPage.xAxisTitle"),
        type: "category",
        boundaryGap: false,
        data: dayFullLength,
      },
    ],
    legend: {
      data: [t("dynamicPage.firstGroupTitle"), t("dynamicPage.secondGroupTitle")],
    },
    yAxis: [
      {
        name: t("dynamicPage.yAxisTitle"),
        type: "value",
      },
    ],
    series: [
      { name: t("dynamicPage.firstGroupTitle"), type: "line", data: options.firstValueArray },
      { name: t("dynamicPage.secondGroupTitle"), type: "line", data: options.secondValueArray },
    ],
  };
};
