import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableStringArray } from "observables/StringArrayObservable";
import { useTranslation } from "react-i18next";
import { userInfoPageService } from "service/shared/singletones/userInfoPageService/userInfoPage.service";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { SChangePasswordSectionErrorForm } from "./ChangePasswordSectionErrorFormStyled/SChangePasswordSectionErrorForm.styled";
import { SChangePasswordSectionErrorFormTitle } from "./ChangePasswordSectionErrorFormStyled/SChangePasswordSectionErrorFormTitle.styled";

const LENGTH_ERROR = "changePasswordSection.lengthError";
export const ChangePasswordSectionErrorForm = createComponent("ChangePasswordSectionErrorForm", () => {
  const { t } = useTranslation();
  const showErrors = useDefaultObservableBoolean(userInfoPageService.state.showErrors);
  const errorTextArray = useDefaultObservableStringArray(userInfoPageService.state.errorTextArray);
  const minLength = userInfoPageService.state.passwordConfig.minLength!;
  const maxLength = userInfoPageService.state.passwordConfig.maxLength!;

  const renderErrorText: RenderFunctionType<string> = (errorText, index) => {
    const text = errorText === LENGTH_ERROR ? t(errorText, { minLength, maxLength }) : t(errorText);

    return <SChangePasswordSectionErrorFormTitle key={index}>{text}</SChangePasswordSectionErrorFormTitle>;
  };

  const hideForm = !(showErrors && errorTextArray.length !== 0);

  if (hideForm) {
    return null;
  }

  return <SChangePasswordSectionErrorForm>{errorTextArray.map(renderErrorText)}</SChangePasswordSectionErrorForm>;
});
