import { styled } from "theme/default/styled";
import { setColor_4d4d4d } from "theme/setter/setColorsValues/setColor_4d4d4d";
import { setFontSize_18px } from "theme/setter/setFontSizeValues/setFontSize_18px";
import { setFontWeight700 } from "theme/setter/setFontWeightValues/setFontWeight700";

export const SActivateInvitePageHeader = styled.div`
  ${setFontSize_18px};
  ${setFontWeight700};
  ${setColor_4d4d4d};
`;
