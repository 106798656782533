import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const requestIdFilterModel = new FilterModel({
  id: 9,
  type: "text",
  title: t("requestIdFilterModel.title"),
  name: "REQUEST_ID",
  options: {
    maxLength: 36,
  },
  langPath: "requestIdFilterModel.title",
});
