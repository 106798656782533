import { styled } from "theme/default/styled";
import { color_rgba_0_0_0_08 } from "theme/setter/opacityColorsValues/color_rgba_0_0_0_08";
import { color_rgba_34_36_38_15 } from "theme/setter/opacityColorsValues/color_rgba_34_36_38_15";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SMenuList = styled.div<{ isArabic?: boolean }>`
  position: absolute;
  right: 0;
  ${(props) => (props.isArabic ? `left: 0` : `right: 0`)};
  width: 200px;

  border: 1px solid ${color_rgba_34_36_38_15};
  box-shadow: 0 1px 3px 0 ${color_rgba_0_0_0_08};

  ${setFlexDFFDC};
  ${setBorderRadius_4px};
`;
