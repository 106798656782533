import { TableNavigation } from "components/TableNavigation/TableNavigation.component";
import { useTranslation } from "react-i18next";
import { h2hPageService } from "service/shared/singletones/h2hPageService/h2hPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { H2HErrorMessage } from "./H2HErrorMessage/H2HErrorMessage.component";
import { H2HPageFilters } from "./H2HPageFilters/H2HPageFilters.component";
import { SH2HPage } from "./H2HPageStyled/SH2HPage.styled";
import { H2HTable } from "./H2HTable/H2HTable.component";

export const H2HPage = createComponent("H2HPage", () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    h2hPageService.controller.mounted();

    return (): void => {
      h2hPageService.controller.unMounted();
    };
  }, []);

  return (
    <SH2HPage isArabic={i18n.language === "ar"}>
      <H2HPageFilters />
      <H2HErrorMessage />
      <H2HTable />
      <TableNavigation service={h2hPageService.state.filterNavigationService} />
    </SH2HPage>
  );
});
