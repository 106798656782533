import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModelArray } from "observables/OptionModelArrayObservable";
import { useDefaultObservableOptionModel } from "observables/OptionModelObservable";
import { useDefaultObservableSvgNames } from "observables/SvgNamesObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { UsersChangeRowGroupInputProps } from "./UsersChangeRowGroupInput.props";
import { SUsersChangeRowGroupInput } from "./UsersChangeRowGroupInputStyled/SUsersChangeRowGroupInput.styled";
import { SUsersChangeRowGroupInputIcon } from "./UsersChangeRowGroupInputStyled/SUsersChangeRowGroupInputIcon.styled";
import { SUsersChangeRowGroupInputInput } from "./UsersChangeRowGroupInputStyled/SUsersChangeRowGroupInputInput.styled";
import { SUsersChangeRowGroupInputTitle } from "./UsersChangeRowGroupInputStyled/SUsersChangeRowGroupInputTitle.styled";
import { SUsersChangeRowGroupInputValue } from "./UsersChangeRowGroupInputStyled/SUsersChangeRowGroupInputValue.styled";

export const UsersChangeRowGroupInput = createComponent<UsersChangeRowGroupInputProps>("UsersChangeRowGroupInput", (props) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const { service } = props;

  useEffect(async () => {
    await service.controller.onMount();
  }, []);

  const active = useDefaultObservableBoolean(service.state.active);
  const value = useDefaultObservableOptionModel(service.state.value);
  const icon = useDefaultObservableSvgNames(service.state.icon);
  const showError = useDefaultObservableBoolean(service.state.showError);
  const optionList = useDefaultObservableOptionModelArray(service.state.optionList);

  if (active) {
    return (
      <SUsersChangeRowGroupInput isArabic={isArabic}>
        <SUsersChangeRowGroupInputTitle>{t("usersDetailsPage.merchantGroupName")}</SUsersChangeRowGroupInputTitle>
        <SUsersChangeRowGroupInputInput showError={showError} value={value} onChange={service.controller.onChange} optionList={optionList} />
        <SUsersChangeRowGroupInputIcon onClick={service.controller.onClickEdit}>
          <SvgImage name={icon} />
        </SUsersChangeRowGroupInputIcon>
      </SUsersChangeRowGroupInput>
    );
  }

  return (
    <SUsersChangeRowGroupInput isArabic={isArabic}>
      <SUsersChangeRowGroupInputTitle>{t("usersDetailsPage.merchantGroupName")}</SUsersChangeRowGroupInputTitle>
      <SUsersChangeRowGroupInputValue>{value.title}</SUsersChangeRowGroupInputValue>
      <SUsersChangeRowGroupInputIcon onClick={service.controller.onClickEdit}>
        <SvgImage name={icon} />
      </SUsersChangeRowGroupInputIcon>
    </SUsersChangeRowGroupInput>
  );
});
