import { paymentRefundDetailsPageController } from "service/shared/singletones/paymentRefundDetailsPageService/paymentRefundDetailsPage.controller";
import { paymentRefundDetailsPageState } from "service/shared/singletones/paymentRefundDetailsPageService/paymentRefundDetailsPage.state";

class Service {
  public readonly state = paymentRefundDetailsPageState;

  public readonly controller = paymentRefundDetailsPageController;
}

export const paymentRefundDetailsPageService = new Service();
