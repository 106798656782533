export class TerminalModel {
  public readonly terminalId: string;

  public readonly merchantName: string;

  public constructor(dataItem: any) {
    this.terminalId = dataItem?.terminalId || "";
    this.merchantName = dataItem?.merchantName || "";
  }
}
