import { DetailsLayout } from "components/DetailsLayout/DetailsLayout.component";
import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { supportPageService } from "service/shared/singletones/supportPageService/supportPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { SupportPageFeedbackForm } from "./SupportPageFeedbackForm/SupportPageFeedbackForm.component";
import { SupportPageQuestionList } from "./SupportPageQuestionList/SupportPageQuestionList.component";
import { SSupportPage } from "./SupportPageStyled/SSupportPage.styled";

export const SupportPage = createComponent("SupportPage", () => {
  const { t } = useTranslation();
  useEffect(async () => {
    await supportPageService.controller.onMount();
  }, []);

  const loaded = useDefaultObservableBoolean(supportPageService.state.loadedContent);

  return (
    <DetailsLayout title={t("supportPage.pageTitle")}>
      <SSupportPage>
        {loaded ? <SupportPageQuestionList /> : <Loader />}
        <SupportPageFeedbackForm />
      </SSupportPage>
    </DetailsLayout>
  );
});
