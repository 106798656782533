import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SToastContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  ${setFlexDFFDC};
`;
