import { Route } from "packages/react-router-dom";
import { Children } from "types/commonExtend/Children";

export const createAppPage = (path: string, Component: Children, canGetPage = true): Children | null => {
  if (!canGetPage) {
    return null;
  }

  return <Route path={path} element={<Component />} />;
};
