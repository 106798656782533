import { OptionModel } from "model/Option.model";
import { ArticleDescriptionInputState } from "service/shared/others/ArticleDescriptionInputService/ArticleDescriptionInput.state";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

export class ArticleDescriptionInputController {
  public readonly state: ArticleDescriptionInputState;

  public readonly onChangeDescriptionValue = onChangeHelper((text: string): void => {
    this.state.descriptionValue.next(text);
  });

  public readonly onChangeEmailValue = onChangeHelper((text: string): void => {
    this.state.emailValue.next(text);
  });

  public constructor(state: ArticleDescriptionInputState) {
    this.state = state;
  }

  public readonly onChangeTypeValue = (value: OptionModel): void => {
    this.state.typeValue.next(value);
  };
}
