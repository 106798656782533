import { color_a0b9d2, setBorderRadius_8px, setFlexDFFDC, setTextRightArabic, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SCompareGroup = styled.div<Props>`
  padding: 24px;
  gap: 24px;

  border: 1px solid ${color_a0b9d2};

  ${setFlexDFFDC};
  ${setBorderRadius_8px};
  ${setTextRightArabic()};
`;
