import { paymentDetailsPageController } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.controller";
import { paymentDetailsPageState } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.state";

class Service {
  public readonly state = paymentDetailsPageState;

  public readonly controller = paymentDetailsPageController;
}

export const paymentDetailsPageService = new Service();
