import { setFlexDFFDC, setTextRightArabic, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SChangePasswordSection = styled.form<Props>`
  ${setFlexDFFDC};
  ${setTextRightArabic()};
`;
