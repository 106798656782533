import { userRolesConfig } from "appConstants/userRolesConfig";
import { t } from "i18n";
import { RolesSelectOptionModel } from "model/RolesSelectOption.model";
import { setTitledValue } from "service/shared/others/UsersChangeRowRolesService/setTitledValue";
import { UsersChangeRowRolesState } from "service/shared/others/UsersChangeRowRolesService/usersChangeRowRoles.state";

export class UsersChangeRowRolesController {
  public readonly state: UsersChangeRowRolesState;

  public constructor(state: UsersChangeRowRolesState) {
    this.state = state;
  }

  public readonly produceData = (): RolesSelectOptionModel[] => {
    return [
      new RolesSelectOptionModel(
        userRolesConfig.ADMINISTRATOR,
        t("usersChangeRowRolesService.administratorDescription"),
        "usersChangeRowRolesService.administratorDescription"
      ),
      new RolesSelectOptionModel(userRolesConfig.R_H2H, t("usersChangeRowRolesService.rH2H"), "usersChangeRowRolesService.rH2H"),
      new RolesSelectOptionModel(
        userRolesConfig.R_OPERATION,
        t("usersChangeRowRolesService.rOperationDescription"),
        "usersChangeRowRolesService.rOperationDescription"
      ),
      new RolesSelectOptionModel(
        userRolesConfig.R_INVOICE,
        t("usersChangeRowRolesService.rInvoiceDescription"),
        "usersChangeRowRolesService.rInvoiceDescription"
      ),
      new RolesSelectOptionModel(
        userRolesConfig.W_INVOICE,
        t("usersChangeRowRolesService.wInvoiceDescription"),
        "usersChangeRowRolesService.wInvoiceDescription"
      ),
      new RolesSelectOptionModel(
        userRolesConfig.X_INVOICE,
        t("usersChangeRowRolesService.xInvoiceDescription"),
        "usersChangeRowRolesService.xInvoiceDescription"
      ),
      new RolesSelectOptionModel(userRolesConfig.X_CANCEL, t("usersChangeRowRolesService.xCancelDescription"), "usersChangeRowRolesService.xCancelDescription"),
      new RolesSelectOptionModel(userRolesConfig.X_REFUND, t("usersChangeRowRolesService.xRefundDescription"), "usersChangeRowRolesService.xRefundDescription"),
      new RolesSelectOptionModel(
        userRolesConfig.X_CONFIRM,
        t("usersChangeRowRolesService.xConfirmDescription"),
        "usersChangeRowRolesService.xConfirmDescription"
      ),
      new RolesSelectOptionModel(userRolesConfig.R_REFUND, t("usersChangeRowRolesService.rRefundDescription"), "usersChangeRowRolesService.rRefundDescription"),
      new RolesSelectOptionModel(
        userRolesConfig.R_TRANSFER,
        t("usersChangeRowRolesService.rTransferDescription"),
        "usersChangeRowRolesService.rTransferDescription"
      ),
      new RolesSelectOptionModel(
        userRolesConfig.X_TRANSFER,
        t("usersChangeRowRolesService.xTransferDescription"),
        "usersChangeRowRolesService.xTransferDescription"
      ),
      new RolesSelectOptionModel(
        userRolesConfig.R_STATISTICS,
        t("usersChangeRowRolesService.rStatisticsDescription"),
        "usersChangeRowRolesService.rStatisticsDescription"
      ),
      new RolesSelectOptionModel(userRolesConfig.R_REWARD, t("usersChangeRowRolesService.rRewardDescription"), "usersChangeRowRolesService.rRewardDescription"),
    ];
  };

  public readonly onMount = async (): Promise<void> => {
    if (this.state.mounted.value) {
      return;
    }

    const producedData = this.produceData();
    this.state.rolesSelectOptionArray.next(producedData);
    this.state.loaded.next(true);
    this.state.mounted.next(true);
  };

  public readonly onClickEdit = (): void => {
    this.state.open.next(true);
  };

  public readonly onClickApplyButton = (): void => {
    this.state.titledValue.next(setTitledValue(this.state.selectedOptions.value));
    this.state.open.next(false);
    this.state.updateUserCallBack();
  };

  public readonly onClickBackButton = (): void => {
    this.state.open.next(false);
  };

  public readonly closeCallBack = (): void => {
    this.state.open.next(false);
  };

  public readonly onClickOption = (rolesSelectOptionModel: RolesSelectOptionModel) => (): void => {
    const foundOption = !!this.state.selectedOptions.value.find((element) => element.title === rolesSelectOptionModel.title);

    if (foundOption) {
      const newSelectedOptions = this.state.selectedOptions.value.filter((element) => element.title !== rolesSelectOptionModel.title);
      this.state.selectedOptions.next(newSelectedOptions);
    } else {
      const newSelectedOptions = [...this.state.selectedOptions.value, rolesSelectOptionModel];
      this.state.selectedOptions.next(newSelectedOptions);
    }
  };
}
