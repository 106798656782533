import { TerminalModel } from "model/Terminal.model";
import { useDefaultObservableTerminalModelArray } from "observables/TerminalModelArrayObservable";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { STerminalsListTableBody } from "./TerminalsListTableBodyStyled/STerminalsListTableBody.styled";
import { STerminalsListTableBodyRow } from "./TerminalsListTableBodyStyled/STerminalsListTableBodyRow.styled";
import { STerminalsListTableBodyTitle } from "./TerminalsListTableBodyStyled/STerminalsListTableBodyTitle.styled";

export const TerminalsListTableBody = createComponent("TerminalsListTableBody", () => {
  const filteredTerminalsList = useDefaultObservableTerminalModelArray(terminalsPageService.state.filteredTerminalsList);

  const renderTerminalRow: RenderFunctionType<TerminalModel> = (terminalRow, index) => {
    return (
      <STerminalsListTableBodyRow key={index}>
        <STerminalsListTableBodyTitle>{terminalRow.terminalId}</STerminalsListTableBodyTitle>
        <STerminalsListTableBodyTitle>{terminalRow.merchantName}</STerminalsListTableBodyTitle>
      </STerminalsListTableBodyRow>
    );
  };

  return <STerminalsListTableBody>{filteredTerminalsList.map(renderTerminalRow)}</STerminalsListTableBody>;
});
