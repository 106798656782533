import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { CustomSelectOptionProps } from "./CustomSelectOption.props";
import { SCustomSelectOption } from "./CustomSelectOptionStyled/SCustomSelectOption.styled";

export const CustomSelectOption = createComponent<CustomSelectOptionProps>("CustomSelectOption", (props) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const { isFirst, isLast, option, value, service } = props;

  const currentValue = t(option.title);
  const isActive = currentValue === value;

  const onClick = (): void => {
    service.controller.onChangeHandler(option);
  };

  return (
    <SCustomSelectOption isArabic={isArabic} isActive={isActive} isFirst={isFirst} isLast={isLast} onClick={onClick}>
      {currentValue}
    </SCustomSelectOption>
  );
});
