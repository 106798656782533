import { css, setProps, styled } from "theme";

interface SFilterSelectorClearProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterSelectorClearProps>(({ isArabic }) => isArabic)(css`
  left: 8px;
`);

const isNotArabicLang = setProps<SFilterSelectorClearProps>(({ isArabic }) => !isArabic)(css`
  right: 8px;
`);

export const SFilterSelectorClear = styled.div<SFilterSelectorClearProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  ${isNotArabicLang};
  ${isArabicLang};
`;
