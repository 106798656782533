import { FilterModel } from "model/Filter.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { CustomInputEvent } from "types/commonExtend/CustomInputEvent";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

export class FilterInputDataModel {
  public readonly filterModel: FilterModel;

  public readonly value: BehaviorSubjectString;

  public readonly valid: BehaviorSubjectBoolean;

  private readonly initialValue: string;

  private readonly removeActiveFilter: (filterModel: FilterModel) => void;

  public constructor(filterModel: FilterModel, removeActiveFilter: (filterModelParam: FilterModel) => void) {
    this.filterModel = filterModel;
    this.removeActiveFilter = removeActiveFilter;

    this.initialValue = this.filterModel.initialValue || "";
    this.value = new BehaviorSubjectString(this.initialValue);

    this.valid = setCombinedBehaviorSubject(this.setValid, this.value);
  }

  public readonly onClickDelete = (): void => {
    this.removeActiveFilter(this.filterModel);
  };

  public readonly onChange = (event: CustomInputEvent): void => {
    const { value } = event.target;

    if (this.filterModel.maxLength) {
      if (value.length <= this.filterModel.maxLength) {
        this.value.next(value);
      }
    } else {
      this.value.next(value);
    }
  };

  public readonly onClickClear = (): void => {
    this.value.next("");
  };

  private readonly setValid = (value: string): boolean => {
    return value.trim() !== "";
  };
}
