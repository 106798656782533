import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { supportPageService } from "service/shared/singletones/supportPageService/supportPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SFeedbackFormTextField } from "./FeedbackFormTextFieldStyled/SFeedbackFormTextField.styled";

export const FeedbackFormTextField = createComponent("FeedbackFormTextField", () => {
  const { t, i18n } = useTranslation();
  const inputValue = useDefaultObservableString(supportPageService.state.inputValue);
  const placeholder = t("supportPage.feedBackFormTextFieldPlaceholder") || "";
  const isArabic = i18n.language === "ar";

  return (
    <SFeedbackFormTextField isArabic={isArabic} value={inputValue} onChange={supportPageService.controller.onChangeInput} rows={12} placeholder={placeholder} />
  );
});
