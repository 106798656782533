import { FilterModel } from "model/Filter.model";
import { BehaviorSubjectDate } from "observables/DateObservable";
import moment, { Moment } from "packages/moment";
import { getProduceMomentDate } from "utils/business/getProduceMomentDate";

export class FilterRangeDataModel {
  public readonly filterModel: FilterModel;

  public readonly startValue: BehaviorSubjectDate;

  public readonly endValue: BehaviorSubjectDate;

  private readonly removeActiveFilter: (filterModel: FilterModel) => void;

  public constructor(filterModel: FilterModel, removeActiveFilter: (filterModelParam: FilterModel) => void) {
    this.filterModel = filterModel;
    this.removeActiveFilter = removeActiveFilter;
    this.startValue = new BehaviorSubjectDate(this.filterModel?.initialStartDateValue || new Date());
    this.endValue = new BehaviorSubjectDate(this.filterModel?.initialEndDateValue || new Date());
  }

  public readonly onClickDelete = (): void => {
    this.removeActiveFilter(this.filterModel);
  };

  public readonly onChangeStartDate = (value: Moment | string): void => {
    const momentDate = moment(value);
    const newDate = getProduceMomentDate(momentDate);
    this.startValue.next(newDate);
  };

  public readonly onChangeEndDate = (value: moment.Moment | string): void => {
    const momentDate = moment(value);
    const newDate = getProduceMomentDate(momentDate);
    this.endValue.next(newDate);
  };
}
