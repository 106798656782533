import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { OptionModel } from "model/Option.model";
import { CompareTerminalState } from "service/shared/others/CompareTerminalService/CompareTerminal.state";

export class CompareTerminalController {
  public readonly state: CompareTerminalState;

  public constructor(state: CompareTerminalState) {
    this.state = state;
  }

  public readonly onMount = async (): Promise<void> => {
    const { data } = await client.post(apiPaths.terminalList);
    const fetchedOptionList = this.convertTerminalsToOptionList(data);
    this.state.optionList.next(fetchedOptionList);
    this.state.loaded.next(true);
  };

  public readonly onClickAll = (): void => {
    this.state.value.next(this.state.optionList.value);
  };

  public readonly onChange = (value: OptionModel): void => {
    const hasInListValue = !!this.state.value.value.find((element) => element.value === value.value);

    if (hasInListValue) {
      const newListValue = this.state.value.value.filter((listValue) => listValue !== value);
      this.state.value.next(newListValue);
    } else {
      this.state.value.next([...this.state.value.value, value]);
    }
  };

  private readonly convertTerminalsToOptionList = (data: any[]): OptionModel[] => {
    return data.map((dataItem: any) => {
      const title = `${dataItem.merchantName} - ${dataItem.terminalId}`;
      const value = dataItem.terminalId;
      return new OptionModel(title, value);
    });
  };
}
