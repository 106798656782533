import { UsersChangePasswordInputState } from "service/shared/others/UsersChangePasswordInputService/UsersChangePasswordInput.state";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

export class UsersChangePasswordInputController {
  public readonly state: UsersChangePasswordInputState;

  public readonly onChange = onChangeHelper((text: string): void => {
    this.state.value.next(text);
  });

  public constructor(state: UsersChangePasswordInputState) {
    this.state = state;
  }

  public readonly onFocus = (): void => {
    this.state.focused.next(true);
    this.state.touched.next(true);
  };

  public readonly onBlur = (): void => {
    this.state.focused.next(false);
  };
}
