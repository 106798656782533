/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const PaginationLeftArrow = createIcon("PaginationLeftArrow", (props) => {
  const { width = 8, height = 14, color = "#69809A" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 8 14" fill="none">
      <path
        d="M0.421009 6.81685L6.01081 1.22723C6.36639 0.871479 6.9429 0.87148 7.29831 1.22723C7.65374 1.58267 7.65374 2.15915 7.29831 2.51455L2.35219 7.46051L7.29816 12.4063C7.6536 12.7619 7.6536 13.3383 7.29816 13.6937C6.94273 14.0493 6.36624 14.0493 6.01066 13.6937L0.420864 8.10402C0.243147 7.92622 0.154388 7.69344 0.154389 7.46054C0.154389 7.22752 0.24332 6.99457 0.421009 6.81685Z"
        fill={color}
      />
    </svg>
  );
});
