import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { rewardsFilterConfig } from "config/filterConfig/rewardsFilterConfig";
import { tableHeaderInitialCell } from "config/filterConfig/tableHeaderInitialCell";
import i18next from "i18next";
import { BehaviorSubjectHeaderCellModelArray } from "observables/HeaderCellModelArrayObservable";
import { BehaviorSubjectRewardTypeArray } from "observables/RewardTypeArrayObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { combineLatestWith } from "packages/rxjs";
import { FiltersService } from "service/shared/others/FiltersService/Filters.service";
import { TableEntityService } from "service/shared/others/TableEntityService/TableEntity.service";
import { RewardType } from "types/business/RewardType";
import { Dictionary } from "types/commonExtend/Dictionary";
import { clearQueriesField } from "utils/business/clearQueriesField";
import { convertRewardDate } from "utils/business/convertRewardDate";
import { convertRewardDateToUtc0 } from "utils/business/convertRewardDateToUtc0";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class Service extends TableEntityService<RewardType> {
  public readonly filterService: FiltersService;

  public readonly tableService: BehaviorSubjectTableService;

  private readonly initialHeaderCellList: BehaviorSubjectHeaderCellModelArray;

  private readonly rewards: BehaviorSubjectRewardTypeArray;

  public constructor() {
    super(tableHeaderInitialCell.rewardsPage, { showCountElements: false });
    this.rewards = new BehaviorSubjectRewardTypeArray([]);
    this.filterService = new FiltersService(rewardsFilterConfig, this.applyFilter, {
      initialOpen: true,
      showCloseIcon: false,
      initialArabic: i18next.language === "ar",
    });
    this.initialHeaderCellList = setCombinedBehaviorSubject(this.controller.setInitialHeaderCellList, this.state.sort, this.state.desc);
    this.tableService = setCombinedBehaviorSubject(this.controller.setTableService, this.rewards, this.initialHeaderCellList);

    this.state.isMounted
      .pipe(
        combineLatestWith(
          this.state.filterNavigationService.state.currentPage,
          this.state.filterNavigationService.state.controlsSelectService.state.activeOption,
          this.state.sort
        )
      )
      .subscribe(([isMounted, currentPage, sizeOption, sort]) => {
        if (isMounted) {
          const isMountedNotEqual = this.state.prevIsMounted !== isMounted;
          const currentPageNotEqual = this.state.prevCurrentPage !== currentPage;
          const sizeOptionNotEqual = this.state.prevSizeOption !== +sizeOption.value;
          const sortNotEqual = this.state.prevSort !== sort;

          if (isMountedNotEqual || currentPageNotEqual || sizeOptionNotEqual || sortNotEqual) {
            this.state.loaded.next(false);

            this.state.prevIsMounted = isMounted;
            this.state.prevSizeOption = +sizeOption.value;
            this.state.prevSort = sort;

            this.getRewards().then(() => {
              this.state.prevCurrentPage = currentPage;
            });
          }
        }
      });
  }

  private readonly produceRewards = (dataItem: any): RewardType => {
    return {
      date: dataItem.date ? convertRewardDate(dataItem.date) : "0",
      feeAmount: dataItem.feeAmount || "0",
      incomeAmount: dataItem.incomeAmount || "0",
      operationsNumber: dataItem.operationsNumber || "0",
      settlementAmount: dataItem.settlementAmount || "0",
    };
  };

  private readonly produceInvoiceData = (data: any[]): RewardType[] => {
    return data.map(this.produceRewards);
  };

  private readonly produceQueries = (queries: Dictionary<string>): Dictionary<string | string[]> => {
    const [startDate, endDate] = convertRewardDateToUtc0(queries.CREATION_DATE).split("_");

    return {
      terminalIds: queries.FILTERS,
      startDate: startDate.split("T")[0],
      endDate: endDate.split("T")[0],
    };
  };

  private readonly fetchRewards = async (): Promise<any> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const size = this.state.filterNavigationService.state.controlsSelectService.state.activeOption.value.value;
    const page = this.state.filterNavigationService.state.currentPage.value;

    const query = {
      ...this.produceQueries(queries),
      size,
      page,
    };

    const { data } = await client.post(apiPaths.rewardsList, query);

    if (page === this.state.prevCurrentPage) {
      this.state.filterNavigationService.state.currentPage.next(0);
    }

    this.state.filterNavigationService.state.totalPages.next(data.totalPages);
    this.state.filterNavigationService.state.totalElements.next(data.totalElements);

    return data.aggregates;
  };

  private readonly getRewards = async (): Promise<void> => {
    this.state.loaded.next(false);
    try {
      const data: any[] = await this.fetchRewards();
      const produceData = this.produceInvoiceData(data);
      this.rewards.next(produceData);
    } catch (e) {
      console.log("e", e);
    } finally {
      this.state.loaded.next(true);
    }
  };

  private readonly applyFilter = async (): Promise<void> => {
    this.state.loaded.next(false);
    await this.getRewards();
  };
}

export const rewardsPageService = new Service();
