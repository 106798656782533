import { OptionModel } from "model/Option.model";
import { CompareMonthState } from "service/shared/others/CompareMonthService/CompareMonth.state";

export class CompareMonthController {
  public readonly state: CompareMonthState;

  public constructor(state: CompareMonthState) {
    this.state = state;
  }

  public readonly onChange = (value: OptionModel): void => {
    this.state.value.next(value);
  };
}
