import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { ToastProps } from "./Toast.props";
import { SToast } from "./ToastStyled/SToast.styled";
import { SToastClose } from "./ToastStyled/SToastClose.styled";
import { SToastContainer } from "./ToastStyled/SToastContainer.styled";
import { SToastTitle } from "./ToastStyled/SToastTitle.styled";

export const Toast = createComponent<ToastProps>("Toast", (props) => {
  const { service } = props;

  const close = useDefaultObservableBoolean(service.state.close);
  const open = useDefaultObservableBoolean(service.state.show);

  return (
    <SToast isOpen={open} isClose={!open && !close} onClick={service.controller.onClick}>
      <SToastContainer isInfo={service.state.type === "info"} isError={service.state.type === "error"} isSuccess={service.state.type === "success"}>
        <SvgImage name="Warning" />
        <SToastTitle data-test-id="toast">{service.state.title}</SToastTitle>
        <SToastClose>
          <SvgImage name="WhiteClear" />
        </SToastClose>
      </SToastContainer>
    </SToast>
  );
});
