import { useTranslation } from "react-i18next";
import { invoiceCreateFormService } from "service/shared/singletones/invoiceCreateFormService/invoiceCreateForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SCreateFormButtons } from "./ButtonsStyled/SCreateFormButtons.styled";
import { SCreateFormButtonsButton } from "./ButtonsStyled/SCreateFormButtonsButton.styled";

export const Buttons = createComponent("Buttons", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return (
    <SCreateFormButtons isArabic={isArabic}>
      <SCreateFormButtonsButton onClick={invoiceCreateFormService.controller.onClickCancel}>
        {t("invoiceCreateForm.cancelButtonTitle")}
      </SCreateFormButtonsButton>
      <SCreateFormButtonsButton onClick={invoiceCreateFormService.controller.onClickCreate}>
        {t("invoiceCreateForm.createButtonTitle")}
      </SCreateFormButtonsButton>
    </SCreateFormButtons>
  );
});
