import { Outlet } from "packages/react-router-dom";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { Header } from "./Header/Header.component";
import { SMainLayout } from "./MainLayoutStyled/SMainLayout.styled";
import { SMainLayoutContent } from "./MainLayoutStyled/SMainLayoutContent.styled";
import { NavigationMenu } from "./NavigationMenu/NavigationMenu.component";

export const MainLayout = createComponent("MainLayout", () => {
  const { i18n } = useTranslation();

  return (
    <SMainLayout>
      <Header />
      <SMainLayoutContent isArabic={i18n.language === "ar"}>
        <NavigationMenu />
        <Outlet />
      </SMainLayoutContent>
    </SMainLayout>
  );
});
