import { OptionModel } from "model/Option.model";
import { CreateUserFetchedSelectState } from "service/shared/others/CreateUserFetchedSelectService/CreateUserFetchedSelect.state";

export class CreateUserFetchedSelectController {
  public readonly state: CreateUserFetchedSelectState;

  public constructor(state: CreateUserFetchedSelectState) {
    this.state = state;
  }

  public readonly onChange = (value: OptionModel): void => {
    this.state.value.next(value);
  };

  public readonly onMount = async (): Promise<void> => {
    const data = await this.state.fetchDataFunction();
    this.state.optionList.next(data);
    this.state.value.next(new OptionModel("", ""));
  };
}
