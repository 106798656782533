import { css, setProps, styled } from 'theme';

interface SCustomSelectInputProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SCustomSelectInputProps>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SCustomSelectInput = styled.input<SCustomSelectInputProps>`
  width: 100%;
  padding: 0 5px;
  text-overflow: ellipsis;
  border: none;
  outline: none;

  ${isArabicLang};
`;
