import { setColor_e74c3c, setTextRightArabic, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SComparisonSectionMessage = styled.div<Props>`
  ${setColor_e74c3c};
  ${setTextRightArabic()};
`;
