import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { signInFormService } from "service/shared/singletones/signInFormService/signInForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SSignInRussianLetterErrorForm } from "./SignInRussianLetterErrorFormStyled/SSignInRussianLetterErrorForm.styled";
import { SSignInRussianLetterErrorFormTitle } from "./SignInRussianLetterErrorFormStyled/SSignInRussianLetterErrorFormTitle.styled";

export const SignInRussianLetterErrorForm = createComponent("SignInRussianLetterErrorForm", () => {
  const { t, i18n } = useTranslation();
  const showRussianLetterError = useDefaultObservableBoolean(signInFormService.state.showRussianLetterError);

  const hideForm = !showRussianLetterError;

  if (hideForm) {
    return null;
  }

  return (
    <SSignInRussianLetterErrorForm $isArabic={i18n.language === "ar"}>
      <SSignInRussianLetterErrorFormTitle>{t("signInPage.russianLetterError")}</SSignInRussianLetterErrorFormTitle>
    </SSignInRussianLetterErrorForm>
  );
});
