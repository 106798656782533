import { userService } from "api/userService";
import { validateRequireDigits } from "utils/business/passwordValidateFunction/validateRequireDigits";
import { validateRequireLowercaseLetters } from "utils/business/passwordValidateFunction/validateRequireLowercaseLetters";
import { validateRequireSpecialSymbols } from "utils/business/passwordValidateFunction/validateRequireSpecialSymbols";
import { validateRequireUppercaseLetters } from "utils/business/passwordValidateFunction/validateRequireUppercaseLetters";
import { validateRussianLetter } from "utils/business/passwordValidateFunction/validateRussianLetter";

export const passwordValidateFunction = (
  value: string
): {
  hasLengthError: boolean;
  hasDigitsError: boolean;
  hasLowercaseLettersError: boolean;
  hasSpecialSymbolsError: boolean;
  hasUppercaseLettersError: boolean;
  hasRussianLetterError: boolean;
} => {
  const { maxLength, minLength, requireDigits, requireLowercaseLetters, requireSpecialSymbols, requireUppercaseLetters } = userService.getPasswordConfig();

  const lengthValid = value.length <= maxLength! && value.length >= minLength!;
  const digitsValid = requireDigits ? validateRequireDigits(value) : true;
  const lowercaseLettersValid = requireLowercaseLetters ? validateRequireLowercaseLetters(value) : true;
  const specialSymbolsValid = requireSpecialSymbols ? validateRequireSpecialSymbols(value) : true;
  const uppercaseLettersValid = requireUppercaseLetters ? validateRequireUppercaseLetters(value) : true;
  const russianLetterValid = validateRussianLetter(value);

  return {
    hasLengthError: !lengthValid,
    hasDigitsError: !digitsValid,
    hasLowercaseLettersError: !lowercaseLettersValid,
    hasSpecialSymbolsError: !specialSymbolsValid,
    hasUppercaseLettersError: !uppercaseLettersValid,
    hasRussianLetterError: !russianLetterValid,
  };
};
