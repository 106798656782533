import { Loader } from "components/Loader/Loader.component";
import { createUserRolesSelectFormService } from "service/shared/singletones/createUserRolesSelectFormService/createUserRolesSelectForm.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";
import { useEffect } from "utils/libReplace/useEffect";

import { RolesSelectFormContent } from "./RolesSelectFormContent/RolesSelectFormContent.component";
import { SRolesSelectForm } from "./RolesSelectFormStyled/SRolesSelectForm.styled";
import { SRolesSelectFormLoader } from "./RolesSelectFormStyled/SRolesSelectFormLoader.styled";
import { SRolesSelectFormPopUp } from "./RolesSelectFormStyled/SRolesSelectFormPopUp.styled";

export const RolesSelectForm = createComponent("RolesSelectForm", () => {
  useEffect(async () => {
    await createUserRolesSelectFormService.controller.onMount();
  }, []);

  const loaded = useDefaultObservable<boolean>(createUserRolesSelectFormService.state.loaded);
  const open = useDefaultObservable<boolean>(createUserRolesSelectFormService.state.open);

  if (!open) return null;

  return (
    <SRolesSelectFormPopUp closeCallBack={createUserRolesSelectFormService.controller.closeCallBack}>
      <SRolesSelectForm>
        {loaded ? (
          <RolesSelectFormContent />
        ) : (
          <SRolesSelectFormLoader>
            <Loader />
          </SRolesSelectFormLoader>
        )}
      </SRolesSelectForm>
    </SRolesSelectFormPopUp>
  );
});
