import { ThemeProvider } from "packages/styled-components";
import { themeValues } from "theme/default/themeValues";
import { themeValuesFin } from "theme/default/themeValuesFin";
import { createComponent } from "utils/libExtend/createComponent";

import { AppContent } from "App/AppContent.component";

export const App = createComponent("App", () => {
  const isFinOn = process.env.REACT_APP_FIN === "fin";
  return (
    <ThemeProvider theme={isFinOn ? themeValuesFin : themeValues}>
      <AppContent />
    </ThemeProvider>
  );
});
