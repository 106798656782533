import { amountFilterModel } from "config/filterModels/amountFilterModel";
import { dateFilterModel } from "config/filterModels/dateFilterModel";
import { merchantFilterModel } from "config/filterModels/merchantFilterModel";
import { multipleTerminalFilterModel } from "config/filterModels/multipleTerminalFilterModel";
import { panFilterModel } from "config/filterModels/panFilterModel";
import { rrnFilterModel } from "config/filterModels/rrnFilterModel";
import { FilterModel } from "model/Filter.model";

export const h2hFilterConfig: FilterModel[] = [
  dateFilterModel,
  merchantFilterModel,
  multipleTerminalFilterModel,
  panFilterModel,
  rrnFilterModel,
  amountFilterModel,
];
