import { MultipleCustomSelectOptionContext } from "context/MultipleCustomSelectOption.context";
import { createComponent } from "utils/libExtend/createComponent";

import { MultipleCustomSelectOptionProps } from "./MultipleCustomSelectOption.props";
import { MultipleCustomSelectOptionContent } from "./MultipleCustomSelectOptionContent/MultipleCustomSelectOptionContent.component";

export const MultipleCustomSelectOption = createComponent<MultipleCustomSelectOptionProps>("MultipleCustomSelectOption", (props) => {
  const { isLast, option, service, value } = props;

  return (
    <MultipleCustomSelectOptionContext.Provider value={{ isLast, option, service, value }}>
      <MultipleCustomSelectOptionContent />
    </MultipleCustomSelectOptionContext.Provider>
  );
});
