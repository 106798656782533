import { styled } from "theme/default/styled";
import { setColor_7b7b7b } from "theme/setter/setColorsValues/setColor_7b7b7b";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";

export const SFormLayoutFooter = styled.div`
  margin-bottom: 20px;
  text-align: center;

  ${setFlexDF};
  ${setColor_7b7b7b};
`;
