import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SUsersInfoPageLayout = styled.div`
  margin-bottom: 8px;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 24px;

  ${setFlexDFFDC};
`;
