import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SvgImage } from "../../../../../../../components/SvgImage/SvgImage.component";
import { useDefaultObservableOptionModelArray } from "../../../../../../../observables/OptionModelArrayObservable";
import { useDefaultObservableOptionModel } from "../../../../../../../observables/OptionModelObservable";
import { appService } from "../../../../../../../service/shared/singletones/appService/app.service";
import { useDefaultObservable } from "../../../../../../../utils/libExtend/useDefaultObservable";
import { SBankSelect } from "./BankSelectStyled/SBankSelect";
import { SBankSelectContainer } from "./BankSelectStyled/SBankSelectContainer";
import { SBankSelectSelectContainer } from "./BankSelectStyled/SBankSelectSelectContainer";
import { SBankSelectTitle } from "./BankSelectStyled/SBankSelectTitle";
import { SCrossButton } from "./BankSelectStyled/SCrossButton";
import { SLoader } from "./BankSelectStyled/SLoader";

export const BankSelect = createComponent("BankSelect", () => {
  const optionList = useDefaultObservableOptionModelArray(paymentDetailsPageService.state.optionList);
  const bankData = useDefaultObservable(appService.state.bankData);
  const selectedOption = useDefaultObservableOptionModel(paymentDetailsPageService.state.selectedOption);

  if (bankData.length === 0) {
    return (
      <SBankSelectContainer>
        <SLoader />
      </SBankSelectContainer>
    );
  }

  return (
    <SBankSelectContainer>
      <SBankSelectTitle>Выберите банк</SBankSelectTitle>
      <SBankSelectSelectContainer>
        <SBankSelect value={selectedOption} onChange={paymentDetailsPageService.controller.onChangeSelect} optionList={optionList} />
        <SCrossButton onClick={paymentDetailsPageService.controller.onClearSelect}>
          <SvgImage name="Clear" />
        </SCrossButton>
      </SBankSelectSelectContainer>
    </SBankSelectContainer>
  );
});
