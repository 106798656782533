import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SPartialActionButton } from "./PartialActionButtonStyled/SPartialActionButton.styled";

export const PartialActionButton = createComponent("PartialActionButton", () => {
  const { t } = useTranslation();
  const partialFormSelected = useDefaultObservableBoolean(paymentDetailsPageService.state.partialFormSelected);

  return (
    <SPartialActionButton disabled={partialFormSelected} onClick={paymentDetailsPageService.controller.onClickPartial}>
      {t("paymentDetailsPage.partialActionButton")}
    </SPartialActionButton>
  );
});
