import { t } from 'i18n';
import { BehaviorSubjectBoolean } from 'observables/BooleanObservable';
import { BehaviorSubjectPaymentDetailsModelNull } from 'observables/PaymentDetailsModelNullObservable';
import { BehaviorSubjectRequestType } from 'observables/RequestTypeObservable';
import { BehaviorSubjectString } from 'observables/StringObservable';
import { RequestType } from 'types/business/RequestType';
import { setCombinedBehaviorSubject } from 'utils/libExtend/setCombinedBehaviorSubject';

import { OptionModel } from '../../../../model/Option.model';
import { BehaviorSubjectOptionModelArray } from '../../../../observables/OptionModelArrayObservable';
import { BehaviorSubjectOptionModel } from '../../../../observables/OptionModelObservable';
import { BankType } from '../../../../types/business/BankType';
import { appService } from '../appService/app.service';

class State {
  public readonly modalIsOpen: BehaviorSubjectBoolean;

  public readonly loaded: BehaviorSubjectBoolean;

  public readonly actionLoaded: BehaviorSubjectBoolean;

  public readonly partialFormSelected: BehaviorSubjectBoolean;

  public readonly additionalInfoStageSelected: BehaviorSubjectBoolean;

  public readonly value: BehaviorSubjectString;

  public readonly phoneValue: BehaviorSubjectString;

  public readonly phoneShowError: BehaviorSubjectBoolean;

  public readonly amountShowError: BehaviorSubjectBoolean;

  public readonly optionList: BehaviorSubjectOptionModelArray;

  public readonly selectedOption: BehaviorSubjectOptionModel;

  public readonly stageSelectedTitle: BehaviorSubjectString;

  public readonly applyButtonTitle: BehaviorSubjectString;

  public readonly stageUnSelectedTitle: BehaviorSubjectString;

  public readonly payment: BehaviorSubjectPaymentDetailsModelNull;

  public paymentId!: string;

  public readonly requestType: BehaviorSubjectRequestType;

  public constructor() {
    this.requestType = new BehaviorSubjectRequestType('none');
    this.modalIsOpen = new BehaviorSubjectBoolean(false);
    this.loaded = new BehaviorSubjectBoolean(false);
    this.actionLoaded = new BehaviorSubjectBoolean(true);
    this.partialFormSelected = new BehaviorSubjectBoolean(false);
    this.additionalInfoStageSelected = new BehaviorSubjectBoolean(false);
    this.value = new BehaviorSubjectString('');
    this.phoneValue = new BehaviorSubjectString('');
    this.phoneShowError = new BehaviorSubjectBoolean(false);
    this.amountShowError = new BehaviorSubjectBoolean(false);
    this.selectedOption = new BehaviorSubjectOptionModel(new OptionModel('', ''));
    this.stageSelectedTitle = setCombinedBehaviorSubject(this.setStageSelectedTitle, this.requestType);
    this.applyButtonTitle = setCombinedBehaviorSubject(this.setApplyButtonTitle, this.requestType);
    this.stageUnSelectedTitle = setCombinedBehaviorSubject(this.setStageUnSelectedTitle, this.requestType);
    this.optionList = setCombinedBehaviorSubject(this.setOptionList, appService.state.bankData);
    this.payment = new BehaviorSubjectPaymentDetailsModelNull(null);
  }

  private readonly setOptionList = (bankData: BankType[]): OptionModel[] => {
    return bankData.map((bank) => {
      return new OptionModel(bank.displayName, bank.id);
    });
  };

  private readonly setStageSelectedTitle = (requestType: RequestType): string => {
    if (requestType === 'refund') {
      return t('paymentDetailsPage.enterRefundSumTitle');
    }
    if (requestType === 'confirm') {
      return t('paymentDetailsPage.enterConfirmSumTitle');
    }
    if (requestType === 'cancel') {
      return t('paymentDetailsPage.enterCancelSumTitle');
    }
    return '';
  };

  private readonly setApplyButtonTitle = (requestType: RequestType): string => {
    if (requestType === 'refund') {
      return t('paymentDetailsPage.refundTitle');
    }
    if (requestType === 'confirm') {
      return t('paymentDetailsPage.confirmTitle');
    }
    if (requestType === 'cancel') {
      return t('paymentDetailsPage.cancelTitle');
    }
    return '';
  };

  private readonly setStageUnSelectedTitle = (requestType: RequestType): string => {
    if (requestType === 'refund') {
      return t('paymentDetailsPage.selectRefundTitle');
    }
    if (requestType === 'confirm') {
      return t('paymentDetailsPage.selectConfirmTitle');
    }
    if (requestType === 'cancel') {
      return t('paymentDetailsPage.selectCancelTitle');
    }
    return '';
  };
}

export const paymentDetailsPageState = new State();
