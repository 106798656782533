import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { signInFormService } from "service/shared/singletones/signInFormService/signInForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SSignInSendButton } from "./SendButtonStyled/SSignInSendButton.styled";
import { SSignInSendButtonLoader } from "./SendButtonStyled/SSignInSendButtonLoader.styled";

export const SendButton = createComponent("SendButton", () => {
  const isLoaded = useDefaultObservableBoolean(
    signInFormService.state.isLoaded
  );
  const { t } = useTranslation();

  if (isLoaded) {
    return (
      <SSignInSendButtonLoader>
        <Loader small />
      </SSignInSendButtonLoader>
    );
  }

  return (
    <SSignInSendButton
      data-test-id="sign-in"
      onClick={signInFormService.controller.sendForm}
    >
      {t("signInPage.sendButton")}
    </SSignInSendButton>
  );
});
