import { RolesFormContext } from "context/RolesForm.context";
import { createComponent } from "utils/libExtend/createComponent";

import { UsersChangeRowRolesProps } from "./UsersChangeRowRoles.props";
import { UsersChangeRowRolesContent } from "./UsersChangeRowRolesContent/UsersChangeRowRolesContent.component";

export const UsersChangeRowRoles = createComponent<UsersChangeRowRolesProps>("UsersChangeRowRoles", (props) => {
  const { service, hasChange } = props;

  return (
    <RolesFormContext.Provider value={{ service, hasChange }}>
      <UsersChangeRowRolesContent />
    </RolesFormContext.Provider>
  );
});
