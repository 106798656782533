import { ArticleDescriptionInputController } from "service/shared/others/ArticleDescriptionInputService/ArticleDescriptionInput.controller";
import { ArticleDescriptionInputState } from "service/shared/others/ArticleDescriptionInputService/ArticleDescriptionInput.state";

export class ArticleDescriptionInputService {
  public readonly state: ArticleDescriptionInputState;

  public readonly controller: ArticleDescriptionInputController;

  public constructor(initialDescription: string, initialEmail: string, initialType: string) {
    this.state = new ArticleDescriptionInputState(initialDescription, initialEmail, initialType);
    this.controller = new ArticleDescriptionInputController(this.state);
  }
}
