const getFullMonth = (value: number): string => {
  const month = value.toString();

  if (month.length === 2) {
    return month;
  }

  return `0${month}`;
};

export const getCurrentDate = (): string => {
  const nowDate = new Date();

  const date = nowDate.getDate();
  const month = getFullMonth(nowDate.getMonth() + 1);
  const year = nowDate.getFullYear();

  return `${date}.${month}.${year}`;
};
