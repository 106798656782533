import { routerPaths } from "appConstants/routerPaths";
import { DetailsLayout } from "components/DetailsLayout/DetailsLayout.component";
import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { paymentRefundDetailsPageService } from "service/shared/singletones/paymentRefundDetailsPageService/paymentRefundDetailsPage.service";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useParams } from "utils/libReplace/useParams";

import { PaymentRefundDetailsPageContent } from "./PaymentRefundDetailsPageContent/PaymentRefundDetailsPageContent.component";
import { SPaymentRefundDetailsLoader } from "./PaymentRefundDetailsPageStyled/SPaymentRefundDetailsLoader.styled";

export const PaymentRefundDetailsPage = createComponent("PaymentRefundDetailsPage", () => {
  const { t } = useTranslation();
  const { refundId, paymentId } = useParams<{ refundId: string; paymentId: string }>();

  useEffect(async () => {
    paymentRefundDetailsPageService.controller.setRefundId(refundId);
    await paymentRefundDetailsPageService.controller.getRefund();
    return (): void => {
      paymentRefundDetailsPageService.controller.unMounted();
    };
  }, []);

  const loaded: boolean = useDefaultObservableBoolean(paymentRefundDetailsPageService.state.loaded);

  return (
    <DetailsLayout
      header={[
        { title: t("paymentRefundDetailsPage.titlePayment"), src: routerPaths.payments },
        {
          title: t("paymentRefundDetailsPage.titleRefund"),
          src: replacePath(routerPaths.paymentDetails, { paymentId }),
        },
      ]}
      title={`# ${refundId}`}
    >
      {loaded ? (
        <PaymentRefundDetailsPageContent />
      ) : (
        <SPaymentRefundDetailsLoader>
          <Loader />
        </SPaymentRefundDetailsLoader>
      )}
    </DetailsLayout>
  );
});
