export class SignInDto {
  private readonly email: string;

  private readonly password: string;

  public constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}
