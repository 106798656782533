import { CreateUserFetchedSelectController } from "service/shared/others/CreateUserFetchedSelectService/CreateUserFetchedSelect.controller";
import { CreateUserFetchedSelectState } from "service/shared/others/CreateUserFetchedSelectService/CreateUserFetchedSelect.state";

export class CreateUserFetchedSelectService {
  public readonly state: CreateUserFetchedSelectState;

  public readonly controller: CreateUserFetchedSelectController;

  public constructor(fetchDataFunction: () => Promise<any[]>, title: string, message: string) {
    this.state = new CreateUserFetchedSelectState(fetchDataFunction, title, message);
    this.controller = new CreateUserFetchedSelectController(this.state);
  }
}
