import { css, setFlexDF, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  align-self: flex-end;
`);

export const SCreateFormButtons = styled.div<Props>`
  margin-top: 20px;
  padding: 0 20px;
  gap: 10px;

  ${setFlexDF};
  ${isArabicLang};
`;
