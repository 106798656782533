import { css, setFlexDF, setProps, styled } from "theme";

type SRangeInputContainerProps = {
  isArabic: boolean;
};

const isArabicLang = setProps<SRangeInputContainerProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SRangeInputContainer = styled.div<SRangeInputContainerProps>`
  ${setFlexDF};
  ${isArabicLang};
`;
