import { styled } from "theme/default/styled";
import { color_rgba_0_0_0_87 } from "theme/setter/opacityColorsValues/color_rgba_0_0_0_87";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";

export const SRolesSelectOptionCheckBox = styled.div`
  width: 20px;
  height: 20px;

  border: 1px solid ${color_rgba_0_0_0_87};

  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBorderRadius_4px};
`;
