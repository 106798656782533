import { getSource } from "./getSource";

export const getRatioOptions = (dateArray: string[], successQuantityArray: number[], failQuantityArray: number[]): any => {
  const source = getSource(dateArray, successQuantityArray, failQuantityArray);

  return {
    legend: {},
    tooltip: {},
    dataset: { source },
    xAxis: { type: "category" },
    yAxis: {},
    dataZoom: [
      {
        type: "inside",
        throttle: 50,
      },
    ],
    series: [{ type: "bar" }, { type: "bar" }],
  };
};
