import { borderRadiusValues } from "theme/values/borderRadiusValues";
import { colorsOpacityValues } from "theme/values/colorsOpacityValues";
import { colorsValues } from "theme/values/colorsValues";
import { fontSizeValues } from "theme/values/fontSizeValues";
import { fontWeightValues } from "theme/values/fontWeightValues";

export const themeValues = {
  colors: {
    ...colorsValues,
    ...colorsOpacityValues,
  },
  fontSize: {
    ...fontSizeValues,
  },
  borderRadius: {
    ...borderRadiusValues,
  },
  fontWeightValues: {
    ...fontWeightValues,
  },
};
