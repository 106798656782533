import { useDefaultObservableConfigModelArray } from "observables/ConfigModelArrayObservable";
import { groupTableBodyService } from "service/shared/singletones/groupTableBodyService/groupTableBody.service";
import { ConfigModel } from "types/business/ConfigModel";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { GroupUserProps } from "./GroupUser.props";
import { GroupUserCell } from "./GroupUserCell/GroupUserCell.component";
import { SGroupUser } from "./GroupUserStyled/SGroupUser.styled";

export const GroupUser = createComponent<GroupUserProps>("GroupUser", (props) => {
  const { groupUserModel, orderNumber } = props;

  const configModel = useDefaultObservableConfigModelArray(groupTableBodyService.state.configList);

  const renderGroupUserCell: RenderFunctionType<ConfigModel> = (groupUserCellModel, index) => {
    return <GroupUserCell groupUserModel={groupUserModel} key={index} isFirst={index === 0} groupUserCellModel={groupUserCellModel} />;
  };
  return <SGroupUser isFirst={orderNumber === 0}>{configModel.map(renderGroupUserCell)}</SGroupUser>;
});
