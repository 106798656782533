export const colorsValuesFin = {
  color_0083f5: "#2AC77B",
  color_00346e: "#2C3246",
  color_69809a: "#19AC65",
  color_25476d: "#218C74",
  color_9f3a38: "#9f3a38",
  color_8bc3f5: "#2C3246",
  color_4183c4: "#218C74",
  color_7b7b7b: "#7b7b7b",
  color_fff6f6: "#fff6f6",
  color_4d4d4d: "#4d4d4d",
  color_f7faff: "#F7FAFB",
  color_93a5b8: "#93a5b8",
  color_ffffff: "#ffffff",
  color_2b3e50: "#2C3246",
  color_2d3033: "#2d3033",
  color_c4edff: "#62F8B0",
  color_3a8fda: "#19AC65",
  color_1839d5: "#218C74",
  color_16e527: "#16e527",
  color_f0fbff: "#f0fbff",
  color_e74c3c: "#e74c3c",
  color_1e70bf: "#2AC77B",
  color_a0b9d2: "#218C74",
  color_b7c8dc: "#b7c8dc",
  color_458cce: "#2C3246",
  color_f6b57a: "#f6b57a",
  color_e7ecf2: "#e7ecf2",
  color_e0b4b4: "#e0b4b4",
  color_47a0ef: "#2AC77B",
  color_f5f5f5: "#f5f5f5",
  color_e8e8e8: "#e8e8e8",
  main: {
    menu_text: "#2AC77B",
    icon_fill: "#19AC65",
    container_text: "#218C74",
  },
};
