import { css, setFlexDFAIC, setProps, styled } from "theme";

type SAddFilterButtonProps = {
  filterListIsEmpty: boolean;
  isArabic: boolean;
};

const filterListIsEmptySet = setProps<SAddFilterButtonProps>(({ filterListIsEmpty }) => filterListIsEmpty)(css`
  cursor: default;
  opacity: 0.45;
`);

const isArabicLang = setProps<SAddFilterButtonProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SAddFilterButton = styled.button<SAddFilterButtonProps>`
  margin-top: 12px;

  ${setFlexDFAIC};
  ${filterListIsEmptySet};
  ${isArabicLang};
`;
