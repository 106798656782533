import { useSupportArticleContext } from "context/SuportArticle.context";
import { useDefaultObservableString } from "observables/StringObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { SSupportArticleEmailInput } from "./SupportArticleEmailInputStyled/SSupportArticleEmailInput.styled";

export const SupportArticleEmailInput = createComponent("SupportArticleEmailInput", () => {
  const { service } = useSupportArticleContext();

  const emailValue = useDefaultObservableString(service.state.emailValue);

  return <SSupportArticleEmailInput value={emailValue} onChange={service.controller.onChangeEmailValue} />;
});
