import { ForgotPasswordInputState } from "service/shared/others/ForgotPasswordInputService/ForgotPasswordInput.state";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

export class ForgotPasswordInputController {
  public readonly state: ForgotPasswordInputState;

  public readonly onChange = onChangeHelper((text: string): void => {
    this.state.value.next(text);
  });

  public constructor(state: ForgotPasswordInputState) {
    this.state = state;
  }

  public readonly clearForm = (): void => {
    this.state.value.next("");
  };

  public readonly setShowError = (showError: boolean): void => {
    this.state.showError.next(showError);
  };
}
