import { borderRadius_5px, color_rgba_183_200_220_80, css, setBackgroundColor_f7faff, setFlexDFAIC, setProps, styled } from "theme";

type SNavigationMenuTogglerProps = {
  isArabic: boolean;
};

const isArabicLang = setProps<SNavigationMenuTogglerProps>(({ isArabic }) => isArabic)(css`
  border-top-left-radius: ${borderRadius_5px};
  border-bottom-left-radius: ${borderRadius_5px};
  justify-content: flex-start;
`);
const isNotArabicLang = setProps<SNavigationMenuTogglerProps>(({ isArabic }) => !isArabic)(css`
  border-top-right-radius: ${borderRadius_5px};
  border-bottom-right-radius: ${borderRadius_5px};
  justify-content: flex-end;
`);

export const SNavigationMenuToggler = styled.button<SNavigationMenuTogglerProps>`
  height: 40px;
  margin-bottom: 12px;

  box-shadow: 0 0 5px ${color_rgba_183_200_220_80};
  ${isArabicLang};
  ${isNotArabicLang};
  ${setFlexDFAIC};
  ${setBackgroundColor_f7faff};
`;
