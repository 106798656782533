import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { RolesFormApplyButton } from "./RolesFormApplyButton/RolesFormApplyButton.component";
import { RolesFormBackButton } from "./RolesFormBackButton/RolesFormBackButton.component";
import { SRolesSelectFormContentButtons } from "./RolesSelectFormContentStyled/SRolesSelectFormContentButtons.styled";
import { SRolesSelectFormContentTitle } from "./RolesSelectFormContentStyled/SRolesSelectFormContentTitle.styled";
import { RolesSelectFormList } from "./RolesSelectFormList/RolesSelectFormList.component";

export const RolesSelectFormContent = createComponent("RolesSelectFormContent", () => {
  const { t } = useTranslation();
  return (
    <>
      <SRolesSelectFormContentTitle>{t("usersDetailsPage.rolesSelectFormTitle")}</SRolesSelectFormContentTitle>
      <RolesSelectFormList />
      <SRolesSelectFormContentButtons>
        <RolesFormApplyButton />
        <RolesFormBackButton />
      </SRolesSelectFormContentButtons>
    </>
  );
});
