import { routerPaths } from "appConstants/routerPaths";
import { useTranslation } from "react-i18next";
import { forgotPasswordFormService } from "service/shared/singletones/forgotPasswordFormService/forgotPasswordForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SForgotPasswordForm } from "./ForgotPasswordPageStyled/SForgotPasswordForm.styled";
import { SForgotPasswordFormEmail } from "./ForgotPasswordPageStyled/SForgotPasswordFormEmail.styled";
import { SForgotPasswordFormHeader } from "./ForgotPasswordPageStyled/SForgotPasswordFormHeader.styled";
import { SForgotPasswordFormLink } from "./ForgotPasswordPageStyled/SForgotPasswordFormLink.styled";
import { SendButton } from "./SendButton/SendButton.component";

export const ForgotPasswordPage = createComponent("ForgotPasswordPage", () => {
  const { t } = useTranslation();
  return (
    <SForgotPasswordForm>
      <SForgotPasswordFormHeader>{t("forgotPasswordPage.title")}</SForgotPasswordFormHeader>
      <SForgotPasswordFormEmail
        dataTestId="email"
        type="email"
        name="email"
        placeholder={t("forgotPasswordPage.emailPlaceholder")}
        service={forgotPasswordFormService.state.emailService}
      />
      <SForgotPasswordFormLink data-test-id="back" to={routerPaths.signIn}>
        {t("forgotPasswordPage.backButton")}
      </SForgotPasswordFormLink>
      <SendButton />
    </SForgotPasswordForm>
  );
});
