import { userService } from "api/userService";
import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { PaymentsDetailsCell } from "detailsCell/PaymentsDetailsCell/PaymentsDetailsCell.component";
import { t } from "i18n";
import { HeaderCellModel } from "model/HeaderCell.model";
import { InvoiceDetailsModel } from "model/InvoiceDetails.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectInvoiceDetailsModelNull } from "observables/InvoiceDetailsModelNullObservable";
import { BehaviorSubjectInvoicePaymentArray } from "observables/InvoicePaymentArrayObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { TableService } from "service/shared/others/TableService/Table.service";
import { InvoicePayment } from "types/business/InvoicePayment";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class State {
  public readonly refreshTime: number = 30 * 1000;

  public readonly invoice: BehaviorSubjectInvoiceDetailsModelNull;

  public readonly loaded: BehaviorSubjectBoolean;

  public readonly tableService: BehaviorSubjectTableService;

  public invoiceId!: string;

  private readonly initialHeaderCellList: HeaderCellModel[];

  private readonly payments: BehaviorSubjectInvoicePaymentArray;

  public constructor() {
    this.loaded = new BehaviorSubjectBoolean(false);
    this.invoice = new BehaviorSubjectInvoiceDetailsModelNull(null);
    this.initialHeaderCellList = this.getInitialHeaderCellList();
    this.payments = setCombinedBehaviorSubject(this.setPayments, this.invoice);
    this.tableService = setCombinedBehaviorSubject(this.setTableService, this.payments);
  }

  private readonly getInitialHeaderCellList = (): HeaderCellModel[] => {
    const initialHeaderCellList = [
      getInitialHeaderCell(initialHeaderCellKey.paymentId, t("invoiceDetailsPageConfig.paymentId"), "invoiceDetailsPageConfig.paymentId", {
        width: tableWidths.width350,
      }),
      getInitialHeaderCell(initialHeaderCellKey.status, t("invoiceDetailsPageConfig.status"), "invoiceDetailsPageConfig.status", {
        width: tableWidths.width250,
      }),
    ];

    const canGetPaymentInfo = userService.canGetPaymentInfo();

    if (canGetPaymentInfo) {
      initialHeaderCellList.unshift(
        getInitialHeaderCell(initialHeaderCellKey.details, t("invoiceDetailsPageConfig.details"), "invoiceDetailsPageConfig.details", {
          isDetails: true,
          Element: PaymentsDetailsCell,
          width: tableWidths.width120,
        })
      );
    }

    return initialHeaderCellList;
  };

  private readonly setPayments = (invoice: InvoiceDetailsModel | null): InvoicePayment[] => {
    if (invoice) {
      return invoice.payments.map((payment) => {
        return {
          paymentId: payment.paymentId,
          status: payment.status,
        };
      });
    }
    return [];
  };

  private readonly setTableService = (payments: InvoicePayment[]): TableService => {
    return new TableService(payments, this.initialHeaderCellList);
  };
}

export const invoiceDetailsPageState = new State();
