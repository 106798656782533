import { styled } from "theme/default/styled";
import { setColor_4d4d4d } from "theme/setter/setColorsValues/setColor_4d4d4d";
import { setFontSize_12px } from "theme/setter/setFontSizeValues/setFontSize_12px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";

export const SSignInCapsLockErrorFormTitle = styled.div`
  ${setFontSize_12px};
  ${setFontWeight400};
  ${setColor_4d4d4d};
`;
