import { styled } from "theme/default/styled";
import { setFontSize_18px } from "theme/setter/setFontSizeValues/setFontSize_18px";
import { setFontWeight700 } from "theme/setter/setFontWeightValues/setFontWeight700";
import { setColor_rgba_0_0_0_87 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_87";

export const SRolesSelectFormContentTitle = styled.div`
  text-align: center;
  margin-bottom: 24px;

  ${setFontSize_18px};
  ${setFontWeight700};
  ${setColor_rgba_0_0_0_87};
`;
