import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { borderRadius_5px } from "theme/setter/borderRadiusValues/borderRadius_5px";
import { color_93a5b8 } from "theme/setter/colorsValues/color_93a5b8";
import { color_ffffff } from "theme/setter/colorsValues/color_ffffff";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";
import { setFontWeight700 } from "theme/setter/setFontWeightValues/setFontWeight700";
import { setColor_rgba_0_131_245_67 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_131_245_67";

type SPaymentTableSwitchProps = {
  active: boolean;
};

const activeSet = setProps<SPaymentTableSwitchProps>(({ active }) => active)(css`
  margin-bottom: -1px;
  padding-bottom: 11px;

  border-top-color: ${color_93a5b8};
  border-right-color: ${color_93a5b8};
  border-bottom-color: ${color_ffffff};
  border-left-color: ${color_93a5b8};

  ${setFontWeight700};
  ${setColor_0083f5};
  ${setBackgroundColor_ffffff};
`);

export const SPaymentTableSwitch = styled.button<SPaymentTableSwitchProps>`
  z-index: 1;
  padding: 10px 20px;
  outline: none;
  background-color: transparent;

  border-top: 1px solid ${color_ffffff};
  border-right: 1px solid ${color_ffffff};
  border-bottom: 1px solid ${color_ffffff};
  border-left: 1px solid ${color_ffffff};
  border-top-left-radius: ${borderRadius_5px};
  border-top-right-radius: ${borderRadius_5px};

  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_rgba_0_131_245_67};
  ${activeSet};
`;
