import { refundDetailsPageController } from "service/shared/singletones/refundDetailsPageService/refundDetailsPage.controller";
import { refundDetailsPageState } from "service/shared/singletones/refundDetailsPageService/refundDetailsPage.state";

class Service {
  public readonly state = refundDetailsPageState;

  public readonly controller = refundDetailsPageController;
}

export const refundDetailsPageService = new Service();
