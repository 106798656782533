import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectGroupModelNull } from "observables/GroupModelNullObservable";

class State {
  public readonly loaded: BehaviorSubjectBoolean;

  public userData!: BehaviorSubjectGroupModelNull;

  public constructor() {
    this.userData = new BehaviorSubjectGroupModelNull(null);
    this.loaded = new BehaviorSubjectBoolean(false);
  }
}

export const usersInfoPageState = new State();
