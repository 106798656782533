/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const PaginationRightDoubleArrow = createIcon("PaginationRightDoubleArrow", (props) => {
  const { width = 15, height = 13, color = "#69809A" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 15 14" fill="none">
      <path
        d="M7.86375 6.81688L2.27395 1.22726C1.91837 0.87151 1.34186 0.87151 0.98645 1.22726C0.631015 1.5827 0.631015 2.15918 0.98645 2.51458L5.93256 7.46054L0.986599 12.4063C0.631164 12.7619 0.631164 13.3383 0.986599 13.6938C1.34203 14.0493 1.91852 14.0493 2.27409 13.6938L7.8639 8.10406C8.04161 7.92625 8.13037 7.69347 8.13037 7.46057C8.13037 7.22755 8.04144 6.9946 7.86375 6.81688Z"
        fill={color}
      />
      <path
        d="M14.4237 6.81688L8.83394 1.22726C8.47836 0.87151 7.90185 0.87151 7.54645 1.22726C7.19101 1.5827 7.19101 2.15918 7.54645 2.51458L12.4926 7.46054L7.5466 12.4063C7.19116 12.7619 7.19116 13.3383 7.5466 13.6938C7.90203 14.0493 8.47851 14.0493 8.83409 13.6938L14.4239 8.10406C14.6016 7.92625 14.6904 7.69347 14.6904 7.46057C14.6904 7.22755 14.6014 6.9946 14.4237 6.81688Z"
        fill={color}
      />
    </svg>
  );
});
