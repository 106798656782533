import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectCreateFieldServiceArray } from "observables/CreateFieldServiceArrayObservable";
import { BehaviorSubjectOptionModel } from "observables/OptionModelObservable";
import { InvoiceSelectService } from "service/shared/others/InvoiceSelectService/InvoiceSelect.service";

class State {
  public readonly open: BehaviorSubjectBoolean;

  public readonly loaded: BehaviorSubjectBoolean;

  public fields!: BehaviorSubjectCreateFieldServiceArray;

  public terminalField!: InvoiceSelectService;

  public terminalOption!: BehaviorSubjectOptionModel;

  public readonly mount: BehaviorSubjectBoolean;

  public constructor() {
    this.open = new BehaviorSubjectBoolean(false);
    this.loaded = new BehaviorSubjectBoolean(false);
    this.mount = new BehaviorSubjectBoolean(false);
  }
}

export const invoiceCreateFormState = new State();
