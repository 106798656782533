import { SvgImage } from "components/SvgImage/SvgImage.component";
import { createComponent } from "utils/libExtend/createComponent";

import { ImgButtonProps } from "./ImgButton.props";
import { SImgButton } from "./ImgButtonStyled/SImgButton.styled";

export const ImgButton = createComponent<ImgButtonProps>("ImgButton", (props) => {
  const { active = true, name, onClick } = props;

  return (
    <SImgButton active={active} disabled={!active} onClick={onClick}>
      <SvgImage name={name} />
    </SImgButton>
  );
});
