import { groupTableBodyController } from "service/shared/singletones/groupTableBodyService/groupTableBody.controller";
import { groupTableBodyState } from "service/shared/singletones/groupTableBodyService/groupTableBody.state";

class Service {
  public readonly state = groupTableBodyState;

  public readonly controller = groupTableBodyController;
}

export const groupTableBodyService = new Service();
