import { styled } from "theme/default/styled";
import { setColor_ffffff } from "theme/setter/setColorsValues/setColor_ffffff";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";

export const SNavigationMenuIconLabelTitle = styled.div`
  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_ffffff};
`;
