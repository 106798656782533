import { css, setColor_0083f5, setFlexDFAIC, setFontSize_14px, setFontWeight600, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  left: 16px;
  flex-direction: row-reverse;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  right: 16px;
`);

export const SFormOpenButton = styled.button<Props>`
  position: absolute;
  top: 0;
  margin-top: 15px;
  padding: 4px 0;
  gap: 10px;

  ${setFlexDFAIC};
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_0083f5};
  ${isArabicLang};
  ${isNotArabicLang};
`;
