import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { ChangePasswordInputProps } from "./ChangePasswordInput.props";
import { SUsersChangePasswordInput } from "./UsersChangePasswordInputStyled/SUsersChangePasswordInput.styled";

export const ChangePasswordInput = createComponent<ChangePasswordInputProps>("ChangePasswordInput", (props) => {
  const { service } = props;

  const value = useDefaultObservableString(service.state.value);
  const showError = useDefaultObservableBoolean(service.state.showError);

  return (
    <SUsersChangePasswordInput
      showError={showError}
      onFocus={service.controller.onFocus}
      onBlur={service.controller.onBlur}
      placeholder={service.state.placeholder}
      type="password"
      value={value}
      onChange={service.controller.onChange}
    />
  );
});
