import { useRolesFormContext } from "context/RolesForm.context";
import { createComponent } from "utils/libExtend/createComponent";

import { UsersChangeRowRolesCommon } from "./UsersChangeRowRolesCommon/UsersChangeRowRolesCommon.component";
import { UsersChangeRowRolesInput } from "./UsersChangeRowRolesInput/UsersChangeRowRolesInput.component";

export const UsersChangeRowRolesContent = createComponent("UsersChangeRowRolesContent", () => {
  const { hasChange } = useRolesFormContext();

  if (hasChange) {
    return <UsersChangeRowRolesInput />;
  }

  return <UsersChangeRowRolesCommon />;
});
