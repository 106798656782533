import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { createArticleFormService } from "service/shared/singletones/createArticleFormService/createArticleForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SArticleFormApplyButton } from "./ArticleFormApplyButtonStyled/SArticleFormApplyButton.styled";

export const ArticleFormApplyButton = createComponent("ArticleFormApplyButton", () => {
  const { t } = useTranslation();
  const isFormValid = useDefaultObservableBoolean(createArticleFormService.state.isFormValid);

  return <SArticleFormApplyButton isFormValid={isFormValid}>{t("supportAdministrationPage.articleFormApplyButtonTitle")}</SArticleFormApplyButton>;
});
