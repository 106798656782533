import { css, resetScrollbar, setBackgroundColor_ffffff, setBorderRadius_15px, setFlexDFFDC, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SInvoiceCreateForm = styled.div<Props>`
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  width: 400px;
  height: 620px;
  padding: 24px 12px;
  transform: translate(-50%, -50%);

  ${setFlexDFFDC};
  ${resetScrollbar};
  ${setBorderRadius_15px};
  ${setBackgroundColor_ffffff};
  ${isArabicLang};
`;
