import { Dictionary } from "types/commonExtend/Dictionary";

export const clearQueriesField = (object: Dictionary<string>): Dictionary<string> => {
  const newObject = {};

  Object.keys(object).forEach((key) => {
    const value = object[key];
    if (value) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newObject[key] = value;
    }
  });

  return newObject;
};
