import { ArticleDescriptionInputService } from "service/shared/others/ArticleDescriptionInputService/ArticleDescriptionInput.service";
import { createContext } from "utils/libReplace/createContext";
import { useContext } from "utils/libReplace/useContext";

export type SupportArticleContextModel = {
  service: ArticleDescriptionInputService;
};

export const SupportArticleContext = createContext<SupportArticleContextModel>({} as SupportArticleContextModel);

export const useSupportArticleContext = (): SupportArticleContextModel => useContext(SupportArticleContext);
