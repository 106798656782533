import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useEffect } from "packages/react";
import { invoiceCreateFormService } from "service/shared/singletones/invoiceCreateFormService/invoiceCreateForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { Buttons } from "../Buttons/Buttons.component";
import { List } from "../List/List.component";
import { SCreateFormContentLoader } from "./CreateFormContentStyled/SCreateFormContentLoader.styled";

export const CreateFormContent = createComponent("CreateFormContent", () => {
  const loaded = useDefaultObservableBoolean(invoiceCreateFormService.state.loaded);
  const mount = useDefaultObservableBoolean(invoiceCreateFormService.state.mount);

  useEffect(() => {
    invoiceCreateFormService.controller.mounted();
  }, []);

  useEffect(() => {
    return (): void => {
      if (mount) {
        invoiceCreateFormService.controller.unMounted();
      }
    };
  }, [mount]);

  if (!loaded) {
    return (
      <SCreateFormContentLoader>
        <Loader />
      </SCreateFormContentLoader>
    );
  }

  return (
    <>
      <List />
      {/* <InfoItemsTable /> */}
      <Buttons />
    </>
  );
});
