import { FilterModel } from "model/Filter.model";
import { FiltersController } from "service/shared/others/FiltersService/Filters.controller";
import { FiltersState } from "service/shared/others/FiltersService/Filters.state";

type ParamsType = {
  showCloseIcon: boolean;
  initialOpen: boolean;
  initialArabic: boolean;
};

export class FiltersService {
  public readonly state: FiltersState;

  public readonly controller: FiltersController;

  public constructor(filterConfig: FilterModel[], applyCallBack: () => void, params?: ParamsType) {
    this.state = new FiltersState(filterConfig, applyCallBack, params);
    this.controller = new FiltersController(this.state);
  }
}
