import { amountFilterModel } from "config/filterModels/amountFilterModel";
import { authCodeFilterModel } from "config/filterModels/authCodeFilterModel";
import { cardHolderFilterModel } from "config/filterModels/cardHolderFilterModel";
import { cardNumberFilterModel } from "config/filterModels/cardNumberFilterModel";
import { dateFilterModel } from "config/filterModels/dateFilterModel";
import { paymentSystemTypeFilterModel } from "config/filterModels/paymentSystemTypeFilterModel";
import { refundIdFilterModel } from "config/filterModels/refundIdFilterModel";
import { refundPaymentFilterModel } from "config/filterModels/refundPaymentFilterModel";
import { requestIdFilterModel } from "config/filterModels/requestIdFilterModel";
import { resultCodeFilterModel } from "config/filterModels/resultCodeFilterModel";
import { rrnFilterModel } from "config/filterModels/rrnFilterModel";
import { succesfullyFilterModel } from "config/filterModels/succesfullyFilterModel";
import { terminalFilterModel } from "config/filterModels/terminalFilterModel";
import { FilterModel } from "model/Filter.model";

export const refundsFilterConfig: FilterModel[] = [
  dateFilterModel,
  refundPaymentFilterModel,
  cardHolderFilterModel,
  cardNumberFilterModel,
  paymentSystemTypeFilterModel,
  rrnFilterModel,
  authCodeFilterModel,
  requestIdFilterModel,
  terminalFilterModel,
  refundIdFilterModel,
  succesfullyFilterModel,
  amountFilterModel,
  resultCodeFilterModel,
];
