import { css, setBorderRadius_4px, setProps } from 'theme';
import { styled } from 'theme/default/styled';

interface SCustomSelectInputContainerProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SCustomSelectInputContainerProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SCustomSelectInputContainer = styled.div<SCustomSelectInputContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;

  ${isArabicLang}
  ${setBorderRadius_4px};
`;
