import { invoiceCreateFormController } from "service/shared/singletones/invoiceCreateFormService/invoiceCreateForm.controller";
import { invoiceCreateFormState } from "service/shared/singletones/invoiceCreateFormService/invoiceCreateForm.state";

class Service {
  public readonly state = invoiceCreateFormState;

  public readonly controller = invoiceCreateFormController;
}

export const invoiceCreateFormService = new Service();
