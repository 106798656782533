import { OptionModel } from "model/Option.model";
import { CreateInvoiceDto } from "types/business/CreateInvoiceDto";

export class CreateInvoiceDtoModel {
  private readonly amount: number;

  private readonly phone: number;

  private readonly paymentWay: OptionModel;

  private readonly description: string;

  private readonly terminalId: OptionModel;

  private readonly title: string;

  private readonly currencyCode: OptionModel;

  private readonly email: string;

  public constructor(
    amount: number,
    phone: number,
    paymentWay: OptionModel,
    description: string,
    terminalId: OptionModel,
    title: string,
    currencyCode: OptionModel,
    email: string
  ) {
    this.amount = amount;
    this.phone = phone;
    this.paymentWay = paymentWay;
    this.description = description;
    this.terminalId = terminalId;
    this.title = title;
    this.currencyCode = currencyCode;
    this.email = email;
  }

  public readonly getQuery = (): CreateInvoiceDto => {
    return {
      amount: this.amount,
      email: this.email,
      phone: this.phone,
      title: this.title,
      paymentWay: !!this.paymentWay.value ? this.paymentWay.value : null,
      terminalId: this.terminalId.value,
      description: this.description,
      currencyCode: this.currencyCode.value,
      customer: { email: this.email, phone: this.phone },
      itemsInfo: { description: this.description, title: this.title }
    };
  };
}
