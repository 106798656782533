import { useTranslation } from "react-i18next";
import { transferDetailsPageService } from "service/shared/singletones/transferDetailsPageService/transferDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SCancelTransferButton } from "./CancelTransferButtonStyled/SCancelTransferButton.styled";

export const CancelTransferButton = createComponent("CancelTransferButton", () => {
  const { t } = useTranslation();
  return (
    <SCancelTransferButton onClick={transferDetailsPageService.controller.cancelTransfer}>
      {t("transferDetailsPage.cancelTransferButtonTitle")}
    </SCancelTransferButton>
  );
});
