import { redirectState } from "service/common/redirectService/redirect.state";

class Controller {
  public readonly state = redirectState;

  public readonly setIsRedirected = (): void => {
    this.state.isRedirected.next(true);
  };

  public readonly setCurrentRedirectPage = (currentRedirectPage: string): void => {
    this.state.currentRedirectPage.next(currentRedirectPage);
    this.state.isRedirected.next(false);
  };
}

export const redirectController = new Controller();
