import { css, setFlexDF, setProps, styled } from "theme";

type SFilterButtonButtonProps = {
  isArabic: boolean;
};

const isArabicLang = setProps<SFilterButtonButtonProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
  padding-right: 8px;
`);

const isNotArabicLang = setProps<SFilterButtonButtonProps>(({ isArabic }) => !isArabic)(css`
  padding-left: 8px;
`);

export const SFilterButtonButton = styled.button<SFilterButtonButtonProps>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFlexDF};
`;
