import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { statisticPageService } from "service/shared/singletones/statisticPageService/statisticPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { StatisticChartContent } from "./StatisticChartContent/StatisticChartContent.component";

export const StatisticChart = createComponent("StatisticChart", () => {
  const loaded = useDefaultObservableBoolean(statisticPageService.state.loaded);
  const fetched = useDefaultObservableBoolean(statisticPageService.state.fetched);

  return loaded && fetched && <StatisticChartContent />;
});
