import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useRolesFormContext } from "context/RolesForm.context";
import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";

import { RolesSelectForm } from "./RolesSelectForm/RolesSelectForm.component";
import { SUsersChangeRowRolesInput } from "./UsersChangeRowRolesInputStyled/SUsersChangeRowRolesInput.styled";
import { SUsersChangeRowRolesInputIcon } from "./UsersChangeRowRolesInputStyled/SUsersChangeRowRolesInputIcon.styled";
import { SUsersChangeRowRolesInputTitle } from "./UsersChangeRowRolesInputStyled/SUsersChangeRowRolesInputTitle.styled";
import { SUsersChangeRowRolesInputValue } from "./UsersChangeRowRolesInputStyled/SUsersChangeRowRolesInputValue.styled";

export const UsersChangeRowRolesInput = createComponent("UsersChangeRowRolesInput", () => {
  const { t, i18n } = useTranslation();
  const { service } = useRolesFormContext();
  const isArabic = i18n.language === "ar";

  const titledValue = useDefaultObservableString(service.state.titledValue);
  const open = useDefaultObservable<boolean>(service.state.open);

  return (
    <>
      <SUsersChangeRowRolesInput isArabic={isArabic}>
        <SUsersChangeRowRolesInputTitle>{t("usersDetailsPage.roles")}</SUsersChangeRowRolesInputTitle>
        <SUsersChangeRowRolesInputValue>{titledValue}</SUsersChangeRowRolesInputValue>
        <SUsersChangeRowRolesInputIcon onClick={service.controller.onClickEdit}>
          <SvgImage name="EditIcon" />
        </SUsersChangeRowRolesInputIcon>
      </SUsersChangeRowRolesInput>

      {open && <RolesSelectForm />}
    </>
  );
});
