import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { routerPaths } from "appConstants/routerPaths";
import { OptionModel } from "model/Option.model";
import { redirectService } from "service/common/redirectService/redirect.service";
import { createUserServiceFormState } from "service/shared/singletones/createUserServiceFormService/createUserServiceForm.state";
import { MouseCustomEvent } from "types/libExtend/MouseCustomEvent";
import { replacePath } from "utils/commonExtend/replacePath";

import { createUserRolesSelectFormService } from "../createUserRolesSelectFormService/createUserRolesSelectForm.service";

class Controller {
  public readonly state = createUserServiceFormState;

  public readonly onClickCreateUserInviteButton = async (event: MouseCustomEvent): Promise<void> => {
    event.preventDefault();

    let hasError = false;

    if (!this.state.emailService.state.valid.value) {
      this.state.emailService.state.showError.next(true);
      hasError = true;
    } else {
      this.state.emailService.state.showError.next(false);
    }

    if (!this.state.nameService.state.valid.value) {
      this.state.nameService.state.showError.next(true);
      hasError = true;
    } else {
      this.state.nameService.state.showError.next(false);
    }

    if (!this.state.merchantGroupIdService.state.valid.value) {
      this.state.merchantGroupIdService.state.showError.next(true);
      hasError = true;
    } else {
      this.state.merchantGroupIdService.state.showError.next(false);
    }

    if (!this.state.phoneNumberService.state.valid.value) {
      this.state.phoneNumberService.state.showError.next(true);
      hasError = true;
    } else {
      this.state.phoneNumberService.state.showError.next(false);
    }

    if (this.state.roles.value.length === 0) {
      createUserRolesSelectFormService.state.showError.next(true);
      hasError = true;
    } else {
      createUserRolesSelectFormService.state.showError.next(false);
    }

    if (!hasError) {
      const user = await this.createUserInvitation();
      redirectService.controller.setCurrentRedirectPage(
        replacePath(routerPaths.usersDetailsPage, {
          userId: user.id,
        })
      );
      this.resetForm();
    }
  };

  public readonly onClickCloseForm = (): void => {
    this.state.open.next(false);
  };

  public readonly onClickOpenFormButton = (): void => {
    this.state.open.next(true);
  };

  private readonly createUserInvitation = async (): Promise<any> => {
    const { data } = await client.post(apiPaths.adminUserCreate, {
      email: this.state.emailService.state.value.value,
      name: this.state.nameService.state.value.value,
      merchantGroupId: +this.state.merchantGroupIdService.state.value.value.value,
      phoneNumber: this.state.phoneNumberService.state.value.value,
      roles: this.state.roles.value.map((element) => element.title),
    });

    return data;
  };

  private readonly resetForm = (): void => {
    this.state.emailService.state.value.next("");
    this.state.nameService.state.value.next("");
    this.state.merchantGroupIdService.state.value.next(new OptionModel("", ""));
    this.state.phoneNumberService.state.value.next("");
    this.state.roles.next([]);
    createUserRolesSelectFormService.state.selectedOptions.next([]);
  };
}

export const createUserServiceFormController = new Controller();
