import { css } from "packages/styled-components";
import { Input } from "pages/SignInPage/Input/Input.component";
import { styled } from "theme/default/styled";

const isFinOn = process.env.REACT_APP_FIN === "fin";
const finOn = css`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 18px;
`;
const sngb = css`
  margin-top: 20px;
  margin-bottom: 5px;
`;
export const SSignInEmailInput = styled(Input)<{ $isArabic?: boolean }>`
  ${isFinOn ? finOn : sngb};
  text-align: ${({ $isArabic }) => $isArabic && "right"};
`;
