import { css, setProps, styled } from "theme";

interface SFilterInputDeleteProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterInputDeleteProps>(({ isArabic }) => isArabic)(css`
  margin-right: 8px;
`);

const isNotArabicLang = setProps<SFilterInputDeleteProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 8px;
`);

export const SFilterInputDelete = styled.button<SFilterInputDeleteProps>`
  ${isArabicLang};
  ${isNotArabicLang};
`;
