import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_e7ecf2 } from "theme/setter/colorsValues/color_e7ecf2";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";

type SGroupUserProps = {
  isFirst: boolean;
};

const isFirstSet = setProps<SGroupUserProps>(({ isFirst }) => isFirst)(css`
  border-top: none;
`);

export const SGroupUser = styled.div<SGroupUserProps>`
  padding: 4px 0;

  border-top: 1px solid ${color_e7ecf2};

  ${setFlexDF};
  ${isFirstSet};
`;
