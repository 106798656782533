import { css, setFlexDFAIC, setFontSize_18px, setFontWeight400, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  justify-content: flex-start;
  flex-direction: row-reverse;
`);

export const SDetailsLayoutHeaderContainer = styled.div<Props>`
  margin-bottom: 20px;

  ${setFlexDFAIC};
  ${setFontSize_18px};
  ${setFontWeight400};
  ${isArabicLang};
`;
