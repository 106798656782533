import { routerPaths } from "appConstants/routerPaths";
import { userRolesConfig } from "appConstants/userRolesConfig";

export const initialRolesRedirectArray = {
  [userRolesConfig.R_REWARD]: routerPaths.rewards,
  [userRolesConfig.R_OPERATION]: routerPaths.payments,
  [userRolesConfig.R_STATISTICS]: routerPaths.statistic,
  [userRolesConfig.R_INVOICE]: routerPaths.invoices,
  [userRolesConfig.R_REFUND]: routerPaths.refunds,
  [userRolesConfig.R_TRANSFER]: routerPaths.transfers,
  [userRolesConfig.ADMINISTRATOR]: routerPaths.payments,
};
