/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const H2H = createIcon("H2H", (props) => {
  const { width = 22, height = 22, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#90C5F5" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none">
      <path
        d="M3.28882 12.2825C3.28882 11.8381 3.64912 11.4778 4.09357 11.4778H4.52378C4.96823 11.4778 5.32854 11.8381 5.32854 12.2825C5.32854 12.727 4.96823 13.0873 4.52378 13.0873H4.09357C3.64912 13.0873 3.28882 12.727 3.28882 12.2825Z"
        fill={color}
      />
      <path
        d="M9.73413 16.4145C9.73413 15.97 10.0944 15.6097 10.5389 15.6097H10.9691C11.4135 15.6097 11.7738 15.97 11.7738 16.4145C11.7738 16.8589 11.4135 17.2193 10.9691 17.2193H10.5389C10.0944 17.2193 9.73413 16.8589 9.73413 16.4145Z"
        fill={color}
      />
      <path
        d="M13.032 15.6097C12.5876 15.6097 12.2273 15.97 12.2273 16.4145C12.2273 16.8589 12.5876 17.2193 13.032 17.2193H13.4623C13.9067 17.2193 14.267 16.8589 14.267 16.4145C14.267 15.97 13.9067 15.6097 13.4623 15.6097H13.032Z"
        fill={color}
      />
      <path
        d="M10.5389 9.5332C10.0944 9.5332 9.73413 9.8935 9.73413 10.338C9.73413 10.7824 10.0944 11.1427 10.5389 11.1427H10.9691C11.4135 11.1427 11.7738 10.7824 11.7738 10.338C11.7738 9.8935 11.4135 9.5332 10.9691 9.5332H10.5389Z"
        fill={color}
      />
      <path
        d="M12.2273 10.338C12.2273 9.8935 12.5876 9.5332 13.032 9.5332H13.4623C13.9067 9.5332 14.267 9.8935 14.267 10.338C14.267 10.7824 13.9067 11.1427 13.4623 11.1427H13.032C12.5876 11.1427 12.2273 10.7824 12.2273 10.338Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.68027 2.2677C2.07865 2.2677 0.780273 3.56608 0.780273 5.1677V7.24426C0.780273 8.01963 1.08457 8.72393 1.58026 9.24425C1.08457 9.76457 0.780273 10.4689 0.780273 11.2442V13.3208C0.780273 14.9224 2.07865 16.2208 3.68027 16.2208H7.22559V17.4528C7.22559 19.0544 8.52396 20.3528 10.1256 20.3528H18.8482C20.4499 20.3528 21.7482 19.0544 21.7482 17.4528V15.3762C21.7482 14.6008 21.4439 13.8965 20.9482 13.3762C21.4439 12.8559 21.7482 12.1516 21.7482 11.3762V9.29966C21.7482 7.69803 20.4499 6.39966 18.8482 6.39966H10.1256C8.85871 6.39966 7.78157 7.21202 7.38665 8.34424H3.68027L3.67429 8.34424C3.06953 8.34103 2.58027 7.84978 2.58027 7.24426V5.1677C2.58027 4.56019 3.07276 4.0677 3.68027 4.0677H12.4029C13.0104 4.0677 13.5029 4.56019 13.5029 5.1677V6.39026H15.3029V5.1677C15.3029 3.56608 14.0045 2.2677 12.4029 2.2677H3.68027ZM7.22559 10.1443H3.68027L3.67429 10.1443C3.06953 10.1475 2.58027 10.6387 2.58027 11.2442V13.3208C2.58027 13.9283 3.07276 14.4208 3.68027 14.4208H7.38665C7.524 14.027 7.74388 13.6719 8.02557 13.3762C7.86699 13.2097 7.72799 13.0245 7.61229 12.824C7.46509 12.9858 7.25287 13.0873 7.01694 13.0873H6.58674C6.14228 13.0873 5.78198 12.727 5.78198 12.2825C5.78198 11.8381 6.14228 11.4778 6.58674 11.4778H7.01694C7.09013 11.4778 7.16104 11.4876 7.22843 11.5059C7.22654 11.4629 7.22559 11.4197 7.22559 11.3762V10.1443ZM10.1196 14.2762H18.8542C19.459 14.2794 19.9482 14.7707 19.9482 15.3762V17.4528C19.9482 18.0603 19.4557 18.5528 18.8482 18.5528H10.1256C9.51807 18.5528 9.02559 18.0603 9.02559 17.4528V15.3762C9.02559 14.7707 9.51485 14.2794 10.1196 14.2762ZM18.8542 12.4762C19.459 12.473 19.9482 11.9817 19.9482 11.3762V9.29966C19.9482 8.69214 19.4557 8.19966 18.8482 8.19966H10.1256C9.51807 8.19966 9.02559 8.69215 9.02559 9.29966V11.3762C9.02559 11.9817 9.51484 12.473 10.1196 12.4762H18.8542Z"
        fill={color}
      />
      <path
        d="M3.28882 6.206C3.28882 5.76155 3.64912 5.40125 4.09357 5.40125H4.52378C4.96823 5.40125 5.32854 5.76155 5.32854 6.206C5.32854 6.65045 4.96823 7.01075 4.52378 7.01075H4.09357C3.64912 7.01075 3.28882 6.65045 3.28882 6.206Z"
        fill={color}
      />
      <path
        d="M6.58674 5.40125C6.14228 5.40125 5.78198 5.76155 5.78198 6.206C5.78198 6.65045 6.14228 7.01075 6.58674 7.01075H7.01694C7.4614 7.01075 7.8217 6.65045 7.8217 6.206C7.8217 5.76155 7.4614 5.40125 7.01694 5.40125H6.58674Z"
        fill={color}
      />
    </svg>
  );
});
