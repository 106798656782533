import { client } from 'api/client';
import { userService } from 'api/userService';
import { apiPaths } from 'appConstants/apiPaths';

import { appState } from './app.state';

class Controller {
  public readonly state = appState;

  public readonly setConfig = async (): Promise<void> => {
    const { data } = await client.get(apiPaths.dictionaries);
    const { passwordRules, fileExportLimit } = data;

    userService.setPasswordConfig(passwordRules);
    userService.setAppSettingsConfig({ fileExportLimit });
  };

  public readonly loadBankData = async (): Promise<void> => {
    const { data } = await client.get(apiPaths.sbpMembers);
    this.state.bankData.next(data);
  };

  public readonly onMount = async (): Promise<void> => {
    this.setConfig();
  };
}

export const appController = new Controller();
