import { userService } from "api/userService";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectStringArray } from "observables/StringArrayObservable";
import { ActivateInviteInputService } from "service/shared/others/ActivateInviteInputService/ActivateInviteInput.service";
import { PasswordConfigType } from "types/business/PasswordConfigType";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class State {
  public readonly passwordConfig: PasswordConfigType;

  public token!: string;

  public readonly showPasswordNotEqualRepeatedPasswordError: BehaviorSubjectBoolean;

  public readonly isLoaded: BehaviorSubjectBoolean;

  public readonly showErrors: BehaviorSubjectBoolean;

  public readonly showDigitsError: BehaviorSubjectBoolean;

  public readonly showLengthError: BehaviorSubjectBoolean;

  public readonly showLowercaseLettersError: BehaviorSubjectBoolean;

  public readonly showRussianLetterError: BehaviorSubjectBoolean;

  public readonly showSpecialSymbolsError: BehaviorSubjectBoolean;

  public readonly showUppercaseLettersError: BehaviorSubjectBoolean;

  public readonly showCapsLockError: BehaviorSubjectBoolean;

  public readonly passwordService: ActivateInviteInputService;

  public readonly repeatedPasswordService: ActivateInviteInputService;

  public readonly activationCodeService: ActivateInviteInputService;

  public readonly errorTextArray: BehaviorSubjectStringArray;

  public constructor() {
    this.passwordConfig = userService.getPasswordConfig();

    this.isLoaded = new BehaviorSubjectBoolean(false);
    this.showPasswordNotEqualRepeatedPasswordError = new BehaviorSubjectBoolean(false);
    this.showErrors = new BehaviorSubjectBoolean(false);
    this.passwordService = new ActivateInviteInputService();
    this.repeatedPasswordService = new ActivateInviteInputService();
    this.activationCodeService = new ActivateInviteInputService({ required: true });

    this.showDigitsError = setCombinedBehaviorSubject(
      this.setShowDigitsError,
      this.passwordService.state.digitsState,
      this.repeatedPasswordService.state.digitsState
    );

    this.showLengthError = setCombinedBehaviorSubject(
      this.setShowLengthError,
      this.passwordService.state.lengthState,
      this.repeatedPasswordService.state.lengthState
    );

    this.showLowercaseLettersError = setCombinedBehaviorSubject(
      this.setShowLowercaseLettersError,
      this.passwordService.state.lowercaseLettersState,
      this.repeatedPasswordService.state.lowercaseLettersState
    );

    this.showRussianLetterError = setCombinedBehaviorSubject(
      this.setShowRussianLetterError,
      this.passwordService.state.russianLetterState,
      this.repeatedPasswordService.state.russianLetterState
    );

    this.showSpecialSymbolsError = setCombinedBehaviorSubject(
      this.setShowSpecialSymbolsError,
      this.passwordService.state.specialSymbolsState,
      this.repeatedPasswordService.state.specialSymbolsState
    );

    this.showUppercaseLettersError = setCombinedBehaviorSubject(
      this.setShowUppercaseLettersError,
      this.passwordService.state.uppercaseLettersState,
      this.repeatedPasswordService.state.uppercaseLettersState
    );

    this.showCapsLockError = setCombinedBehaviorSubject(
      this.setShowCapsLockError,
      this.passwordService.state.capsLockState,
      this.repeatedPasswordService.state.capsLockState
    );

    this.errorTextArray = setCombinedBehaviorSubject(
      this.setErrorTextArray,
      this.showPasswordNotEqualRepeatedPasswordError,
      this.showDigitsError,
      this.showLengthError,
      this.showLowercaseLettersError,
      this.showSpecialSymbolsError,
      this.showUppercaseLettersError
    );
  }

  public readonly setShowDigitsError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowLengthError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowLowercaseLettersError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowRussianLetterError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowSpecialSymbolsError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowUppercaseLettersError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setErrorTextArray = (
    showPasswordNotEqualRepeatedPasswordError: boolean,
    showDigitsError: boolean,
    showLengthError: boolean,
    showLowercaseLettersError: boolean,
    showSpecialSymbolsError: boolean,
    showUppercaseLettersError: boolean
  ): string[] => {
    const errorTextArray: string[] = [];

    if (showPasswordNotEqualRepeatedPasswordError) errorTextArray.push("signInPage.showPasswordNotEqualRepeatedPasswordError");
    if (showDigitsError) errorTextArray.push("signInPage.digitsError");
    if (showLengthError) errorTextArray.push("signInPage.lengthError");
    if (showLowercaseLettersError) errorTextArray.push("signInPage.lowercaseLettersError");
    if (showSpecialSymbolsError) errorTextArray.push("signInPage.specialSymbolsError");
    if (showUppercaseLettersError) errorTextArray.push("signInPage.uppercaseLettersError");

    return errorTextArray;
  };

  private readonly setShowCapsLockError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };
}

export const activateInvitePageState = new State();
