import { appController } from "service/shared/singletones/appService/app.controller";
import { appState } from "service/shared/singletones/appService/app.state";

class Service {
  public readonly state = appState;

  public readonly controller = appController;
}

export const appService = new Service();
