import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";

class State {
  public readonly isRedirected: BehaviorSubjectBoolean;

  public readonly currentRedirectPage: BehaviorSubjectString;

  public constructor() {
    this.isRedirected = new BehaviorSubjectBoolean(true);
    this.currentRedirectPage = new BehaviorSubjectString("");
  }
}

export const redirectState = new State();
