import { getDateAndRationArray } from "./getDateAndRationArray";
import { getStatisticRatioValue } from "./getStatisticRatioValue";
import { StatisticValue } from "./StatisticValue";

export const produceRatioStatistic = (
  statistics: StatisticValue[]
): {
  dateArray: string[];
  successQuantityArray: number[];
  failQuantityArray: number[];
} => {
  const newStatistics = statistics.map((el) => ({ ...el }));
  const statisticRatioValues = getStatisticRatioValue(newStatistics);
  return getDateAndRationArray(statisticRatioValues);
};
