import { terminalsPageController } from "service/shared/singletones/terminalsPageService/terminalsPage.controller";
import { terminalsPageState } from "service/shared/singletones/terminalsPageService/terminalsPage.state";

class Service {
  public readonly state = terminalsPageState;

  public readonly controller = terminalsPageController;
}

export const terminalsPageService = new Service();
