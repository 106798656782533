import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { routerPaths } from "appConstants/routerPaths";
import { t } from "i18n";
import { redirectService } from "service/common/redirectService/redirect.service";
import { resetPasswordFormState } from "service/shared/singletones/resetPasswordFormService/resetPasswordForm.state";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { ResetPasswordDto } from "types/business/ResetPasswordDto";
import { MouseCustomEvent } from "types/libExtend/MouseCustomEvent";

class Controller {
  public readonly state = resetPasswordFormState;

  public readonly sendForm = async (event: MouseCustomEvent): Promise<void> => {
    event.preventDefault();

    this.state.isLoaded.next(true);

    const passwordValue = this.state.passwordService.state.value.value;
    const confirmPasswordValue = this.state.confirmPasswordService.state.value.value;

    const passwordValid = this.state.passwordService.state.valid.value;
    const confirmPasswordValid = this.state.confirmPasswordService.state.valid.value;

    if (this.state.token && passwordValid && confirmPasswordValid && passwordValue === confirmPasswordValue) {
      const resetPasswordDto: ResetPasswordDto = new ResetPasswordDto(passwordValue, this.state.token);

      try {
        await client.post(apiPaths.userPasswordForgotChange, resetPasswordDto);
        redirectService.controller.setCurrentRedirectPage(routerPaths.signIn);
        toastContainerService.controller.createSuccessToast(t("resetPasswordFormService.changedPasswordTitle"));
      } catch (error: any) {
        const { message } = error.response.data;
        toastContainerService.controller.createErrorToast(message);
        this.clearForm();
      }
    } else {
      this.state.passwordService.controller.setShowError(true);
      this.state.confirmPasswordService.controller.setShowError(true);
    }

    this.state.isLoaded.next(false);
  };

  public readonly onMount = (search: string): void => {
    try {
      const newSearch = search.slice(1, search.length);
      const token = newSearch.split("=")[1];

      if (token) {
        this.state.token = token;
      } else {
        redirectService.controller.setCurrentRedirectPage(routerPaths.signIn);
      }
    } catch (error: any) {
      redirectService.controller.setCurrentRedirectPage(routerPaths.signIn);
    }
  };

  private readonly clearForm = (): void => {
    this.state.passwordService.controller.clearForm();
    this.state.confirmPasswordService.controller.clearForm();
  };
}

export const resetPasswordFormController = new Controller();
