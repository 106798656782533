import { SupportArticleModel } from "model/SupportArticle.model";

export const initialSupportArticle = [
  new SupportArticleModel(1, "type_1", "description_1", "email_1"),
  new SupportArticleModel(2, "type_2", "description_2", "email_2"),
  new SupportArticleModel(3, "type_3", "description_3", "email_3"),
  new SupportArticleModel(4, "type_4", "description_4", "email_4"),
  new SupportArticleModel(5, "type_5", "description_5", "email_5"),
  new SupportArticleModel(6, "type_6", "description_6", "email_6"),
  new SupportArticleModel(7, "type_7", "description_7", "email_7"),
  new SupportArticleModel(8, "type_8", "description_8", "email_8"),
  new SupportArticleModel(9, "type_9", "description_9", "email_9"),
];
