import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { InputProps } from "./Input.props";
import { SCreateFormInput } from "./InputStyled/SCreateFormInput.styled";
import { SCreateFormInputError } from "./InputStyled/SCreateFormInputError.styled";
import { SCreateFormInputInput } from "./InputStyled/SCreateFormInputInput.styled";
import { SCreateFormInputTitle } from "./InputStyled/SCreateFormInputTitle.styled";

export const Input = createComponent<InputProps>("Input", (props) => {
  const { service } = props;
  const { t } = useTranslation();

  const value = useDefaultObservableString(service.state.value);
  const showError = useDefaultObservableBoolean(service.state.showError);
  const valid = useDefaultObservableBoolean(service.state.valid);

  return (
    <SCreateFormInput>
      <SCreateFormInputTitle>{service.state.langPath ? t(service.state.langPath) : service.state.title}</SCreateFormInputTitle>
      <SCreateFormInputInput
        type="text"
        value={value}
        isError={showError && !valid}
        onChange={service.controller.onChange}
        onFocus={service.controller.onFocus}
      />
      {showError ? (
        <SCreateFormInputError>{service.state.langPathError ? t(service.state.langPathError) : service.state.errorMessage}</SCreateFormInputError>
      ) : null}
    </SCreateFormInput>
  );
});
