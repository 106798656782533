import { ForgotPasswordInputController } from "service/shared/others/ForgotPasswordInputService/ForgotPasswordInput.controller";
import { ForgotPasswordInputState } from "service/shared/others/ForgotPasswordInputService/ForgotPasswordInput.state";

type InputServiceParams = {
  regexp?: RegExp;
  required?: true;
};

export class ForgotPasswordInputService {
  public readonly state: ForgotPasswordInputState;

  public readonly controller: ForgotPasswordInputController;

  public constructor(params: InputServiceParams) {
    this.state = new ForgotPasswordInputState(params);
    this.controller = new ForgotPasswordInputController(this.state);
  }
}
