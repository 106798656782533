import { useMultipleCustomSelectOptionContext } from "context/MultipleCustomSelectOption.context";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SMultipleCustomSelectOptionAll } from "./MultipleCustomSelectOptionAllStyled/SMultipleCustomSelectOptionAll.styled";

export const MultipleCustomSelectOptionAll = createComponent("MultipleCustomSelectOptionAll", () => {
  const { t, i18n } = useTranslation();
  const { service } = useMultipleCustomSelectOptionContext();
  const isArabic = i18n.language === "ar";

  return (
    <SMultipleCustomSelectOptionAll isArabic={isArabic} onClick={service.controller.onClickAll}>
      {t("customMultipleSelect.allOptionTitle")}
    </SMultipleCustomSelectOptionAll>
  );
});
