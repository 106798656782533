import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { STerminalsListTableHeader } from "./TerminalsListTableHeaderStyled/STerminalsListTableHeader.styled";
import { STerminalsListTableHeaderTitle } from "./TerminalsListTableHeaderStyled/STerminalsListTableHeaderTitle.styled";

export const TerminalsListTableHeader = createComponent("TerminalsListTableHeader", () => {
  const { t } = useTranslation();
  return (
    <STerminalsListTableHeader>
      <STerminalsListTableHeaderTitle>{t("terminalsPage.terminalsListTableHeaderTerminalId")}</STerminalsListTableHeaderTitle>
      <STerminalsListTableHeaderTitle>{t("terminalsPage.terminalsListTableHeaderMerchantName")}</STerminalsListTableHeaderTitle>
    </STerminalsListTableHeader>
  );
});
