import { color_rgba_183_200_220_80, css, resetScrollbar, setBackgroundColor_ffffff, setBorderRadius_8px, setFlexDFFDC, setProps, styled } from "theme";

type SMultipleCustomSelectOptionListProps = {
  isFitContentList: boolean;
  isArabic: boolean;
};

const isFitContentListSet = setProps<SMultipleCustomSelectOptionListProps>(({ isFitContentList }) => isFitContentList)(css`
  height: fit-content;
`);

const isFitContentListNotSet = setProps<SMultipleCustomSelectOptionListProps>(({ isFitContentList }) => !isFitContentList)(css`
  height: 150px;
`);

const isArabicLang = setProps<SMultipleCustomSelectOptionListProps>(({ isArabic }) => isArabic)(css`
  left: -50px;
  right: 0;
`);

const isNotArabicLang = setProps<SMultipleCustomSelectOptionListProps>(({ isArabic }) => !isArabic)(css`
  right: -50px;
`);

export const SMultipleCustomSelectOptionList = styled.div<SMultipleCustomSelectOptionListProps>`
  position: absolute;
  z-index: 1;
  top: 30px;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;

  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${setFlexDFFDC};
  ${isNotArabicLang};
  ${isArabicLang};
  ${resetScrollbar};
  ${isFitContentListSet};
  ${isFitContentListNotSet};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
`;
