import { css, setColor_e74c3c, setFontSize_13px, setFontWeight600, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const SSessionDropButton = styled.button<Props>`
  margin-top: 32px;
  text-align: left;

  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_e74c3c};
  ${isNotArabicLang};
  ${isArabicLang};
`;
