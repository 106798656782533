import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const resultCodeFilterModel = new FilterModel({
  id: 8,
  type: "text",
  title: t("resultCodeFilterModel.title"),
  name: "RESULT_CODE",
  options: {},
  langPath: "resultCodeFilterModel.title",
});
