import { styled } from "theme/default/styled";
import { setColor_25476d } from "theme/setter/setColorsValues/setColor_25476d";
import { setFontSize_15px } from "theme/setter/setFontSizeValues/setFontSize_15px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const SCreateUserFormTitle = styled.div`
  text-align: center;

  ${setFontSize_15px};
  ${setFontWeight600};
  ${setColor_25476d};
`;
