import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { auditFilterConfig } from "config/filterConfig/auditFilterConfig";
import { tableHeaderInitialCell } from "config/filterConfig/tableHeaderInitialCell";
import { BehaviorSubjectAuditUserTypeArray } from "observables/AuditUserTypeArrayObservable";
import { BehaviorSubjectHeaderCellModelArray } from "observables/HeaderCellModelArrayObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { combineLatestWith } from "packages/rxjs";
import { FiltersService } from "service/shared/others/FiltersService/Filters.service";
import { TableEntityService } from "service/shared/others/TableEntityService/TableEntity.service";
import { AuditUserType } from "types/business/AuditUserType";
import { Dictionary } from "types/commonExtend/Dictionary";
import { clearQueriesField } from "utils/business/clearQueriesField";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

import { convertFetchedDateToTableShowDate } from "../../../../utils/business/convertFetchedDateToTableShowDate";

class Service extends TableEntityService<AuditUserType> {
  public readonly filterService: FiltersService;

  public readonly tableService: BehaviorSubjectTableService;

  private readonly initialHeaderCellList: BehaviorSubjectHeaderCellModelArray;

  private readonly auditUsers: BehaviorSubjectAuditUserTypeArray;

  public constructor() {
    super(tableHeaderInitialCell.auditPage);
    this.auditUsers = new BehaviorSubjectAuditUserTypeArray([]);
    this.filterService = new FiltersService(auditFilterConfig, this.applyFilter);
    this.initialHeaderCellList = setCombinedBehaviorSubject(this.controller.setInitialHeaderCellList, this.state.sort, this.state.desc);
    this.tableService = setCombinedBehaviorSubject(this.controller.setTableService, this.auditUsers, this.initialHeaderCellList);

    this.state.isMounted
      .pipe(
        combineLatestWith(
          this.state.filterNavigationService.state.currentPage,
          this.state.filterNavigationService.state.controlsSelectService.state.activeOption,
          this.state.sort
        )
      )
      .subscribe(([isMounted, currentPage, sizeOption, sort]) => {
        if (isMounted) {
          const isMountedNotEqual = this.state.prevIsMounted !== isMounted;
          const currentPageNotEqual = this.state.prevCurrentPage !== currentPage;
          const sizeOptionNotEqual = this.state.prevSizeOption !== +sizeOption.value;
          const sortNotEqual = this.state.prevSort !== sort;

          if (isMountedNotEqual || currentPageNotEqual || sizeOptionNotEqual || sortNotEqual) {
            this.state.loaded.next(false);

            this.state.prevIsMounted = isMounted;
            this.state.prevSizeOption = +sizeOption.value;
            this.state.prevSort = sort;

            this.getAuditUsers().then(() => {
              this.state.prevCurrentPage = currentPage;
            });
          }
        }
      });
  }

  private readonly produceAuditUsers = (dataItem: any): AuditUserType => {
    return {
      created: convertFetchedDateToTableShowDate(dataItem.created) || "",
      executor: dataItem.executor || "",
      requestType: dataItem.requestType || "",
      sourceIp: dataItem.sourceIp || "",
      successfully: dataItem.successfully ? "SUCCESS" : "FAIL",
      paymentId: dataItem.paymentId || "",
      transferId: dataItem.transferId || "",
    };
  };

  private readonly produceAuditUsersData = (data: any[]): AuditUserType[] => {
    return data.map(this.produceAuditUsers);
  };

  private readonly produceQueries = (queries: Dictionary<string>): Dictionary<string | string[]> => {
    return {
      CREATED: queries.CREATION_DATE,
      EXECUTOR: queries.EXECUTOR,
    };
  };

  private readonly getAuditData = async (): Promise<any> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const size = this.state.filterNavigationService.state.controlsSelectService.state.activeOption.value.value;
    const page = this.state.filterNavigationService.state.currentPage.value;
    const sort = this.state.sort.value;

    const query = {
      queries: this.produceQueries(queries),
      page,
      size,
      sort,
    };

    const { data } = await client.post(apiPaths.auditInfo, query);

    if (page === this.state.prevCurrentPage) {
      this.state.filterNavigationService.state.currentPage.next(0);
    }

    this.state.filterNavigationService.state.totalPages.next(data.totalPages);
    this.state.filterNavigationService.state.totalElements.next(data.totalElements);

    return data.content;
  };

  private readonly getAuditUsers = async (): Promise<void> => {
    this.state.loaded.next(false);
    try {
      const data: any[] = await this.getAuditData();
      const produceData = this.produceAuditUsersData(data);
      this.auditUsers.next(produceData);
    } catch (e) {
      console.log("e", e);
    } finally {
      this.state.loaded.next(true);
    }
  };

  private readonly applyFilter = async (): Promise<void> => {
    this.state.loaded.next(false);
    await this.getAuditUsers();
  };
}

export const auditPageService = new Service();
