import { useTranslation } from "react-i18next";
import { groupTableBodyService } from "service/shared/singletones/groupTableBodyService/groupTableBody.service";
import { DragDivType } from "types/libExtend/DragDivType";
import { createComponent } from "utils/libExtend/createComponent";

import { GroupHeaderCellProps } from "./GroupHeaderCell.props";
import { SGroupHeaderCell } from "./GroupHeaderCellStyled/SGroupHeaderCell.styled";
import { SGroupHeaderCellDraggable } from "./GroupHeaderCellStyled/SGroupHeaderCellDraggable.styled";
import { SGroupHeaderCellDraggableContainer } from "./GroupHeaderCellStyled/SGroupHeaderCellDraggableContainer.styled";
import { SGroupHeaderCellDraggablePointer } from "./GroupHeaderCellStyled/SGroupHeaderCellDraggablePointer.styled";
import { SGroupHeaderCellTitle } from "./GroupHeaderCellStyled/SGroupHeaderCellTitle.styled";

export const GroupHeaderCell = createComponent<GroupHeaderCellProps>("GroupHeaderCell", (props) => {
  const { t, i18n } = useTranslation();
  const { isLast, config } = props;
  const isArabic = i18n.language === "ar";

  const onDragHandler = (event: DragDivType): void => {
    groupTableBodyService.controller.onDrag(event, config);
  };

  const onDragEndHandler = (event: DragDivType): void => {
    groupTableBodyService.controller.onDragEnd(event, config);
  };

  return (
    <SGroupHeaderCell style={{ width: config.width }}>
      <SGroupHeaderCellTitle isArabic={isArabic}>{t(config.langPath)}</SGroupHeaderCellTitle>

      {!isLast ? (
        <SGroupHeaderCellDraggableContainer>
          <SGroupHeaderCellDraggable />
          <SGroupHeaderCellDraggablePointer draggable="true" onDrag={onDragHandler} onDragEnd={onDragEndHandler} />
        </SGroupHeaderCellDraggableContainer>
      ) : null}
    </SGroupHeaderCell>
  );
});
