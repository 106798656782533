import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";

export const multipleTerminalFilterModel = new FilterModel({
  id: 17,
  type: "fetched-multiple-select",
  title: t("multipleTerminalFilterModel.title"),
  name: "FILTERS",
  options: {
    optionListCallBack: async (): Promise<OptionModel[]> => {
      const { data } = await client.post(apiPaths.terminalList);

      return data.map((element: any) => {
        const merchantName = element.merchantName || "";
        const terminalId = element.terminalId || "";
        return new OptionModel(`${terminalId} - ${merchantName}`, `${terminalId}`);
      });
    },
  },
  langPath: "multipleTerminalFilterModel.title",
});
