import { routerPaths } from "appConstants/routerPaths";
import { DetailsLayout } from "components/DetailsLayout/DetailsLayout.component";
import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { refundDetailsPageService } from "service/shared/singletones/refundDetailsPageService/refundDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useParams } from "utils/libReplace/useParams";

import { RefundDetailsPageContent } from "./RefundDetailsPageContent/RefundDetailsPageContent.component";
import { SRefundDetailsPageLoader } from "./RefundDetailsPageStyled/SRefundDetailsPageLoader.styled";

export const RefundDetailsPage = createComponent("RefundDetailsPage", () => {
  const { t } = useTranslation();
  const { refundId } = useParams<{ refundId: string }>();

  useEffect(async () => {
    await refundDetailsPageService.controller.onMounted(refundId);
    return (): void => {
      refundDetailsPageService.controller.unMounted();
    };
  }, []);

  const loaded: boolean = useDefaultObservableBoolean(refundDetailsPageService.state.loaded);

  return (
    <DetailsLayout header={[{ title: t("refundDetailsPage.headerTitle"), src: routerPaths.refunds }]} title={`# ${refundId}`}>
      {loaded ? (
        <RefundDetailsPageContent />
      ) : (
        <SRefundDetailsPageLoader>
          <Loader />
        </SRefundDetailsPageLoader>
      )}
    </DetailsLayout>
  );
});
