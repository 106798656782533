import { css, setProps, styled } from "theme";

type SFilterButtonClearProps = {
  open: boolean;
  isArabic: boolean;
};

const openSet = setProps<SFilterButtonClearProps>(({ open }) => !open)(css`
  cursor: default;
  opacity: 0;
`);

const isArabicLang = setProps<SFilterButtonClearProps>(({ isArabic }) => isArabic)(css`
  margin-right: 8px;
`);

const isNotArabicLang = setProps<SFilterButtonClearProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 8px;
`);

export const SFilterButtonClear = styled.button<SFilterButtonClearProps>`
  height: 22px;

  ${isNotArabicLang};
  ${isArabicLang};
  ${openSet};
`;
