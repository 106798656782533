export class ResetPasswordDto {
  private readonly password: string;

  private readonly token: string;

  public constructor(password: string, token: string) {
    this.password = password;
    this.token = token;
  }
}
