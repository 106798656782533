import { styled } from "theme/default/styled";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SRolesSelectForm = styled.div`
  position: fixed;
  top: 80px;
  bottom: 80px;
  left: 50%;
  width: 500px;
  padding: 16px 24px;
  transform: translateX(-50%);

  ${setFlexDFFDC};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
`;
