import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";

export const merchantFilterModel = new FilterModel({
  id: 322,
  type: "fetched-select",
  title: t("merchantFilterModel.title"),
  name: "MERCHANT",
  options: {
    optionListCallBack: async (): Promise<OptionModel[]> => {
      const { data } = await client.post(apiPaths.merchantList);

      return data.map((element: any) => {
        const merchantId = element.merchantId || "";
        const merchantName = element.merchantName || "";
        return new OptionModel(`${merchantId} - ${merchantName}`, `${merchantId}`);
      });
    },
  },
  langPath: "merchantFilterModel.title",
});
