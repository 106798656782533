import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { UsersChangeRowCommonProps } from "./UsersChangeRowCommon.props";
import { SUsersChangeRowCommon } from "./UsersChangeRowCommonStyled/SUsersChangeRowCommon.styled";
import { SUsersChangeRowCommonTitle } from "./UsersChangeRowCommonStyled/SUsersChangeRowCommonTitle.styled";
import { SUsersChangeRowCommonValue } from "./UsersChangeRowCommonStyled/SUsersChangeRowCommonValue.styled";

export const UsersChangeRowCommon = createComponent<UsersChangeRowCommonProps>("UsersChangeRowCommon", (props) => {
  const { i18n } = useTranslation();
  const { title, value } = props;
  const isArabic = i18n.language === "ar";

  return (
    <SUsersChangeRowCommon isArabic={isArabic}>
      <SUsersChangeRowCommonTitle>{title}</SUsersChangeRowCommonTitle>
      <SUsersChangeRowCommonValue>{value}</SUsersChangeRowCommonValue>
    </SUsersChangeRowCommon>
  );
});
