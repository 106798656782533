import { userRolesConfig } from "appConstants/userRolesConfig";
import { t } from "i18n";
import { RolesSelectOptionModel } from "model/RolesSelectOption.model";
import { BehaviorSubject } from "types/libReplace/BehaviorSubject";

class State {
  public readonly showError: BehaviorSubject<boolean>;

  public readonly open: BehaviorSubject<boolean>;

  public readonly loaded: BehaviorSubject<boolean>;

  public readonly selectedOptions: BehaviorSubject<RolesSelectOptionModel[]>;

  public readonly rolesSelectOptionArray: BehaviorSubject<RolesSelectOptionModel[]>;

  public constructor() {
    this.showError = new BehaviorSubject<boolean>(false);
    this.open = new BehaviorSubject<boolean>(false);
    this.loaded = new BehaviorSubject<boolean>(true);
    this.selectedOptions = new BehaviorSubject<RolesSelectOptionModel[]>([]);
    this.rolesSelectOptionArray = new BehaviorSubject<RolesSelectOptionModel[]>([
      new RolesSelectOptionModel(
        userRolesConfig.ADMINISTRATOR,
        t("usersChangeRowRolesService.administratorDescription"),
        "usersChangeRowRolesService.administratorDescription"
      ),
      new RolesSelectOptionModel(
        userRolesConfig.R_OPERATION,
        t("usersChangeRowRolesService.rOperationDescription"),
        "usersChangeRowRolesService.rOperationDescription"
      ),
      new RolesSelectOptionModel(userRolesConfig.R_H2H, t("usersChangeRowRolesService.rH2H"), "usersChangeRowRolesService.rH2H"),
      new RolesSelectOptionModel(
        userRolesConfig.R_INVOICE,
        t("usersChangeRowRolesService.rInvoiceDescription"),
        "usersChangeRowRolesService.rInvoiceDescription"
      ),
      new RolesSelectOptionModel(
        userRolesConfig.W_INVOICE,
        t("usersChangeRowRolesService.wInvoiceDescription"),
        "usersChangeRowRolesService.wInvoiceDescription"
      ),
      new RolesSelectOptionModel(
        userRolesConfig.X_INVOICE,
        t("usersChangeRowRolesService.xInvoiceDescription"),
        "usersChangeRowRolesService.xInvoiceDescription"
      ),
      new RolesSelectOptionModel(userRolesConfig.X_CANCEL, t("usersChangeRowRolesService.xCancelDescription"), "usersChangeRowRolesService.xCancelDescription"),
      new RolesSelectOptionModel(userRolesConfig.X_REFUND, t("usersChangeRowRolesService.xRefundDescription"), "usersChangeRowRolesService.xRefundDescription"),
      new RolesSelectOptionModel(
        userRolesConfig.X_CONFIRM,
        t("usersChangeRowRolesService.xConfirmDescription"),
        "usersChangeRowRolesService.xConfirmDescription"
      ),
      new RolesSelectOptionModel(userRolesConfig.R_REFUND, t("usersChangeRowRolesService.rRefundDescription"), "usersChangeRowRolesService.rRefundDescription"),
      new RolesSelectOptionModel(
        userRolesConfig.R_TRANSFER,
        t("usersChangeRowRolesService.rTransferDescription"),
        "usersChangeRowRolesService.rTransferDescription"
      ),
      new RolesSelectOptionModel(
        userRolesConfig.X_TRANSFER,
        t("usersChangeRowRolesService.xTransferDescription"),
        "usersChangeRowRolesService.xTransferDescription"
      ),
      new RolesSelectOptionModel(
        userRolesConfig.R_STATISTICS,
        t("usersChangeRowRolesService.rStatisticsDescription"),
        "usersChangeRowRolesService.rStatisticsDescription"
      ),
      new RolesSelectOptionModel(userRolesConfig.R_REWARD, t("usersChangeRowRolesService.rRewardDescription"), "usersChangeRowRolesService.rRewardDescription"),
    ]);
  }
}

export const createUserRolesSelectFormState = new State();
