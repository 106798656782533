import { Filters } from "components/Filters/Filters.component";
import { statisticPageService } from "service/shared/singletones/statisticPageService/statisticPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SStatisticHeader } from "./StatisticHeaderStyled/SStatisticHeader.styled";
import { SStatisticHeaderFilter } from "./StatisticHeaderStyled/SStatisticHeaderFilter.styled";

export const StatisticHeader = createComponent("StatisticHeader", () => {
  return (
    <SStatisticHeader>
      <SStatisticHeaderFilter>
        <Filters service={statisticPageService.state.filterService} />
      </SStatisticHeaderFilter>
    </SStatisticHeader>
  );
});
