import { emailRegExp } from "appConstants/regexp";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { ForgotPasswordInputService } from "service/shared/others/ForgotPasswordInputService/ForgotPasswordInput.service";

class State {
  public readonly isLoaded: BehaviorSubjectBoolean;

  public readonly emailService: ForgotPasswordInputService;

  public constructor() {
    this.isLoaded = new BehaviorSubjectBoolean(false);
    this.emailService = new ForgotPasswordInputService({
      regexp: emailRegExp,
      required: true,
    });
  }
}

export const forgotPasswordFormState = new State();
