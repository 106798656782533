export const translationRu = {
  loading: 'Загрузка...',
  h2hPage: {
    errorMessage: 'Не введены поля ',
    errorMessageMerchant: 'Мерчант'
  },
  activateInvitePage: {
    activate: 'Активировать',
    activationCode: 'Код из СМС',
    capsLockError: 'Включен caps lock',
    password: 'Новый пароль',
    redirectToSignIn: 'Вернуться на вход',
    repeatedPassword: 'Еще раз пароль',
    successMessage: 'Всё прошло успешно',
    title: 'Активация учетной записи'
  },
  activateInvitePageService: {
    tokenErrorTitle: 'Токен невалидный или просрочен'
  },
  amountFilterModel: {
    title: 'Сумма'
  },
  auditPageConfig: {
    created: 'Дата создания',
    details: 'Детализация',
    executor: 'Исполнитель',
    requestType: 'Событие',
    sourceIp: 'IP адрес',
    successfully: 'Статус'
  },
  auditUserIdModel: {
    title: 'Пользователь'
  },
  authCodeFilterModel: {
    title: 'Код авторизации'
  },
  authIdFilterModel: {
    title: 'ID авторизации'
  },
  cardHolderFilterModel: {
    title: 'Имя держателя карты'
  },
  cardNumberFilterModel: {
    title: 'Номер карты'
  },
  changeEmailPhoneInputSection: {
    email: 'Email',
    phone: 'Телефон',
    title: 'Изменить Email и номер телефона'
  },
  changePasswordSection: {
    buttonTitle: 'Изменить пароль',
    capsLockError: 'Включен caps lock',
    currentPasswordTitle: 'Текущий пароль',
    digitsError: 'В пароле должны присутствовать цифры',
    lengthError: 'Пароль должен быть от {{minLength}} до {{maxLength}} символов',
    lowercaseLettersError: 'В пароле должны присутствовать символы в нижнем регистре',
    newPasswordTitle: 'Новый пароль',
    repeatedNewPasswordTitle: 'Еще раз новый пароль',
    russianLetterError: 'В пароле не должны присутствовать русские буквы',
    showCurrentPasswordEqualNewPasswordError: 'Новый пароль не должен совпадать со старым паролем',
    showNewPasswordNotEqualRepeatedNewPasswordError: 'Новые пароли не совпадают',
    specialSymbolsError:
      'В пароле должны присутствовать специальные символы (@ # $ % ^ & ; : * _ - + = ! ? [ ] { } / \\ . , < >)',
    title: 'Изменить пароль',
    uppercaseLettersError: 'В пароле должны присутствовать символы в верхнем регистре'
  },
  compareMonthService: {
    aprilTitle: 'Апрель',
    augustTitle: 'Август',
    decemberTitle: 'Декабрь',
    februaryTitle: 'Февраль',
    januaryTitle: 'Январь',
    julyTitle: 'Июль',
    juneTitle: 'Июнь',
    marchTitle: 'Март',
    mayTitle: 'Май',
    novemberTitle: 'Ноябрь',
    octoberTitle: 'Октябрь',
    septemberTitle: 'Сентябрь'
  },
  controlsSelect: {
    title: 'Строк на страницу'
  },
  createUserFetchedSelectService: {
    initialOptionTitle: 'Загрузка...'
  },
  createUserServiceFormService: {
    email: 'Email',
    merchantGroupIdMessage: 'Группа не выбрана',
    merchantGroupIdTitle: 'Выберите группу',
    name: 'Имя пользователя',
    phoneNumber: 'Номер телефона',
    rolesMessage: 'Роль не выбрана',
    rolesTitle: 'Выберите роль'
  },
  customDateSelect: {
    applyButtonTitle: 'Применить',
    closeButtonTitle: 'Отменить'
  },
  customMultipleSelect: {
    allOptionTitle: 'Все',
    buttonTitle: 'Выбрано: {{value}}'
  },
  dateFilterModel: {
    title: 'Даты, интервал'
  },
  dynamicPage: {
    applyButton: 'Применить',
    compareParamTitle: 'Параметры сравнения',
    firstGroupTitle: 'Первая группа',
    graphTitle: 'Показатели',
    monthInputTitle: 'Месяц',
    notEnoughDataErrorMessageTitle: 'Недостаточно данных для сравнения',
    pageTitle: 'Динамика',
    secondGroupTitle: 'Вторая группа',
    terminalInputTitle: 'Терминалы',
    xAxisTitle: 'День',
    yAxisTitle: 'Сумма операций, ₽',
    yearInputTitle: 'Год'
  },
  filterFetchedSelectDataModel: {
    loadedTitle: 'Загрузка...'
  },
  filters: {
    addFilterButton: 'Добавить фильтр',
    applyButtonTitle: 'Применить',
    filterButton: 'Фильтры',
    filterSelectorSelectTitle: 'Выберите фильтр',
    filterSelectorTitle: 'Добавить фильтр',
    rangeInputTitle: 'Даты, интервал',
    resetButton: 'Сбросить',
    downloadButtonTitle: 'Выгрузить в файл',
    downloadErrorMax: 'Вы пытаетесь выгрузить более {{count}} записей',
    cvsNamePayments: 'Платежи',
    cvsNameInvoices: 'Счета',
    cvsNameTransfers: 'Переводы',
    cvsNameRefunds: 'Возвраты'
  },
  forgotPasswordFormService: {
    resetPasswordMessage: 'Письмо с восстановлением пароля отправлено на почту'
  },
  forgotPasswordPage: {
    backButton: 'Назад',
    emailPlaceholder: 'Email',
    sendButtonTitle: 'Отправить',
    title: 'Восстановление пароля'
  },
  formLayout: {
    bottomTitle: 'Объединенные Арабские Эмираты, Дубай',
    topTitle: '© 2023 OOO FinOn'
  },
  formLayoutSNGB: {
    bottomTitle: 'Генеральная лицензия Банка России №588 от 05.09.2016',
    topTitle: '© 2021 АО БАНК «СНГБ»'
  },
  formOpenButton: {
    title: 'Новый счет'
  },
  groupTableBodyService: {
    detailsTitle: 'Детализация',
    emailTitle: 'Email',
    groupTitle: 'Группа',
    nameTitle: 'Имя',
    phoneNumberTitle: 'Телефон'
  },
  invoiceCreateForm: {
    cancelButtonTitle: 'Отмена',
    createButtonTitle: 'Создать'
  },
  invoiceCreateFormService: {
    amountErrorMessage: 'Сумма введена неверно',
    amountTitle: 'Сумма',
    oneStagePaymentTypeTitle: 'Одностадийный',
    twoStagePaymentTypeTitle: 'Двустадийный',
    paymentTypeTitle: 'Тип платежа',
    paymentTypeErrorMessage: 'Тип платежа введен неверно',
    createInvoiceMessage: 'Счет создан',
    currencyErrorMessage: 'Валюта введена неверно',
    currencyTitle: 'Валюта',
    descriptionErrorMessage: 'Описание заказа введено неверно',
    descriptionTitle: 'Описание заказа',
    emailErrorMessage: 'Email покупателя введен неверно',
    emailTitle: 'Email покупателя',
    phoneErrorMessage: 'Телефон покупателя введен неверно',
    phoneTitle: 'Телефон покупателя',
    terminalErrorMessage: 'Терминал введен неверно',
    terminalTitle: 'Терминал',
    titleErrorMessage: 'Заголовок введен неверно',
    titleTitle: 'Заголовок'
  },
  invoiceDetailsPage: {
    amount: 'Сумма заказа',
    copyButtonTitle: 'Ссылка на заказ',
    creationDate: 'Создан',
    currency: 'Валюта',
    deleteButton: 'Отменить',
    description: 'Описание счета',
    email: 'Email покупателя',
    headerTitle: 'Счета',
    phone: 'Телефон покупателя',
    sendSmsButtonTitle: 'Отправить СМС',
    sendEmailButtonTitle: 'Отправить почтой',
    status: 'Статус',
    tableTitle: 'Платеж',
    terminalId: 'ID Терминала',
    title: 'Заголовок счета'
  },
  invoiceDetailsPageConfig: {
    details: 'Детализация',
    paymentId: 'Идентификатор платежа',
    status: 'Статус'
  },
  invoiceDetailsPageService: {
    linkCopied: 'Ссылка на заказ скопирована в буфер обмена',
    messageSendEmailTitle: 'Письмо отправлено клиенту на почту',
    messageSendSmsTitle: 'Письмо отправлено клиенту по смс',
    paymentRemove: 'Платеж удален'
  },
  invoiceIdFilterModel: {
    title: 'Идентификатор счета'
  },
  invoicesPageConfig: {
    amount: 'Сумма',
    creationDate: 'Дата создания',
    details: 'Детализация',
    email: 'Email покупателя',
    invoiceId: 'Идентификатор счета',
    phone: 'Телефон покупателя',
    status: 'Статус',
    terminalId: 'ID Терминала'
  },
  mainLayout: {
    auditTitle: 'Аудит',
    h2h: 'H2H',
    logoutTitle: 'Профиль',
    profileTitle: 'Выйти',
    supportAdministration: 'Поддержка',
    terminalsTitle: 'Терминалы',
    title: 'ТСП СНГБ ЛК UI v1.1.7',
    userTitle: 'Пользователи'
  },
  multipleTerminalFilterModel: {
    title: 'Терминалы'
  },
  navigationMenuService: {
    h2h: 'H2H',
    dynamic: 'Динамика',
    invoices: 'Счета',
    payments: 'Платежи',
    refunds: 'Возвраты',
    reward: 'Возмещение',
    statistic: 'Статистика',
    support: 'Поддержка',
    transfers: 'Переводы'
  },
  notFoundPage: {
    title: 'Страница не найдена'
  },
  operationStatusFilterModel: {
    title: 'Статус операции',
    AUTHENTICATED: 'Пройден 3DS',
    AUTHENTICATION_FAILED: 'Не успешен 3DS',
    AUTHENTICATION_REQUIRED: 'Требуется 3DS',
    AUTHENTICATION_STARTED: 'Начат 3DS',
    CREATED: 'Создан',
    ERROR: 'Ошибка',
    EXPIRED: 'Превышен таймаут',
    FORM_SHOWED: 'Показана форма',
    PROCESSING: 'В обработке',
    THREE_DS_METHOD_CALL_REQUIRED: '3DS метод',
    FAILED: 'Отказ',
    SUCCESS: 'Успешно'
  },
  operationTypeFilterModel: {
    MIR_PAY: 'Mir Pay',
    APPLE_PAY: 'Apple Pay',
    CARD: 'Карта',
    GOOGLE_PAY: 'Google Pay',
    PAYMENT_TOKEN: 'Платежный токен',
    SAMSUNG_PAY: 'Samsung Pay',
    SBP: 'СБП',
    title: 'Тип операции'
  },
  panFilterModel: {
    title: 'Номер карты'
  },
  paymentDetailsPage: {
    backButtonButton: 'Назад',
    cancelSwitchTitle: 'Отмены',
    cancelTitle: 'Отменить',
    confirmSwitchTitle: 'Подтверждения',
    confirmTitle: 'Подтвердить',
    enterCancelSumTitle: 'Введите сумму отмены',
    enterConfirmSumTitle: 'Введите сумму подтверждения',
    enterRefundSumTitle: 'Введите сумму возврата',
    headerTitle: 'Платежи',
    partialActionButton: 'Частично',
    paymentCancelMessage: 'Платеж отменен',
    paymentConfirmMessage: 'Платеж подтвержден',
    paymentRefundMessage: 'Платеж возвращен',
    refundSwitchTitle: 'Возвраты',
    refundTitle: 'Вернуть',
    additionalInfoTitle: 'Дополнительная информация',
    selectCancelTitle: 'Выберите способ отмены',
    selectConfirmTitle: 'Выберите способ подтверждения',
    selectRefundTitle: 'Выберите способ возврата'
  },
  paymentDetailsPageCancelConfig: {
    amount: 'Сумма',
    creationDate: 'Дата создания',
    status: 'Статус'
  },
  paymentDetailsPageConfirmConfig: {
    amount: 'Сумма',
    creationDate: 'Дата создания',
    status: 'Статус'
  },
  paymentDetailsPageContent: {
    phoneInputPlaceholder: 'Введите телефон',
    actionInputPlaceholder: 'Введите сумму',
    additionalInfo: 'Дополнительная информация',
    amount: 'Сумма платежа',
    trxId: 'TRX_ID',
    authId: 'Код авторизации',
    cancelButtonTitle: 'Отменить',
    canceled: 'Отменен',
    cardHolder: 'Имя держателя карты',
    confirmButtonTitle: 'Подтвердить',
    confirmedAmount: 'Подтвержденная сумма платежа',
    creationDate: 'Дата и время создания',
    currency: 'Валюта платежа',
    externalRrn: 'Внешний RRN',
    fee: 'Комиссия',
    fullActionButtonTitle: 'Полную сумму',
    maskedPan: 'Номер карты',
    paymentSystem: 'Платежная система',
    refundButtonTitle: 'Вернуть',
    refundsAmount: 'Сумма возвратов',
    requestId: 'Идентификатор запроса',
    resultCode: 'Код ответа',
    rrn: 'RRN',
    status: 'Статус',
    terminalId: 'ID Терминала',
    type: 'Тип операции'
  },
  paymentDetailsPageRefundConfig: {
    amount: 'Сумма',
    creationDate: 'Дата создания',
    details: 'Детализация',
    status: 'Статус'
  },
  paymentIdFilterModel: {
    title: 'Идентификатор платежа'
  },
  paymentRefundDetailsPage: {
    titlePayment: 'Платежи',
    titleRefund: 'Возвраты'
  },
  paymentRefundDetailsPageContent: {
    amount: 'Сумма платежа',
    authId: 'Код авторизации',
    creationDate: 'Дата и время создания',
    currency: 'Валюта платежа',
    maskedPan: 'Номер карты',
    paymentSystem: 'Платежная система',
    resultCode: 'Код ответа',
    rrn: 'RRN',
    externalId: 'externalId',
    status: 'Статус',
    terminalId: 'ID Терминала'
  },
  paymentSystemTypeFilterModel: {
    mastercard: 'Mastercard',
    mir: 'МИР',
    title: 'Тип ПС',
    visa: 'Visa'
  },
  h2hPageConfig: {
    details: 'Детализация',
    terminalTzOperationTime: 'Время операции, в часовом поясе терминала',
    processingTzOperationTime: 'Время обработки, хостовое',
    merchantId: 'ID мерчанта',
    terminalId: 'ID терминала',
    amount: 'Сумма операции',
    refundsAmount: 'Сумма частичной отмены/возврата',
    messageCode: 'Код сообщения',
    responseCode: 'Код ответа',
    maskedPan: 'Номер карты',
    rrn: 'RRN',
    transactionType: 'Тип транзакции',
    authId: 'Код авторизации',
    pointOfServiceEntryMode: 'Режим чтения карты',
    eci: 'ECI',
    mcc: 'MCC',
    terminalName: 'Имя терминала',
    terminalCity: 'Город',
    terminalRegion: 'Регион',
    terminalCountry: 'Страна',
    currency: 'Код валюты',
    acquireId: 'ID эквайера',
    paymentSystem: 'Платежная система'
  },
  paymentsPageConfig: {
    amount: 'Сумма',
    creationDate: 'Дата и время',
    details: 'Детализация',
    maskedPan: 'Номер карты (маскированный)',
    paymentId: 'Идентификатор платежа',
    rrn: 'RRN',
    status: 'Статус',
    terminalId: 'ID Терминала'
  },
  refundDetailsPage: {
    headerTitle: 'Возвраты'
  },
  refundDetailsPageContent: {
    amount: 'Сумма платежа',
    authId: 'Код авторизации',
    creationDate: 'Дата и время создания',
    currency: 'Валюта платежа',
    maskedPan: 'Номер карты',
    paymentSystem: 'Платежная система',
    requestId: 'Идентификатор запроса',
    resultCode: 'Код ответа',
    rrn: 'RRN',
    successful: 'Статус',
    tableTitle: 'Платеж',
    terminalId: 'ID Терминала'
  },
  refundDetailsPageService: {
    details: 'Детализация',
    paymentId: 'Идентификатор платежа'
  },
  refundIdFilterModel: {
    title: 'Идентификатор возврата'
  },
  refundPaymentFilterModel: {
    title: 'Идентификатор платежа'
  },
  refundsPageConfig: {
    amount: 'Сумма',
    creationDate: 'Дата и время',
    details: 'Детализация',
    maskedPan: 'Номер карты (маскированный)',
    refundId: 'Идентификатор возврата',
    rrn: 'RRN',
    status: 'Статус',
    terminalId: 'ID Терминала'
  },
  requestIdFilterModel: {
    title: 'Идентификатор запроса'
  },
  resetPasswordFormService: {
    changedPasswordTitle: 'Пароль успешно изменен'
  },
  resetPasswordPage: {
    backButtonTitle: 'Назад',
    passwordPlaceholder: 'Пароль',
    repeatPasswordPlaceholder: 'Подтвердите пароль',
    sendButtonTitle: 'Отправить',
    title: 'Смена пароля'
  },
  resultCodeFilterModel: {
    title: 'Код ответа'
  },
  rewardDetailsPage: {
    informationContentFeeAmountTitle: 'Комиссия',
    informationContentIncomeAmountTitle: 'Сумма',
    informationContentNotFound: 'Данные не найдены',
    informationContentSettlementAmountTitle: 'Возмещение'
  },
  rewardDetailsPageConfig: {
    amount: 'Сумма операции',
    authId: 'Код авторизации',
    fee: 'Комиссия',
    operTime: 'Время операции',
    pan: 'Номер карты',
    paymentId: 'Идентификатор платежа',
    rrn: 'RRN',
    settlement: 'Возмещения',
    terminalId: 'ID Терминала',
    terminalName: 'Название терминала'
  },
  rewardsPageConfig: {
    date: 'Дата возмещения',
    details: 'Детализация',
    feeAmount: 'Сумма комиссий',
    incomeAmount: 'Сумма операций',
    operationsNumber: 'Количество операций',
    settlementAmount: 'Сумма возмещения'
  },
  signInFormService: {
    accessDeniedTitle: 'Вам недоступны страницы',
    systemUserAccessDenied: 'Пользователю с этой ролью нельзя авторизоваться'
  },
  signInPage: {
    capsLockError: 'Включен caps lock',
    digitsError: 'В пароле должны присутствовать цифры',
    emailTitle: 'Email',
    forgotPasswordButton: 'Забыли пароль?',
    lengthError: 'Пароль должен быть от {{minLength}} до {{maxLength}} символов',
    lowercaseLettersError: 'В пароле должны присутствовать символы в нижнем регистре',
    passwordTitle: 'Пароль',
    russianLetterError: 'В пароле не должны присутствовать русские буквы',
    sendButton: 'Войти',
    showPasswordNotEqualRepeatedPasswordError: 'Новые пароли не совпадают',
    specialSymbolsError:
      'В пароле должны присутствовать специальные символы (@ # $ % ^ & ; : * _ - + = ! ? [ ] { } / \\ . , < >',
    title: 'Вход в личный кабинет',
    uppercaseLettersError: 'В пароле должны присутствовать символы в верхнем регистре'
  },
  statisticPage: {
    conversionsTitle: 'Конверсии',
    errorMessage: 'Не все поля введены',
    failTitle: 'Неуспешные',
    paymentSystemTitle: 'Платежные системы',
    successTitle: 'Успешные'
  },
  statisticPageConfig: {
    datePart: 'Дата',
    failQuantity: 'Неуспешных',
    refundQuantity: 'Возвратов',
    successQuantity: 'Успешных',
    successSum: 'Всего',
    terminalId: 'Терминал'
  },
  statisticStepFilterModel: {
    DAY: 'День',
    MONTH: 'Месяц',
    YEAR: 'Год',
    title: 'Шаг агрегации'
  },
  statisticTypeFilterModel: {
    QUANTITY: 'Количество',
    SUM: 'Сумма',
    title: 'Тип агрегации'
  },
  succesfullyFilterModel: {
    FAIL: 'FAILED',
    SUCCESS: 'SUCCESS',
    PROCESSING: 'PROCESSING',
    title: 'Статус'
  },
  supportAdministrationPage: {
    applyChangesButtonTitle: 'Применить изменения',
    articleFormApplyButtonTitle: 'Создать статью',
    createArticleDescriptionInputPlaceholder: 'Введите описание',
    createArticleTypeInputPlaceholder: 'Введите тип',
    emailInputPlaceholder: 'Введите email',
    feedbackTitle: 'Форма обратной связи',
    openCreateSupportButtonTitle: 'Создать статью помощи'
  },
  supportPage: {
    feedBackFormTextFieldPlaceholder: 'Введите сообщение',
    feedbackFormSelector: 'Тема сообщения',
    feedbackFormSendButtonTitle: 'Отправить',
    feedbackFormTitle: 'Форма обратной связи',
    listTitle: 'Часто задаваемые вопросы',
    pageTitle: 'Поддержка'
  },
  tableContent: {
    notDataTitle: 'Данные не найдены'
  },
  tableNavigation: {
    totalOperationsTitle: 'Всего операций',
    totalPageTitle: 'Всего страниц'
  },
  terminalFilterModel: {
    title: 'Терминал'
  },
  merchantFilterModel: {
    title: 'Мерчант'
  },
  rrnFilterModel: {
    title: 'RRN'
  },
  terminalsPage: {
    countPostTitle: 'записей из найденных',
    countPreTitle: 'Показано',
    filterTitle: 'Фильтр',
    terminalListTitle: 'Терминалы',
    terminalsApplyButtonTitle: 'Применить',
    terminalsClearFilterButtonTitle: 'Сбросить фильтры',
    terminalsFilterSelectTitle: 'Фильтр',
    terminalsListTableHeaderMerchantName: 'Название',
    terminalsListTableHeaderTerminalId: 'ID Терминала'
  },
  toastContainerService: {
    errorTitle: 'Ошибка',
    noInfoTitle: 'Информация не указана',
    successTitle: 'Успешно'
  },
  transferDetailsPage: {
    cancelTransferButtonTitle: 'Отменить перевод',
    title: 'Переводы'
  },
  transferDetailsPageConfig: {
    creationDate: 'Дата создания',
    status: 'Статус'
  },
  transferDetailsPageContent: {
    amountTitle: 'Сумма',
    cancelButtonTitle: 'Отмена перевода',
    canceledTitle: 'Отменен',
    creationDateTitle: 'Дата перевода',
    currencyTitle: 'Валюта',
    destinationAuthId: 'Код авторизации',
    destinationExternalRrn: 'Внешний RRN',
    destinationMaskedPan: 'Номер карты',
    destinationPaymentSystem: 'Тип платежной системы',
    destinationResultCode: 'Код ответа',
    destinationRrn: 'RRN',
    destinationTitle: 'Реквизиты платежа назначения',
    fee: 'Комиссия',
    requestIdTitle: 'Идентификатор запроса',
    sourceAuthId: 'Код авторизации',
    sourceCardHolder: 'Имя владельца',
    sourceExternalRrn: 'Внешний RRN',
    sourceMaskedPan: 'Номер карты',
    sourcePaymentSystem: 'Тип платежной системы',
    sourceResultCode: 'Код ответа',
    sourceRrn: 'RRN',
    sourceTitle: 'Реквизиты источника платежа',
    statusTitle: 'Статус',
    terminalIdTitle: 'Терминал ID',
    typeTitle: 'Тип перевода'
  },
  transferDetailsPageService: {
    transferCancel: 'Перевод отменен'
  },
  transferIdFilterModel: {
    title: 'Идентификатор перевода'
  },
  transferTypeFilterModel: {
    AFT: 'AFT',
    C2C: 'C2C',
    OCT: 'OCT',
    title: 'Тип операции'
  },
  transfersPageConfig: {
    amount: 'Сумма',
    creationDate: 'Дата создания',
    details: 'Детализация',
    requestId: 'Идентификатор запроса',
    status: 'Статус',
    terminalId: 'ID Терминала',
    transferId: 'Идентификатор перевода',
    type: 'Тип операции'
  },
  userInfoPage: {
    title: 'Данные пользователя'
  },
  userInfoPageContent: {
    email: 'Email',
    id: 'ID',
    name: 'Имя пользователя',
    phone: 'Телефон',
    roles: 'Роли'
  },
  userInfoPageService: {
    changedEmailPhoneTitle: 'Телефон и Email успешно изменены',
    changedPasswordTitle: 'Пароль успешно изменен',
    errorBadCredentialsTitle: 'Истек срок действия сессии',
    errorEmailExistTitle: 'Ошибка при регистрации',
    errorInvitationCodeTitle: 'Код приглашения не верный',
    errorNotExistPaymentTitle: 'Такого платежа не существует',
    errorPasswordEqualTitle: 'Новый пароль совпадает с существующим',
    errorPasswordExpireTitle: 'Истек срок действия пароля',
    errorPasswordTitle: 'Пароль не был изменен.',
    errorPasswordUnEqualTitle: 'Текущий пароль не совпадает с предоставленным',
    errorPasswordWeakTitle: 'Новый пароль не удовлетворяет требованиям безопасности',
    errorSessionExpiredTitle: 'Срок действия сессии истёк',
    errorTerminalsNotAvailableTitle: 'Терминалы не доступны для этого аккаунта',
    errorUserWithEmailNotExistTitle: 'Пользователя с такой почтой не существует',
    errorNotSuccess: 'Не успешно'
  },
  usersChangeRowRolesService: {
    administratorDescription: 'Доступ ко всем возможным действиям, доступных в рамках ТСП или группы ТСП',
    rInvoiceDescription: 'Доступ к получению информации о счетах и детализации счета',
    rOperationDescription: 'Доступ к получению информации о платежах',
    rH2H: 'Доступ к H2H разделу',
    rRefundDescription: 'Доступ к получению информации о возвратах',
    rRewardDescription: 'Доступ к просмотру данных о возмещениях',
    rStatisticsDescription: 'Доступ к просмотру статистики и динамики',
    rTransferDescription: 'Доступ к получению информации о переводах',
    wInvoiceDescription: 'Доступ к созданию счета',
    xCancelDescription: 'Доступ к отмене платежей',
    xConfirmDescription: 'Доступ к подтверждению платежей',
    xInvoiceDescription: 'Доступ к отправке или отмене счета',
    xRefundDescription: 'Доступ к исполнению возвратов',
    xTransferDescription: 'Доступ к операциям с переводами'
  },
  usersDetailsPage: {
    blockUserButtonTitle: 'Заблокировать пользователя',
    blockedFlag: 'Блокировка',
    changePasswordButtonTitle: 'Сменить пароль',
    changePasswordFormTitle: 'Смена пароля',
    email: 'Email',
    enabled: 'Активен',
    id: 'ID',
    merchantGroupName: 'Группа ТСП',
    name: 'Имя пользователя',
    phone: 'Телефон',
    resetPasswordApplyButton: 'Применить',
    resetPasswordBackButton: 'Назад',
    resetPasswordButtonTitle: 'Сбросить пароль',
    roles: 'Роли',
    rolesFormApplyButton: 'Применить',
    rolesFormBackButton: 'Назад',
    rolesSelectFormTitle: 'Роли',
    sessionDropButtonTitle: 'Прервать сессию пользователя',
    unBlockUserButtonTitle: 'Разблокировать пользователя',
    userResetPasswordFormTitle: 'Вы уверены, что хотите сбросить пароль?'
  },
  usersDetailsPageService: {
    currentPasswordTitle: 'Текущий пароль',
    newPasswordTitle: 'Новый пароль',
    passwordWasReset: 'Пароль сброшен',
    sessionIsNotExist: 'Сессии нет',
    sessionWasBreak: 'Сессия прервана',
    title: 'Данные пользователя'
  },
  usersInfoPage: {
    createUserFormTitle: 'Создать приглашение для пользователя',
    createUserInviteButtonTitle: 'Создать приглашение',
    createUserSelectErrorMessage: 'Введите корректное значение',
    openUserFormButton: 'Добавить',
    createMultipleSelectTitle: 'Выберите роль',
    createMultipleSelectContainer: 'Выбрано: ',
    createMultipleSelectMessage: 'Роли не введены'
  },
  customerEmailFilterModel: {
    title: 'Email'
  },
  customerPhoneNumberFilterModel: {
    title: 'Номер телефона'
  }
};
