import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { t } from "i18n";
import { HeaderCellModel } from "model/HeaderCell.model";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const transferDetailsPage = (): HeaderCellModel[] => {
  return [
    getInitialHeaderCell(initialHeaderCellKey.creationDate, t("transferDetailsPageConfig.creationDate"), "transferDetailsPageConfig.creationDate", {
      width: tableWidths.width200,
    }),
    getInitialHeaderCell(initialHeaderCellKey.successfully, t("transferDetailsPageConfig.status"), "transferDetailsPageConfig.status", {
      width: tableWidths.width150,
    }),
  ];
};
