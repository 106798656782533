import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { OpenCreateSupportButton } from "./OpenCreateSupportButton/OpenCreateSupportButton.component";
import { SupportAdministrationPageList } from "./SupportAdministrationPageList/SupportAdministrationPageList.component";
import { SSupportAdministrationPage } from "./SupportAdministrationPageStyled/SSupportAdministrationPage.styled";
import { SSupportAdministrationPageContainer } from "./SupportAdministrationPageStyled/SSupportAdministrationPageContainer.styled";
import { SSupportAdministrationPageHeader } from "./SupportAdministrationPageStyled/SSupportAdministrationPageHeader.styled";
import { SSupportAdministrationPageTitle } from "./SupportAdministrationPageStyled/SSupportAdministrationPageTitle.styled";

export const SupportAdministrationPage = createComponent("SupportAdministrationPage", () => {
  const { t } = useTranslation();
  return (
    <SSupportAdministrationPage>
      <SSupportAdministrationPageHeader>
        <SSupportAdministrationPageTitle>{t("supportAdministrationPage.feedbackTitle")}</SSupportAdministrationPageTitle>
        <OpenCreateSupportButton />
      </SSupportAdministrationPageHeader>
      <SSupportAdministrationPageContainer>
        <SupportAdministrationPageList />
      </SSupportAdministrationPageContainer>
    </SSupportAdministrationPage>
  );
});
