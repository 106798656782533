import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";

export const SCheckboxFilterInputContainer = styled.button`
  position: relative;
  width: 26px;
  height: 26px;
  margin-right: 8px;

  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};
`;
