import { styled } from "theme/default/styled";
import { setBorderRadius_20px } from "theme/setter/setBorderRadiusValues/setBorderRadius_20px";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SUserResetPasswordForm = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 125px;
  padding: 20px;
  transform: translate(-50%, -50%);
  background-color: white;

  ${setFlexDFFDC};
  ${setBorderRadius_20px};
`;
