export const initialHeaderCellKey = {
  terminalTzOperationTime: "terminalTzOperationTime",
  processingTzOperationTime: "processingTzOperationTime",
  merchantId: "merchantId",
  terminalId: "terminalId",
  amount: "amount",
  refundsAmount: "refundsAmount",
  messageCode: "messageCode",
  responseCode: "responseCode",
  maskedPan: "maskedPan",
  rrn: "rrn",
  transactionType: "transactionType",
  authId: "authId",
  pointOfServiceEntryMode: "pointOfServiceEntryMode",
  eci: "eci",
  mcc: "mcc",
  terminalName: "terminalName",
  terminalCity: "terminalCity",
  terminalRegion: "terminalRegion",
  terminalCountry: "terminalCountry",
  currency: "currency",
  acquireId: "acquireId",
  paymentSystem: "paymentSystem",
  date: "date",
  operationsNumber: "operationsNumber",
  incomeAmount: "incomeAmount",
  settlementAmount: "settlementAmount",
  feeAmount: "feeAmount",
  created: "created",
  executor: "executor",
  requestType: "requestType",
  sourceIp: "sourceIp",
  successfully: "successfully",
  datePart: "datePart",
  successQuantity: "successQuantity",
  failQuantity: "failQuantity",
  refundQuantity: "refundQuantity",
  successSum: "successSum",
  fee: "fee",
  operTime: "operTime",
  pan: "pan",
  settlement: "settlement",
  creationDate: "creationDate",
  details: "details",
  email: "email",
  invoiceId: "invoiceId",
  paymentId: "paymentId",
  phone: "phone",
  refundId: "refundId",
  requestId: "requestId",
  status: "status",
  successful: "successful",
  transferId: "transferId",
  type: "type",
};
