import { setFlexDFFDC, setTextRightArabic, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SFeedbackFormSelector = styled.div<Props>`
  width: 300px;
  margin-bottom: 16px;
  gap: 8px;

  ${setFlexDFFDC};
  ${setTextRightArabic()};
`;
