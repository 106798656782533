import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SSelectedApplyButton } from "./ApplyButtonStyled/SSelectedApplyButton.styled";

export const ApplyButton = createComponent("ApplyButton", () => {
  const partialFormSelected = useDefaultObservableBoolean(paymentDetailsPageService.state.partialFormSelected);
  const applyButtonTitle = useDefaultObservableString(paymentDetailsPageService.state.applyButtonTitle);

  return (
    <SSelectedApplyButton disabled={!partialFormSelected} onClick={paymentDetailsPageService.controller.onClickApply}>
      {applyButtonTitle}
    </SSelectedApplyButton>
  );
});
