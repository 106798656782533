import { ToastController } from "service/shared/others/ToastService/Toast.controller";
import { ToastState } from "service/shared/others/ToastService/Toast.state";

type ToastOptions = {
  type: string;
};

export class ToastService {
  public readonly state: ToastState;

  public readonly controller: ToastController;

  public constructor(id: number, title: string, deleteToastCallBack: (rootService: ToastService) => void, options: ToastOptions) {
    this.state = new ToastState(this, id, title, deleteToastCallBack, options);
    this.controller = new ToastController(this, this.state);
  }
}
