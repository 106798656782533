/* eslint-disable max-len */

import { colorsValues } from "theme/values/colorsValues";
import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const Clear = createIcon("Clear", (props) => {
  const { width = 22, height = 22, color = props.isFinOn ? colorsValuesFin.main.icon_fill : colorsValues.main.icon_fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.38899 14.8337C4.99847 15.2243 4.99847 15.8574 5.38899 16.248C5.77952 16.6385 6.41268 16.6385 6.80321 16.248L10.4247 12.6265C10.62 12.4312 10.9366 12.4312 11.1318 12.6265L14.7534 16.248C15.1439 16.6385 15.7771 16.6385 16.1676 16.248C16.5581 15.8575 16.5581 15.2243 16.1676 14.8338L12.546 11.2122C12.3508 11.017 12.3508 10.7004 12.546 10.5051L16.1674 6.88375C16.5579 6.49323 16.5579 5.86006 16.1674 5.46954C15.7769 5.07901 15.1437 5.07901 14.7532 5.46954L11.1318 9.09092C10.9366 9.28618 10.62 9.28618 10.4247 9.09092L6.80338 5.46959C6.41286 5.07907 5.7797 5.07907 5.38917 5.46959C4.99865 5.86012 4.99865 6.49328 5.38917 6.88381L9.0105 10.5051C9.20576 10.7004 9.20576 11.017 9.0105 11.2122L5.38899 14.8337Z"
        fill={color}
      />
    </svg>
  );
});
