import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { paymentDetailsTableService } from "service/shared/singletones/paymentDetailsTableService/paymentDetailsTable.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { PaymentCancelTableContent } from "./PaymentCancelTableContent/PaymentCancelTableContent.component";
import { PaymentDetailsTableProps } from "./PaymentDetailsTable.props";

export const PaymentDetailsTable = createComponent<PaymentDetailsTableProps>("PaymentDetailsTable", (props) => {
  const { cancels, confirms, refunds } = props;

  useEffect(() => {
    paymentDetailsTableService.controller.mount(cancels, confirms, refunds);

    return (): void => {
      paymentDetailsTableService.controller.unMount();
    };
  }, [cancels, confirms, refunds]);

  const loaded = useDefaultObservableBoolean(paymentDetailsTableService.state.loaded);

  if (!loaded) {
    return null;
  }

  return <PaymentCancelTableContent />;
});
