import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18n";
import { OptionModel } from "model/Option.model";
import { RolesSelectOptionModel } from "model/RolesSelectOption.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { CreateUserFetchedSelectService } from "service/shared/others/CreateUserFetchedSelectService/CreateUserFetchedSelect.service";
import { CreateUserSelectService } from "service/shared/others/CreateUserSelectService/CreateUserSelect.service";
import { BehaviorSubject } from "types/libReplace/BehaviorSubject";

class State {
  public readonly roles: BehaviorSubject<RolesSelectOptionModel[]>;

  public readonly open: BehaviorSubjectBoolean;

  public readonly emailService: CreateUserSelectService;

  public readonly nameService: CreateUserSelectService;

  public readonly merchantGroupIdService: CreateUserFetchedSelectService;

  public readonly phoneNumberService: CreateUserSelectService;

  public constructor() {
    this.roles = new BehaviorSubject<RolesSelectOptionModel[]>([]);
    this.open = new BehaviorSubjectBoolean(false);
    this.merchantGroupIdService = new CreateUserFetchedSelectService(
      this.fetchUserCallback,
      "createUserServiceFormService.merchantGroupIdTitle",
      "createUserServiceFormService.merchantGroupIdMessage"
    );
    this.emailService = new CreateUserSelectService({
      placeholder: t("createUserServiceFormService.email"),
      isEmail: true,
    });
    this.nameService = new CreateUserSelectService({ placeholder: t("createUserServiceFormService.name") });
    this.phoneNumberService = new CreateUserSelectService({
      placeholder: t("createUserServiceFormService.phoneNumber"),
      isPhoneNumber: true,
    });
  }

  private readonly fetchUserCallback = async (): Promise<any[]> => {
    const { data } = await client.post(apiPaths.adminGroupSubs);

    return data.groups.map((element: any) => {
      const description = element.description || "";
      const id = element.id || "";
      return new OptionModel(`${description}`, `${id}`);
    });
  };
}

export const createUserServiceFormState = new State();
