import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { statisticFilterConfig } from "config/filterConfig/statisticFilterConfig";
import { tableHeaderInitialCell } from "config/filterConfig/tableHeaderInitialCell";
import i18next from "i18next";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectStatisticPartArray } from "observables/StatisticPartArrayObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { FiltersService } from "service/shared/others/FiltersService/Filters.service";
import { TableService } from "service/shared/others/TableService/Table.service";
import { StatisticPart } from "types/business/StatisticPart";
import { Dictionary } from "types/commonExtend/Dictionary";
import { clearQueriesField } from "utils/business/clearQueriesField";
import { convertFetchedDateToTableShowDate } from "utils/business/convertFetchedDateToTableShowDate";
import { convertRewardDateToUtc0 } from "utils/business/convertRewardDateToUtc0";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class State {
  public readonly showStatisticErrorMessage: BehaviorSubjectBoolean;

  public readonly loaded: BehaviorSubjectBoolean;

  public readonly fetched: BehaviorSubjectBoolean;

  public readonly filterService: FiltersService;

  public readonly tableService: BehaviorSubjectTableService;

  public readonly statistics: BehaviorSubjectStatisticPartArray;

  public constructor() {
    this.showStatisticErrorMessage = new BehaviorSubjectBoolean(false);
    this.loaded = new BehaviorSubjectBoolean(true);
    this.fetched = new BehaviorSubjectBoolean(false);
    this.statistics = new BehaviorSubjectStatisticPartArray([]);
    this.filterService = new FiltersService(statisticFilterConfig, this.applyFilter, {
      initialOpen: true,
      showCloseIcon: false,
      initialArabic: i18next.language === "ar",
    });
    this.tableService = setCombinedBehaviorSubject(this.setTableService, this.statistics);
  }

  private readonly setTableService = (data: StatisticPart[]): TableService => {
    return new TableService(data, tableHeaderInitialCell.statisticPage());
  };

  private readonly applyFilter = async (): Promise<void> => {
    const showStatisticErrorMessage = this.getShowStatisticErrorMessage();

    if (showStatisticErrorMessage) {
      this.showStatisticErrorMessage.next(false);
      this.loaded.next(false);
      this.fetched.next(false);
      await this.getStatistic();
    } else {
      this.showStatisticErrorMessage.next(true);
    }
  };

  private readonly getShowStatisticErrorMessage = (): boolean => {
    const produceFilterData = this.filterService.state.getProduceFilterData();
    const queries = clearQueriesField(produceFilterData);
    return Object.keys(produceFilterData).length === Object.keys(queries).length;
  };

  private readonly getStatistic = async (): Promise<void> => {
    this.loaded.next(false);
    this.fetched.next(false);
    try {
      const data: any[] = await this.fetchStatistic();
      const produceData = this.produceStatisticData(data);
      this.statistics.next(produceData);
      this.fetched.next(true);
    } catch (e) {
      console.log("e", e);
    } finally {
      this.loaded.next(true);
    }
  };

  private readonly fetchStatistic = async (): Promise<any> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());

    const query = this.produceQueries(queries);

    const { data } = await client.post(apiPaths.statistics, query);

    return data.statistics;
  };

  private readonly produceQueries = (queries: Dictionary<string>): Dictionary<string | string[]> => {
    const [startDate, endDate] = convertRewardDateToUtc0(queries.CREATION_DATE).split("_");

    return {
      statisticType: queries.STATISTIC_TYPE,
      statisticStep: queries.STATISTIC_STEP,
      terminalIds: queries.FILTERS,
      startDate,
      endDate,
    };
  };

  public readonly setTerminalId = (object: { [key: string]: StatisticPart }, data: StatisticPart): string => {
    if (object[data.datePart].terminalId.includes(data.terminalId)) {
      return object[data.datePart].terminalId;
    }

    return `${object[data.datePart].terminalId}, ${data.terminalId}`;
  };

  private readonly mergeStatisticData = (statistic: StatisticPart[]): StatisticPart[] => {
    const object: { [key: string]: StatisticPart } = {};

    statistic.forEach((data) => {
      if (object[data.datePart]) {
        // eslint-disable-next-line operator-assignment
        object[data.datePart].terminalId = this.setTerminalId(object, data);
        object[data.datePart].failQuantity = (+object[data.datePart].failQuantity + +data.failQuantity).toString();
        object[data.datePart].refundQuantity = (+object[data.datePart].refundQuantity + +data.refundQuantity).toString();
        object[data.datePart].successQuantity = (+object[data.datePart].successQuantity + +data.successQuantity).toString();
      } else {
        object[data.datePart] = data;
      }
    });

    return Object.values(object);
  };

  public readonly produceMergedStatisticData = (statistic: StatisticPart[]): StatisticPart[] => {
    return statistic.map((dataItem) => {
      return {
        ...dataItem,
        successQuantity: (+dataItem.successQuantity).toFixed(2),
        failQuantity: (+dataItem.failQuantity).toFixed(2),
        refundQuantity: (+dataItem.refundQuantity).toFixed(2),
      };
    });
  };

  private readonly produceStatisticData = (data: any[]): StatisticPart[] => {
    const statisticDataWithField = data.map(this.produceStatistic);
    const mergedStatisticData = this.mergeStatisticData(statisticDataWithField);
    return this.produceMergedStatisticData(mergedStatisticData);
  };

  public readonly produceDatePart = (date: string): string => {
    const parsedDate = convertFetchedDateToTableShowDate(date);
    return parsedDate.split(" ")[0];
  };

  private readonly produceStatistic = (dataItem: any): StatisticPart => {
    return {
      datePart: this.produceDatePart(dataItem.datePart || ""),
      paymentSystem: dataItem.paymentSystem || "",
      terminalId: dataItem.terminalId || "",
      successQuantity: dataItem.successQuantity === undefined ? dataItem.successSum : dataItem.successQuantity,
      failQuantity: dataItem.failQuantity === undefined ? dataItem.failSum : dataItem.failQuantity,
      refundQuantity: dataItem.refundQuantity === undefined ? dataItem.refundSum : dataItem.refundQuantity,
    };
  };
}

export const statisticPageState = new State();
