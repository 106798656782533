import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SAdditionalInfoStepButtons } from "./AdditionalInfoStepStyled/SAdditionalInfoStepButtons.styled";
import { SAdditionalInfoStepTitle } from "./AdditionalInfoStepStyled/SAdditionalInfoStepTitle.styled";
import { ApplyButton } from "./ApplyButton/ApplyButton.component";
import { BackButtonButton } from "./BackButtonButton/BackButtonButton.component";
import { BankSelect } from "./BankSelect/BankSelect.component";
import { PhoneInput } from "./PhoneInput/PhoneInput.component";

export const AdditionalInfoStep = createComponent("AdditionalInfoStep", () => {
  const { t } = useTranslation();
  return (
    <>
      <SAdditionalInfoStepTitle>{t("paymentDetailsPage.additionalInfoTitle")}</SAdditionalInfoStepTitle>
      <PhoneInput />
      <BankSelect />
      <SAdditionalInfoStepButtons>
        <ApplyButton />
        <BackButtonButton />
      </SAdditionalInfoStepButtons>
    </>
  );
});
