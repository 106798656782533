import { useUserTableContext } from "context/UserTable.context";
import { GroupModel } from "model/Group.model";
import { GroupUserModel } from "model/GroupUser.model";
import { useTranslation } from "react-i18next";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { Group } from "./Group/Group.component";
import { GroupUser } from "./Group/GroupUser/GroupUser.component";
import { SGroupTableBody } from "./GroupTableBodyStyled/SGroupTableBody.styled";
import { SGroupTableBodyContainer } from "./GroupTableBodyStyled/SGroupTableBodyContainer.styled";

export const GroupTableBody = createComponent("GroupTableBody", () => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const { userData } = useUserTableContext();
  const renderGroup: RenderFunctionType<GroupModel> = (groupModel: GroupModel, index) => {
    return <Group orderNumber={index} groupModel={groupModel} key={index} />;
  };

  const renderUser: RenderFunctionType<GroupUserModel> = (groupUserModel: GroupUserModel, index) => {
    return <GroupUser orderNumber={index} groupUserModel={groupUserModel} key={index} />;
  };

  return (
    <SGroupTableBody isArabic={isArabic}>
      <SGroupTableBodyContainer>{userData.groups.map(renderGroup)}</SGroupTableBodyContainer>
      <SGroupTableBodyContainer>{userData.users.map(renderUser)}</SGroupTableBodyContainer>
    </SGroupTableBody>
  );
});
