import { css, setProps, styled } from "theme";

interface SFilterSelectorDeleteProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterSelectorDeleteProps>(({ isArabic }) => isArabic)(css`
  margin-right: 8px;
`);

const isNotArabicLang = setProps<SFilterSelectorDeleteProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 8px;
`);

export const SFilterSelectorDelete = styled.button<SFilterSelectorDeleteProps>`
  height: 20px;
  ${isArabicLang};
  ${isNotArabicLang};
`;
