import { CustomDateTime } from "components/CustomDateTime/CustomDateTime.component";
import { color_a0b9d2, css, setBorderRadius_4px, setProps, styled } from "theme";

interface SRangeInputStartPickerProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SRangeInputStartPickerProps>(({ isArabic }) => isArabic)(css`
  .rdtPicker {
    right: 0;
  }
`);

export const SRangeInputLastPicker = styled(CustomDateTime)`
  ${isArabicLang};
  margin-right: 20px;

  & > .formControl {
    width: 180px;
    height: 26px;
    padding: 5px;

    border: 1px solid ${color_a0b9d2};

    ${setBorderRadius_4px};
  }
`;
