import { styled } from "../../../../../../../../theme/default/styled";

export const SCrossButton = styled.button`
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  position: absolute;
  width: 20px;
  height: 20px;
`;
