import { styled } from "theme/default/styled";
import { color_f6b57a } from "theme/setter/colorsValues/color_f6b57a";
import { setBackgroundColor_rgba_246_181_122_40 } from "theme/setter/setBackgroundOpacityColorsValues/setBackgroundColor_rgba_246_181_122_40";
import { setBorderRadius_5px } from "theme/setter/setBorderRadiusValues/setBorderRadius_5px";

export const SSignInCapsLockErrorForm = styled.div<{ $isArabic?: boolean }>`
  width: 200px;
  margin-bottom: 8px;
  text-align: ${({ $isArabic }) => ($isArabic ? "right" : "center")};
  padding: 5px;

  border: 1px solid ${color_f6b57a};

  ${setBorderRadius_5px};
  ${setBackgroundColor_rgba_246_181_122_40};
`;
