import { BehaviorSubjectSupportArticleModelArray } from "observables/SupportArticleModelArrayObservable";
import { initialSupportArticle } from "service/shared/singletones/supportAdministrationPageService/initialSupportArticle";

class State {
  public readonly supportArticlesList: BehaviorSubjectSupportArticleModelArray;

  public constructor() {
    this.supportArticlesList = new BehaviorSubjectSupportArticleModelArray(initialSupportArticle);
  }
}

export const supportAdministrationPageState = new State();
