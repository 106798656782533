import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { activateInvitePageService } from "service/shared/singletones/activateInvitePageService/activateInvitePage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SInviteRussianLetterErrorForm } from "./InviteRussianLetterErrorFormStyled/SInviteRussianLetterErrorForm.styled";
import { SInviteRussianLetterErrorFormTitle } from "./InviteRussianLetterErrorFormStyled/SInviteRussianLetterErrorFormTitle.styled";

export const InviteRussianLetterErrorForm = createComponent("InviteRussianLetterErrorForm", () => {
  const { t } = useTranslation();
  const showRussianLetterError = useDefaultObservableBoolean(activateInvitePageService.state.showRussianLetterError);

  const hideForm = !showRussianLetterError;

  if (hideForm) {
    return null;
  }

  return (
    <SInviteRussianLetterErrorForm>
      <SInviteRussianLetterErrorFormTitle>{t("signInPage.russianLetterError")}</SInviteRussianLetterErrorFormTitle>
    </SInviteRussianLetterErrorForm>
  );
});
