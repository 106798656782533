import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { ImgButton } from "../ImgButton/ImgButton.component";
import { LeftControlsProps } from "./LeftControls.props";
import { SLeftControls } from "./LeftControlsStyled/SLeftControls.styled";
import { SLeftControlsTitle } from "./LeftControlsStyled/SLeftControlsTitle.styled";

export const LeftControls = createComponent<LeftControlsProps>("LeftControls", (props) => {
  const { service } = props;

  const doubleLeftArrowIconActive = useDefaultObservableBoolean(service.state.doubleLeftArrowIconActive);
  const leftArrowIconActive = useDefaultObservableBoolean(service.state.leftArrowIconActive);

  return (
    <SLeftControls>
      <ImgButton
        active={doubleLeftArrowIconActive}
        name="PaginationLeftDoubleArrow"
        onClick={service.controller.onClickDoubleLeftArrowIcon}
        alt="double left arrow icon"
      />
      <SLeftControlsTitle>10</SLeftControlsTitle>
      <ImgButton active={leftArrowIconActive} name="PaginationLeftArrow" onClick={service.controller.onClickLeftArrowIcon} alt="left arrow icon" />
    </SLeftControls>
  );
});
