import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { CreateUserSelectProps } from "./CreateUserSelect.props";
import { SCreateUserSelect } from "./CreateUserSelectStyled/SCreateUserSelect.styled";
import { SCreateUserSelectInput } from "./CreateUserSelectStyled/SCreateUserSelectInput.styled";
import { SCreateUserSelectMessage } from "./CreateUserSelectStyled/SCreateUserSelectMessage.styled";

export const CreateUserSelect = createComponent<CreateUserSelectProps>("CreateUserSelect", (props) => {
  const { t } = useTranslation();
  const { service } = props;

  const value = useDefaultObservableString(service.state.value);
  const showError = useDefaultObservableBoolean(service.state.showError);

  return (
    <SCreateUserSelect>
      <SCreateUserSelectInput
        type="text"
        value={value}
        onFocus={service.controller.onFocus}
        onChange={service.controller.onChange}
        placeholder={service.state.placeholder}
      />
      {showError && <SCreateUserSelectMessage>{t("usersInfoPage.createUserSelectErrorMessage")}</SCreateUserSelectMessage>}
    </SCreateUserSelect>
  );
});
