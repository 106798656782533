import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

type SArticleFormApplyButtonProps = {
  isFormValid: boolean;
};

const isFormValidSet = setProps<SArticleFormApplyButtonProps>(({ isFormValid }) => !isFormValid)(css`
  cursor: default;
  opacity: 0.45;
`);

export const SArticleFormApplyButton = styled.button<SArticleFormApplyButtonProps>`
  margin: 20px auto 0;

  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_0083f5};
  ${isFormValidSet};
`;
