import { useDefaultObservableStatisticPartArray } from "observables/StatisticPartArrayObservable";
import ReactECharts from "packages/echarts-for-react";
import { useTranslation } from "react-i18next";
import { statisticPageService } from "service/shared/singletones/statisticPageService/statisticPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { getChartOptions } from "./getChartOptions";
import { produceChartStatistic } from "./produceChartStatistic/produceChartStatistic";
import { SStatisticChartContent } from "./StatisticChartContentStyled/SStatisticChartContent.styled";
import { SStatisticChartContentTitle } from "./StatisticChartContentStyled/SStatisticChartContentTitle.styled";

export const StatisticChartContent = createComponent("StatisticChartContent", () => {
  const { t } = useTranslation();
  const statistics = useDefaultObservableStatisticPartArray(statisticPageService.state.statistics);

  const { mirValue, visaValue, masterCardValue } = produceChartStatistic(statistics);
  const option = getChartOptions(mirValue, visaValue, masterCardValue);

  return (
    <SStatisticChartContent>
      <SStatisticChartContentTitle>{t("statisticPage.paymentSystemTitle")}</SStatisticChartContentTitle>
      <ReactECharts option={option} style={{ height: 400 }} opts={{ renderer: "svg" }} />
    </SStatisticChartContent>
  );
});
