import { css, setProps, styled } from "theme";

type SNavigationMenuTogglerArrowProps = {
  isArabic: boolean;
};

const isArabicLang = setProps<SNavigationMenuTogglerArrowProps>(({ isArabic }) => isArabic)(css`
  margin-left: 12px;
`);
const isNotArabicLang = setProps<SNavigationMenuTogglerArrowProps>(({ isArabic }) => !isArabic)(css`
  margin-right: 12px;
`);
export const SNavigationMenuTogglerArrow = styled.div<SNavigationMenuTogglerArrowProps>`
  ${isArabicLang};
  ${isNotArabicLang};
`;
