import { styled } from "theme/default/styled";
import { setFlexDFJCC } from "theme/setter/setFlexValues/setFlexDFJCC";

export const SGroupHeaderCellDraggableContainer = styled.div`
  position: relative;
  width: 1px;
  margin-right: 8px;

  ${setFlexDFJCC};
`;
