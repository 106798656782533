import { borderRadius_8px, color_ffffff, css, setBackgroundColor_b7c8dc, setBackgroundColor_ffffff, setFontSize_12px, setProps, styled } from "theme";

type Props = {
  isLast: boolean;
  isActive: boolean;
  isArabic: boolean;
};

const isActiveSet = setProps<Props>(({ isActive }) => isActive)(css`
  font-weight: 600;
`);

const isActiveNotSet = setProps<Props>(({ isActive }) => !isActive)(css`
  font-weight: 400;
`);

const isLastSet = setProps<Props>(({ isLast }) => isLast)(css`
  border-bottom-right-radius: ${borderRadius_8px};
  border-bottom-left-radius: ${borderRadius_8px};
`);

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);
export const SMultipleCustomSelectOptionCommon = styled.button<Props>`
  padding: 7px 8px;
  text-align: left;
  white-space: nowrap;

  border: 1px solid ${color_ffffff};

  ${setFontSize_12px};
  ${isActiveSet};
  ${isActiveNotSet};
  ${setBackgroundColor_ffffff};
  ${isLastSet};
  ${isNotArabicLang};
  ${isArabicLang};

  &:hover {
    ${setBackgroundColor_b7c8dc};
  }
`;
