import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useRef } from "utils/libReplace/useRef";

import { PopUpContainerProps } from "./PopUpContainer.props";
import { SPopUpContainer } from "./PopUpContainerStyled/SPopUpContainer.styled";
import { SPopUpContainerContent } from "./PopUpContainerStyled/SPopUpContainerContent.styled";
import { SPopUpContainerEmitter } from "./PopUpContainerStyled/SPopUpContainerEmitter.styled";

export const PopUpContainer = createComponent<PopUpContainerProps>("PopUpContainer", (props) => {
  const { children, className = "", closeCallBack } = props;

  const emitter = useRef(null);

  useEffect(() => {
    window.addEventListener("click", (event) => {
      if (emitter.current === event.target) {
        closeCallBack();
      }
    });
  }, []);

  return (
    <SPopUpContainer>
      <SPopUpContainerEmitter className={className} ref={emitter} />
      <SPopUpContainerContent>{children}</SPopUpContainerContent>
    </SPopUpContainer>
  );
});
