import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { RefundDetailsModel } from "model/RefundDetails.model";
import { paymentRefundDetailsPageState } from "service/shared/singletones/paymentRefundDetailsPageService/paymentRefundDetailsPage.state";
import { replacePath } from "utils/commonExtend/replacePath";

class Controller {
  public readonly state = paymentRefundDetailsPageState;

  public readonly getRefund = async (): Promise<void> => {
    const data: any[] = await this.fetchRefund();
    const produceData = this.produceRefund(data);
    this.state.refund.next(produceData);
    this.state.loaded.next(true);
  };

  public readonly setRefundId = (refundId: string): void => {
    this.state.refundId = refundId;
  };

  public readonly unMounted = (): void => {
    this.state.refund.next(null);
    this.state.loaded.next(false);
  };

  private readonly produceRefund = (dataItem: any): RefundDetailsModel => {
    return new RefundDetailsModel(dataItem);
  };

  private readonly fetchRefund = async (): Promise<any> => {
    const { data } = await client.get(
      replacePath(apiPaths.refundInfoId, {
        refundId: this.state.refundId,
      })
    );
    return data;
  };
}

export const paymentRefundDetailsPageController = new Controller();
