import { css, setColor_69809a, setFontSize_14px, setFontWeight700, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  margin-right: 10px;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  margin-left: 10px;
`);

export const STableNavigationTotalPagesValue = styled.div<Props>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFontSize_14px};
  ${setFontWeight700};
  ${setColor_69809a};
`;
