import { useTranslation } from "react-i18next";
import { activateInvitePageService } from "service/shared/singletones/activateInvitePageService/activateInvitePage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useLocation } from "utils/libReplace/useLocation";

import { ActivateButton } from "./ActivateButton/ActivateButton.component";
import { SActivateInvitePage } from "./ActivateInvitePageStyled/SActivateInvitePage.styled";
import { SActivateInvitePageHeader } from "./ActivateInvitePageStyled/SActivateInvitePageHeader.styled";
import { InviteActivationCodeInput } from "./InviteActivationCodeInput/InviteActivationCodeInput.component";
import { InviteCapsLockErrorForm } from "./InviteCapsLockErrorForm/InviteCapsLockErrorForm.component";
import { InviteErrorForm } from "./InviteErrorForm/InviteErrorForm.component";
import { InvitePasswordInput } from "./InvitePasswordInput/InvitePasswordInput.component";
import { InviteRepeatedPasswordInput } from "./InviteRepeatedPasswordInput/InviteRepeatedPasswordInput.component";
import { InviteRussianLetterErrorForm } from "./InviteRussianLetterErrorForm/InviteRussianLetterErrorForm.component";
import { InviteSignInButton } from "./InviteSignInButton/InviteSignInButton.component";

export const ActivateInvitePage = createComponent("ActivateInvitePage", () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  useEffect(() => {
    activateInvitePageService.controller.onMount(search);
  }, []);

  return (
    <SActivateInvitePage>
      <SActivateInvitePageHeader>{t("activateInvitePage.title")}</SActivateInvitePageHeader>
      <InvitePasswordInput />
      <InviteRepeatedPasswordInput />
      <InviteActivationCodeInput />
      <InviteCapsLockErrorForm />
      <InviteRussianLetterErrorForm />
      <InviteErrorForm />
      <ActivateButton />
      <InviteSignInButton />
    </SActivateInvitePage>
  );
});
