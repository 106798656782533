import { CustomSelect } from "components/CustomSelect/CustomSelect.component";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";

type SFetchedFilterSelectSelectProps = {
  isTitle: boolean;
};

const isTitleSet = setProps<SFetchedFilterSelectSelectProps>(({ isTitle }) => isTitle)(css`
  color: transparent;
`);

export const SFetchedFilterSelectSelect = styled(CustomSelect)<SFetchedFilterSelectSelectProps>`
  width: 180px;
  background-color: transparent;
  appearance: none;

  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};
  ${isTitleSet};

  & > option {
    color: initial;
  }
`;
