import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";

export const transferTypeFilterModel = new FilterModel({
  id: 1,
  type: "select",
  title: t("transferTypeFilterModel.title"),
  name: "TYPE",
  options: {
    optionList: [
      new OptionModel("transferTypeFilterModel.AFT", "AFT"),
      new OptionModel("transferTypeFilterModel.OCT", "OCT"),
      new OptionModel("transferTypeFilterModel.C2C", "C2C"),
    ],
  },
  langPath: "transferTypeFilterModel.title",
});
