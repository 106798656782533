export const fontSizeValues = {
  font_size_10px: "10px",
  font_size_12px: "12px",
  font_size_13px: "13px",
  font_size_14px: "14px",
  font_size_15px: "15px",
  font_size_18px: "18px",
  font_size_20px: "20px",
  font_size_22px: "22px",
  font_size_24px: "24px",
};
