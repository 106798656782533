import { styled } from "theme/default/styled";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";

export const STerminalsListLoaderContainer = styled.div`
  height: 200px;

  ${setFlexDFAIC};
  ${setFlexJCC};
`;
