import { css, setProps, styled } from "theme";

interface SFetchedFilterSelectDeleteProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFetchedFilterSelectDeleteProps>(({ isArabic }) => isArabic)(css`
  margin-right: 8px;
`);

const isNotArabicLang = setProps<SFetchedFilterSelectDeleteProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 8px;
`);

export const SFetchedFilterSelectDelete = styled.button<SFetchedFilterSelectDeleteProps>`
  height: 20px;
  margin-left: 8px;
  ${isArabicLang};
  ${isNotArabicLang};
`;
