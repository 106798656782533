import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";

type ConstructorParams = {
  isEmail?: boolean;
  isPhoneNumber?: boolean;
  isNumber?: boolean;
  isPassword?: boolean;
  placeholder: string;
};

export class CreateUserSelectState {
  public readonly placeholder: string;

  public readonly isEmail: boolean;

  public readonly isPhoneNumber: boolean;

  public readonly value: BehaviorSubjectString;

  public readonly valid: BehaviorSubjectBoolean;

  public readonly showError: BehaviorSubjectBoolean;

  private readonly isNumber: boolean;

  public constructor(params: ConstructorParams) {
    this.placeholder = params.placeholder;
    this.isNumber = params.isNumber || false;
    this.isEmail = params.isEmail || false;
    this.isPhoneNumber = params.isPhoneNumber || false;
    this.value = new BehaviorSubjectString("");
    this.valid = new BehaviorSubjectBoolean(false);
    this.showError = new BehaviorSubjectBoolean(false);
  }
}
