import { InvoiceInputController } from "service/shared/others/InvoiceInputService/InvoiceInput.controller";
import { InvoiceInputState } from "service/shared/others/InvoiceInputService/InvoiceInput.state";

type CreateInputOptions = {
  isEmail?: boolean;
  isPhone?: boolean;
  isNumber?: boolean;
  isTitle?: boolean;
  isDescription?: boolean;
};

export class InvoiceInputService {
  public readonly state: InvoiceInputState;

  public readonly controller: InvoiceInputController;

  public constructor(id: number, title: string, errorMessage: string, name: string, langPath: string, langPathError: string, options?: CreateInputOptions) {
    this.state = new InvoiceInputState(id, title, errorMessage, name, langPath, langPathError, options);
    this.controller = new InvoiceInputController(this.state);
  }
}
