import { ChangePasswordInputController } from "service/shared/others/ChangePasswordInputService/ChangePasswordInput.controller";
import { ChangePasswordInputState } from "service/shared/others/ChangePasswordInputService/ChangePasswordInput.state";

export class ChangePasswordInputService {
  public readonly state: ChangePasswordInputState;

  public readonly controller: ChangePasswordInputController;

  public constructor() {
    this.state = new ChangePasswordInputState();
    this.controller = new ChangePasswordInputController(this.state);
  }
}
