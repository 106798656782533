import { Loader } from "components/Loader/Loader.component";
import { useRolesFormContext } from "context/RolesForm.context";
import { createComponent } from "utils/libExtend/createComponent";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";
import { useEffect } from "utils/libReplace/useEffect";

import { RolesSelectFormContent } from "./RolesSelectFormContent/RolesSelectFormContent.component";
import { SRolesSelectForm } from "./RolesSelectFormStyled/SRolesSelectForm.styled";
import { SRolesSelectFormLoader } from "./RolesSelectFormStyled/SRolesSelectFormLoader.styled";
import { SRolesSelectFormPopUp } from "./RolesSelectFormStyled/SRolesSelectFormPopUp.styled";

export const RolesSelectForm = createComponent("RolesSelectForm", () => {
  const { service } = useRolesFormContext();

  useEffect(async () => {
    await service.controller.onMount();
  }, []);

  const loaded = useDefaultObservable<boolean>(service.state.loaded);

  return (
    <SRolesSelectFormPopUp closeCallBack={service.controller.closeCallBack}>
      <SRolesSelectForm>
        {loaded ? (
          <RolesSelectFormContent />
        ) : (
          <SRolesSelectFormLoader>
            <Loader />
          </SRolesSelectFormLoader>
        )}
      </SRolesSelectForm>
    </SRolesSelectFormPopUp>
  );
});
