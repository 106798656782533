import { setFlexDF, setRowReverse, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SComparisonSectionContainer = styled.div<Props>`
  margin-bottom: 24px;

  ${setFlexDF};
  ${setRowReverse()};
`;
