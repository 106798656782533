import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SChangePasswordButton } from "./ChangePasswordButtonStyled/SChangePasswordButton.styled";

export const ChangePasswordButton = createComponent("ChangePasswordButton", () => {
  const { t } = useTranslation();
  return (
    <SChangePasswordButton onClick={usersDetailsPageService.controller.onClickChangePasswordButton}>
      {t("usersDetailsPage.changePasswordButtonTitle")}
    </SChangePasswordButton>
  );
});
