import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const invoiceIdFilterModel = new FilterModel({
  id: 18,
  type: "text",
  title: t("invoiceIdFilterModel.title"),
  name: "ORDER_ID",
  options: {
    maxLength: 36,
  },
  langPath: "invoiceIdFilterModel.title",
});
