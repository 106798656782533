import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { activateInvitePageService } from "service/shared/singletones/activateInvitePageService/activateInvitePage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SInviteCapsLockErrorForm } from "./InviteCapsLockErrorFormStyled/SInviteCapsLockErrorForm.styled";
import { SInviteCapsLockErrorFormTitle } from "./InviteCapsLockErrorFormStyled/SInviteCapsLockErrorFormTitle.styled";

export const InviteCapsLockErrorForm = createComponent("InviteCapsLockErrorForm", () => {
  const { t } = useTranslation();
  const showCapsLockError = useDefaultObservableBoolean(activateInvitePageService.state.showCapsLockError);

  const hideForm = !showCapsLockError;

  if (hideForm) {
    return null;
  }

  return (
    <SInviteCapsLockErrorForm>
      <SInviteCapsLockErrorFormTitle>{t("activateInvitePage.capsLockError")}</SInviteCapsLockErrorFormTitle>
    </SInviteCapsLockErrorForm>
  );
});
