import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const amountFilterModel = new FilterModel({
  id: 26,
  type: "text",
  title: t("amountFilterModel.title"),
  name: "AMOUNT",
  options: {
    isNumber: true,
  },
  langPath: "amountFilterModel.title",
});
