import { styled } from "theme/default/styled";
import { color_93a5b8 } from "theme/setter/colorsValues/color_93a5b8";
import { setBorderRadius_5px } from "theme/setter/setBorderRadiusValues/setBorderRadius_5px";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFlexF1 } from "theme/setter/setFlexValues/setFlexF1";
import { setFontSize_15px } from "theme/setter/setFontSizeValues/setFontSize_15px";
import { setFontWeight700 } from "theme/setter/setFontWeightValues/setFontWeight700";

export const SSelectedApplyButton = styled.button`
  height: 50px;
  margin-right: 16px;

  border: 1px solid ${color_93a5b8};

  ${setFlexF1};
  ${setFontSize_15px};
  ${setFontWeight700};
  ${setColor_0083f5};
  ${setBorderRadius_5px};
`;
