import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableString } from "observables/StringObservable";
import { menuService } from "service/shared/singletones/menuService/menu.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { List } from "./List/List.component";
import { SHeaderMenu } from "./MenuStyled/SHeaderMenu.styled";
import { SHeaderMenuContainer } from "./MenuStyled/SHeaderMenuContainer.styled";
import { SHeaderMenuTitle } from "./MenuStyled/SHeaderMenuTitle.styled";

export const Menu = createComponent("Menu", () => {
  useEffect(async () => {
    await menuService.controller.onMount();
  }, []);

  const email = useDefaultObservableString(menuService.state.email);

  return (
    <SHeaderMenu>
      <SHeaderMenuContainer onClick={menuService.controller.toggleList}>
        <SHeaderMenuTitle>{email}</SHeaderMenuTitle>
        <div>
          <SvgImage name="ArrowDown" />
        </div>
      </SHeaderMenuContainer>
      <List />
    </SHeaderMenu>
  );
});
