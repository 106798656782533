import i18next from "i18next";
import { HeaderCellModel } from "model/HeaderCell.model";
import { BehaviorSubjectBodyCellWithIdArray } from "observables/BodyCellWithIdArrayObservable";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectHeaderCellWithIdArray } from "observables/HeaderCellWithIdArrayObservable";
import { BodyCellWithId } from "types/business/BodyCellWithId";
import { InitialBodyRowList } from "types/business/InitialBodyRowList";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

export class TableState {
  public readonly headerCellList: BehaviorSubjectHeaderCellWithIdArray;

  public readonly bodyRowList: BehaviorSubjectBodyCellWithIdArray;

  public readonly isEmpty: BehaviorSubjectBoolean;

  public readonly isArabic: BehaviorSubjectBoolean;

  public constructor(initialBodyRowList: InitialBodyRowList, initialHeaderCellList: HeaderCellModel[]) {
    this.isArabic = new BehaviorSubjectBoolean(i18next.language === "ar");
    const bodyRowList = initialBodyRowList.map((cell, id) => {
      return Object.assign(cell, { id });
    });

    this.bodyRowList = new BehaviorSubjectBodyCellWithIdArray(bodyRowList);

    this.isEmpty = setCombinedBehaviorSubject(this.setIsEmpty, this.bodyRowList);

    this.headerCellList = new BehaviorSubjectHeaderCellWithIdArray(
      initialHeaderCellList.map((cell, index, array) => {
        cell.isFirst = index === 0;
        cell.isLast = index === array.length - 1;
        return Object.assign(cell, { id: index });
      })
    );
  }

  private readonly setIsEmpty = (bodyRowList: BodyCellWithId[]): boolean => {
    return bodyRowList.length === 0;
  };
}
