import { FilterModel } from "model/Filter.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";

export class FilterCheckboxDataModel {
  public readonly filterModel: FilterModel;

  public readonly value: BehaviorSubjectBoolean;

  private readonly initialValue: boolean;

  private readonly removeActiveFilter: (filterModel: FilterModel) => void;

  public constructor(filterModel: FilterModel, removeActiveFilter: (filterModelParam: FilterModel) => void) {
    this.filterModel = filterModel;
    this.removeActiveFilter = removeActiveFilter;

    this.initialValue = this.filterModel.initialBooleanValue || false;
    this.value = new BehaviorSubjectBoolean(this.initialValue);
  }

  public readonly onClickDelete = (): void => {
    this.removeActiveFilter(this.filterModel);
  };

  public readonly onChange = (): void => {
    this.value.next(!this.value.value);
  };
}
