import { CompareTerminalController } from "service/shared/others/CompareTerminalService/CompareTerminal.controller";
import { CompareTerminalState } from "service/shared/others/CompareTerminalService/CompareTerminal.state";

export class CompareTerminalService {
  public readonly state: CompareTerminalState;

  public readonly controller: CompareTerminalController;

  public constructor() {
    this.state = new CompareTerminalState();
    this.controller = new CompareTerminalController(this.state);
  }
}
