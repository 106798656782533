import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { MultipleCustomSelectService } from "service/shared/others/MultipleCustomSelectService/MultipleCustomSelect.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useMemo } from "utils/libReplace/useMemo";

import { CustomMultipleSelectProps } from "./CustomMultipleSelect.props";
import { SCustomMultipleSelect } from "./CustomMultipleSelectStyled/SCustomMultipleSelect.styled";
import { SCustomMultipleSelectContainer } from "./CustomMultipleSelectStyled/SCustomMultipleSelectContainer.styled";
import { SCustomMultipleSelectEmitter } from "./CustomMultipleSelectStyled/SCustomMultipleSelectEmitter.styled";
import { SCustomMultipleSelectOpenButton } from "./CustomMultipleSelectStyled/SCustomMultipleSelectOpenButton.styled";
import { MultipleSelectOptionList } from "./MultipleSelectOptionList/MultipleSelectOptionList.component";

export const CustomMultipleSelect = createComponent<CustomMultipleSelectProps>("CustomMultipleSelect", (props) => {
  const { t, i18n } = useTranslation();
  const { listClassName = "", className = "", onChange, onClickAll, optionList, value } = props;
  const isArabic = i18n.language === "ar";

  const service = useMemo(() => {
    return new MultipleCustomSelectService(optionList, onChange, onClickAll);
  }, [optionList]);

  const isDropDownOpened = useDefaultObservableBoolean(service.state.isDropDownOpened);

  return (
    <SCustomMultipleSelect className={className}>
      <SCustomMultipleSelectContainer>
        <SCustomMultipleSelectOpenButton isArabic={isArabic} onClick={service.controller.onClickOpenButton}>
          {t("customMultipleSelect.buttonTitle", { value: value.length })}
        </SCustomMultipleSelectOpenButton>

        <MultipleSelectOptionList className={listClassName} service={service} value={value} />
      </SCustomMultipleSelectContainer>

      {isDropDownOpened ? <SCustomMultipleSelectEmitter onClick={service.controller.onClickEmitter} /> : null}
    </SCustomMultipleSelect>
  );
});
