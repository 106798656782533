import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { UsersChangeRowGroupCommonProps } from "./UsersChangeRowGroupCommon.props";
import { SUsersChangeRowGroupCommon } from "./UsersChangeRowGroupCommonStyled/SUsersChangeRowGroupCommon.styled";
import { SUsersChangeRowGroupCommonTitle } from "./UsersChangeRowGroupCommonStyled/SUsersChangeRowGroupCommonTitle.styled";
import { SUsersChangeRowGroupCommonValue } from "./UsersChangeRowGroupCommonStyled/SUsersChangeRowGroupCommonValue.styled";

export const UsersChangeRowGroupCommon = createComponent<UsersChangeRowGroupCommonProps>("UsersChangeRowGroupCommon", (props) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const { service } = props;

  return (
    <SUsersChangeRowGroupCommon isArabic={isArabic}>
      <SUsersChangeRowGroupCommonTitle>{t("usersDetailsPage.merchantGroupName")}</SUsersChangeRowGroupCommonTitle>
      <SUsersChangeRowGroupCommonValue>{service.state.value.value.title}</SUsersChangeRowGroupCommonValue>
    </SUsersChangeRowGroupCommon>
  );
});
