import { useDefaultObservableConfigModelArray } from "observables/ConfigModelArrayObservable";
import { groupTableBodyService } from "service/shared/singletones/groupTableBodyService/groupTableBody.service";
import { ConfigModel } from "types/business/ConfigModel";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { GroupHeaderCell } from "./GroupHeaderCell/GroupHeaderCell.component";
import { SGroupTableHeader } from "./GroupTableHeaderStyled/SGroupTableHeader.styled";
import { SGroupTableHeaderContainer } from "./GroupTableHeaderStyled/SGroupTableHeaderContainer.styled";

export const GroupTableHeader = createComponent("GroupTableHeader", () => {
  const configList = useDefaultObservableConfigModelArray(groupTableBodyService.state.configList);

  const renderGroupHeaderCell: RenderFunctionType<ConfigModel> = (configModel, index, array) => {
    return <GroupHeaderCell isLast={index === array.length - 1} key={index} config={configModel} />;
  };

  return (
    <SGroupTableHeader>
      <SGroupTableHeaderContainer>{configList.map(renderGroupHeaderCell)}</SGroupTableHeaderContainer>
    </SGroupTableHeader>
  );
});
