import { forgotPasswordFormController } from "service/shared/singletones/forgotPasswordFormService/forgotPasswordForm.controller";
import { forgotPasswordFormState } from "service/shared/singletones/forgotPasswordFormService/forgotPasswordForm.state";

class Service {
  public readonly state = forgotPasswordFormState;

  public readonly controller = forgotPasswordFormController;
}

export const forgotPasswordFormService = new Service();
