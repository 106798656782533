import i18next, { InitOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { translationEn } from "./locales/en/translation";
import { translationRu } from "./locales/ru/translation";

const isFinOn = process.env.REACT_APP_FIN === "fin";
const initOptions: InitOptions = {
  resources: {
    en: {
      translation: translationEn,
    },
    ru: {
      translation: translationRu,
    },
    ar: {
      translation: translationEn,
    },
  },
  fallbackLng: isFinOn ? "en" : "ru",
};

if (!isFinOn) {
  initOptions.lng = "ru";
}

i18next.use(initReactI18next).use(LanguageDetector).init(initOptions);

const t = i18next.t.bind(i18next);
export { t };
