import { OptionModel } from "model/Option.model";
import { MultipleCustomSelectService } from "service/shared/others/MultipleCustomSelectService/MultipleCustomSelect.service";
import { createContext } from "utils/libReplace/createContext";
import { useContext } from "utils/libReplace/useContext";

export type MultipleCustomSelectOptionContextModel = {
  option?: OptionModel;
  value?: OptionModel[];
  isLast?: boolean;
  service: MultipleCustomSelectService;
};

export const MultipleCustomSelectOptionContext = createContext<MultipleCustomSelectOptionContextModel>({} as MultipleCustomSelectOptionContextModel);

export const useMultipleCustomSelectOptionContext = (): MultipleCustomSelectOptionContextModel => useContext(MultipleCustomSelectOptionContext);
