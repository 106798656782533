/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const ArrowDown = createIcon("ArrowDown", (props) => {
  const { width = 14, height = 8, color = "#69809A" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 14 8" fill="none">
      <path
        d="M8.12373 7.43504L13.7134 1.84524C14.0691 1.48966 14.0691 0.913151 13.7134 0.557745C13.3579 0.20231 12.7814 0.20231 12.426 0.557745L7.48007 5.50386L2.53428 0.557888C2.1787 0.202453 1.60228 0.202453 1.24685 0.557888C0.891269 0.913323 0.891269 1.4898 1.24685 1.84538L6.83655 7.43519C7.01436 7.6129 7.24714 7.70166 7.48004 7.70166C7.71306 7.70166 7.94601 7.61273 8.12373 7.43504Z"
        fill={color}
      />
    </svg>
  );
});
