import { StatisticPartDay } from "types/business/StatisticPartDay";

import { dayToDayString } from "./dayToDayString";

export const mapOnFullMonthLength = (statistics: StatisticPartDay[]): string[] => {
  const statisticPartDayArray: string[] = [];

  for (let index = 1; index <= 31; index++) {
    const day = dayToDayString(index);

    const foundStatisticDay = statistics.filter((statisticDay) => statisticDay.day === day);

    if (foundStatisticDay.length !== 0) {
      const value = foundStatisticDay.reduce((acc, datePart) => {
        return acc + +datePart.value;
      }, 0);

      statisticPartDayArray.push(value.toString());
    } else {
      statisticPartDayArray.push("0");
    }
  }

  return statisticPartDayArray;
};
