/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const Search = createIcon("Search", (props) => {
  const { width = 13, height = 13, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#8BC3F5" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 13 13" fill="none">
      <path
        d="M8.4752 8.59202C7.8251 9.24213 6.94336 9.60736 6.02397 9.60736C5.10458 9.60736 4.22285 9.24213 3.57274 8.59202C2.92263 7.94192 2.5574 7.06018 2.5574 6.14079C2.5574 5.2214 2.92263 4.33967 3.57274 3.68956C4.22285 3.03945 5.10458 2.67423 6.02397 2.67423C6.94336 2.67423 7.8251 3.03945 8.4752 3.68956C9.12531 4.33967 9.49054 5.2214 9.49054 6.14079C9.49054 7.06018 9.12531 7.94192 8.4752 8.59202ZM12.3318 11.2191L10.2681 9.15548C10.9893 8.14003 11.3234 6.89665 11.2061 5.65199C11.0819 4.33369 10.4599 3.1123 9.46676 2.23645C8.47366 1.3606 7.18411 0.89614 5.86062 0.937615C4.53712 0.97909 3.27918 1.52338 2.34287 2.45969C1.40656 3.396 0.862269 4.65395 0.820794 5.97744C0.779319 7.30093 1.24378 8.59049 2.11963 9.58358C2.99547 10.5767 4.21687 11.1987 5.53517 11.3229C6.77993 11.4403 8.02274 11.1055 9.03805 10.3843L11.1016 12.4478C11.1814 12.5301 11.2767 12.5958 11.3819 12.6411C11.488 12.6867 11.6021 12.7108 11.7175 12.7119C11.8329 12.713 11.9474 12.691 12.0543 12.6474C12.1612 12.6038 12.2583 12.5393 12.34 12.4577C12.4217 12.3761 12.4863 12.2791 12.5301 12.1723C12.5739 12.0655 12.596 11.951 12.5951 11.8356C12.5941 11.7201 12.5702 11.606 12.5247 11.4999C12.4796 11.3945 12.414 11.2991 12.3318 11.2191Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </svg>
  );
});
