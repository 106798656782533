/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const ArrowLeft = createIcon("ArrowLeft", (props) => {
  const { width = 11, height = 13, color = "#208CEB" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 11 13" fill="none">
      <path
        d="M0.358886 6.99698C0.0255524 6.80453 0.025553 6.3234 0.358886 6.13095L10.1089 0.501786C10.4422 0.309336 10.8589 0.549899 10.8589 0.934799L10.8589 12.1931C10.8589 12.578 10.4422 12.8186 10.1089 12.6261L0.358886 6.99698"
        fill={color}
      />
    </svg>
  );
});
