import { useTranslation } from "react-i18next";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { STerminalsClearFilterButton } from "./TerminalsClearFilterButtonStyled/STerminalsClearFilterButton.styled";

export const TerminalsClearFilterButton = createComponent("TerminalsClearFilterButton", () => {
  const { t } = useTranslation();
  return (
    <STerminalsClearFilterButton type="button" onClick={terminalsPageService.controller.onClickTerminalsClearFilterButton}>
      {t("terminalsPage.terminalsClearFilterButtonTitle")}
    </STerminalsClearFilterButton>
  );
});
