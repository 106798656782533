import { CustomSelect } from "components/CustomSelect/CustomSelect.component";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { color_e0b4b4 } from "theme/setter/colorsValues/color_e0b4b4";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";

type SCompareYearSelectorProps = {
  isError: boolean;
};

const isErrorSet = setProps<SCompareYearSelectorProps>(({ isError }) => isError)(css`
  border: 1px solid ${color_e0b4b4};
`);

const isErrorNotSet = setProps<SCompareYearSelectorProps>(({ isError }) => !isError)(css`
  border: 1px solid ${color_a0b9d2};
`);

export const SCompareYearSelector = styled(CustomSelect)<SCompareYearSelectorProps>`
  width: 180px;
  background-color: transparent;
  appearance: none;

  ${isErrorSet};
  ${isErrorNotSet};
  ${setBorderRadius_4px};
`;
