import { ResetPasswordInputState } from "service/shared/others/ResetPasswordInputService/ResetPasswordInput.state";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

export class ResetPasswordInputController {
  public readonly state: ResetPasswordInputState;

  public readonly onChange = onChangeHelper((text: string): void => {
    this.state.value.next(text);
  });

  public constructor(state: ResetPasswordInputState) {
    this.state = state;
  }

  public readonly clearForm = (): void => {
    this.state.value.next("");
  };

  public readonly setShowError = (showError: boolean): void => {
    this.state.showError.next(showError);
  };
}
