import { BrowserRouter } from "packages/react-router-dom";
import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "App/App.component";

import "./i18n";
import "./index.css";

createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <React.Suspense fallback="Loading...">
      <App />
    </React.Suspense>
  </BrowserRouter>
);
