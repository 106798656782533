import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useRolesFormContext } from "context/RolesForm.context";
import { RolesSelectOptionModel } from "model/RolesSelectOption.model";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";

import { RolesSelectOptionProps } from "./RolesSelectOption.props";
import { SRolesSelectOption } from "./RolesSelectOptionStyled/SRolesSelectOption.styled";
import { SRolesSelectOptionCheckBox } from "./RolesSelectOptionStyled/SRolesSelectOptionCheckBox.styled";
import { SRolesSelectOptionDescription } from "./RolesSelectOptionStyled/SRolesSelectOptionDescription.styled";
import { SRolesSelectOptionTitle } from "./RolesSelectOptionStyled/SRolesSelectOptionTitle.styled";

export const RolesSelectOption = createComponent<RolesSelectOptionProps>("RolesSelectOption", (props) => {
  const { t } = useTranslation();
  const { rolesSelectOptionModel, isFirst } = props;
  const { service } = useRolesFormContext();

  const selectedOption = useDefaultObservable<RolesSelectOptionModel[]>(service.state.selectedOptions);

  const active = !!selectedOption.find((element) => element.title === rolesSelectOptionModel.title);

  return (
    <SRolesSelectOption isFirst={isFirst} onClick={service.controller.onClickOption(rolesSelectOptionModel)}>
      <SRolesSelectOptionTitle>{rolesSelectOptionModel.title}</SRolesSelectOptionTitle>
      <SRolesSelectOptionDescription>{t(rolesSelectOptionModel.langPath)}</SRolesSelectOptionDescription>

      <SRolesSelectOptionCheckBox>{active && <SvgImage name="Check" />}</SRolesSelectOptionCheckBox>
    </SRolesSelectOption>
  );
});
