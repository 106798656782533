import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const authCodeFilterModel = new FilterModel({
  id: 24,
  type: "text",
  title: t("authCodeFilterModel.title"),
  name: "AUTH_CODE",
  options: {},
  langPath: "authCodeFilterModel.title",
});
