/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const PaginationLeftDoubleArrow = createIcon("PaginationLeftDoubleArrow", (props) => {
  const { width = 15, height = 14, color = "#69809A" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 15 14" fill="none">
      <path
        d="M7.33858 6.81688L12.9284 1.22726C13.284 0.87151 13.8605 0.87151 14.2159 1.22726C14.5713 1.5827 14.5713 2.15918 14.2159 2.51458L9.26977 7.46054L14.2157 12.4063C14.5712 12.7619 14.5712 13.3383 14.2157 13.6938C13.8603 14.0493 13.2838 14.0493 12.9282 13.6938L7.33844 8.10406C7.16072 7.92625 7.07196 7.69347 7.07196 7.46057C7.07196 7.22755 7.16089 6.9946 7.33858 6.81688Z"
        fill={color}
      />
      <path
        d="M0.778583 6.81688L6.36839 1.22726C6.72397 0.87151 7.30048 0.87151 7.65588 1.22726C8.01132 1.5827 8.01132 2.15918 7.65588 2.51458L2.70977 7.46054L7.65574 12.4063C8.01117 12.7619 8.01117 13.3383 7.65573 13.6938C7.3003 14.0493 6.72382 14.0493 6.36824 13.6938L0.778439 8.10406C0.600721 7.92625 0.511963 7.69347 0.511963 7.46057C0.511963 7.22755 0.600894 6.9946 0.778583 6.81688Z"
        fill={color}
      />
    </svg>
  );
});
