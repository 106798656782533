import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectStatisticDataItemArray } from "observables/StatisticDataItemArrayObservable";
import { BehaviorSubjectStringArray } from "observables/StringArrayObservable";
import { CompareMonthService } from "service/shared/others/CompareMonthService/CompareMonth.service";
import { CompareTerminalService } from "service/shared/others/CompareTerminalService/CompareTerminal.service";
import { CompareYearService } from "service/shared/others/CompareYearService/CompareYear.service";

class State {
  public readonly statisticType: string;

  public readonly statisticStep: string;

  public readonly firstFetchedGroupTerminal: BehaviorSubjectStringArray;

  public readonly secondFetchedGroupTerminal: BehaviorSubjectStringArray;

  public readonly firstGroupCompareTerminalService: CompareTerminalService;

  public readonly firstGroupCompareYearService: CompareYearService;

  public readonly firstGroupCompareMonthService: CompareMonthService;

  public readonly secondGroupCompareTerminalService: CompareTerminalService;

  public readonly secondGroupCompareYearService: CompareYearService;

  public readonly secondGroupCompareMonthService: CompareMonthService;

  public readonly loaded: BehaviorSubjectBoolean;

  public readonly fetched: BehaviorSubjectBoolean;

  public readonly showError: BehaviorSubjectBoolean;

  public readonly firstStatistics: BehaviorSubjectStatisticDataItemArray;

  public readonly secondStatistics: BehaviorSubjectStatisticDataItemArray;

  public constructor() {
    this.statisticType = "SUM";
    this.statisticStep = "DAY";

    this.firstGroupCompareTerminalService = new CompareTerminalService();
    this.firstGroupCompareYearService = new CompareYearService();
    this.firstGroupCompareMonthService = new CompareMonthService();
    this.secondGroupCompareTerminalService = new CompareTerminalService();
    this.secondGroupCompareYearService = new CompareYearService();
    this.secondGroupCompareMonthService = new CompareMonthService();

    this.loaded = new BehaviorSubjectBoolean(false);
    this.fetched = new BehaviorSubjectBoolean(false);
    this.showError = new BehaviorSubjectBoolean(false);
    this.firstStatistics = new BehaviorSubjectStatisticDataItemArray([]);
    this.secondStatistics = new BehaviorSubjectStatisticDataItemArray([]);

    this.firstFetchedGroupTerminal = new BehaviorSubjectStringArray([]);
    this.secondFetchedGroupTerminal = new BehaviorSubjectStringArray([]);
  }
}

export const dynamicPageState = new State();
