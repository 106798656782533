import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SSelectedApplyButton } from "./ApplyButtonStyled/SSelectedApplyButton.styled";

export const ApplyButton = createComponent("ApplyButton", () => {
  const { t } = useTranslation();
  return <SSelectedApplyButton onClick={paymentDetailsPageService.controller.onClickApplyRefund}>{t("paymentDetailsPage.refundTitle")}</SSelectedApplyButton>;
});
