import { routerPaths } from "appConstants/routerPaths";
import { FormLayout } from "components/FormLayout/FormLayout.component";
import { Navigate, Route, Routes } from "packages/react-router-dom";
import { createComponent } from "utils/libExtend/createComponent";

import { createAppPage } from "App/createAppPage";
import * as Pages from "App/pages";

export const AppUnAuthPart = createComponent("AppUnAuthPart", () => {
  return (
    <Routes>
      <Route element={<FormLayout />}>
        {createAppPage(routerPaths.signIn, Pages.SignInPage)}
        {createAppPage(routerPaths.forgotPassword, Pages.ForgotPasswordPage)}
        {createAppPage(routerPaths.resetPassword, Pages.ResetPasswordPage)}
        {createAppPage(routerPaths.activateInvite, Pages.ActivateInvitePage)}
        <Route path="*" element={<Navigate to={routerPaths.signIn} />} />
      </Route>
    </Routes>
  );
});
