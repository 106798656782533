import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectOptionModelArray } from "observables/OptionModelArrayObservable";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

export class FilterFetchedMultipleSelectDataModel {
  public readonly value: BehaviorSubjectOptionModelArray;

  public readonly filterModel: FilterModel;

  public readonly optionList: BehaviorSubjectOptionModelArray;

  public readonly valid: BehaviorSubjectBoolean;

  private readonly loaded: BehaviorSubjectBoolean;

  private readonly fetchedOptionList: BehaviorSubjectOptionModelArray;

  private readonly startValue: OptionModel[];

  private readonly removeActiveFilter: (filterModel: FilterModel) => void;

  public constructor(filterModel: FilterModel, removeActiveFilter: (filterModelParam: FilterModel) => void) {
    this.loaded = new BehaviorSubjectBoolean(false);
    this.fetchedOptionList = new BehaviorSubjectOptionModelArray([]);
    this.optionList = setCombinedBehaviorSubject(this.setOptionList, this.loaded, this.fetchedOptionList);

    this.filterModel = filterModel;
    this.removeActiveFilter = removeActiveFilter;

    this.startValue = this.filterModel?.initialMultipleOptionValue || [];
    this.value = new BehaviorSubjectOptionModelArray([...this.startValue]);

    this.valid = setCombinedBehaviorSubject(this.setValid, this.value);
  }

  public readonly onMount = async (): Promise<void> => {
    await this.fetchData();
  };

  public readonly onClickAll = (): void => {
    this.value.next(this.optionList.value);
  };

  public readonly onChange = (value: OptionModel): void => {
    const foundOption = this.value.value.find((optionValue) => optionValue.value === value.value);

    if (foundOption) {
      this.value.next(this.value.value.filter((element) => element.value !== value.value));
    } else {
      this.value.value.push(value);
      this.value.next([...this.value.value]);
    }
  };

  public readonly onClickTrash = (): void => {
    this.removeActiveFilter(this.filterModel);
  };

  public readonly onClickClear = (): void => {
    this.resetValue();
  };

  private readonly resetValue = (): void => {
    this.value.next([...this.startValue]);
  };

  private readonly setValid = (value: string[]): boolean => {
    return value.length !== 0;
  };

  private readonly setOptionList = (loaded: boolean, fetchedOptionList: OptionModel[]): OptionModel[] => {
    if (loaded) {
      return fetchedOptionList;
    }

    return [new OptionModel("filterFetchedSelectDataModel.loadedTitle", t("filterFetchedSelectDataModel.loadedTitle"))];
  };

  private readonly fetchData = async (): Promise<void> => {
    if (this.filterModel?.optionListCallBack) {
      try {
        const optionList = await this.filterModel?.optionListCallBack();
        this.fetchedOptionList.next(optionList);
        this.loaded.next(true);
      } catch (error: any) {
        const { message } = error.response.data;
        toastContainerService.controller.createErrorToast(message);
      }
    }
  };
}
