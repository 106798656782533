import { client } from "api/client";
import { userService } from "api/userService";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18n";
import { authenticatedService } from "service/common/authenticatedService/authenticated.service";
import { redirectService } from "service/common/redirectService/redirect.service";
import { signInFormState } from "service/shared/singletones/signInFormService/signInForm.state";
import { EmailAndPasswordValidData } from "service/shared/singletones/signInFormService/types/EmailAndPasswordValidData";
import { RolesType } from "service/shared/singletones/signInFormService/types/RolesType";
import { UserInfoResponseType } from "service/shared/singletones/signInFormService/types/UserInfoResponseType";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { SignInDto } from "types/business/SignInDto";
import { MouseCustomEvent } from "types/libExtend/MouseCustomEvent";

class Controller {
  public readonly state = signInFormState;

  public readonly produceShowError = (): boolean => {
    let flag = false;

    const { emailValid } = this.getEmailAndPasswordValidData();

    if (this.state.passwordService.state.capsLockState.value) flag = true;
    if (this.state.passwordService.state.russianLetterState.value) flag = true;
    if (this.state.passwordService.state.digitsState.value) flag = true;
    if (this.state.passwordService.state.lengthState.value) flag = true;
    if (this.state.passwordService.state.lowercaseLettersState.value) flag = true;
    if (this.state.passwordService.state.specialSymbolsState.value) flag = true;
    if (this.state.passwordService.state.uppercaseLettersState.value) flag = true;
    if (!emailValid) flag = true;
    this.state.showErrors.next(true);

    return flag;
  };

  public readonly sendForm = async (event: MouseCustomEvent): Promise<void> => {
    event.preventDefault();

    const hasErrors = this.produceShowError();

    if (hasErrors) {
      this.state.emailService.controller.setShowError(true);
      this.state.passwordService.controller.setShowError(true);
      return;
    }

    this.state.isLoaded.next(true);

    const signInDto = this.getEmailAndPasswordValueData();

    try {
      await client.post(apiPaths.login, signInDto);

      userService.setIsLoggedFlag(true);

      this.clearForm();

      const { roles, email, id } = await this.getUserInfoResponse();

      const userHasNotReadRoles = await this.checkUserHasNotReadRoles(roles);

      if (userHasNotReadRoles) return;

      this.setUserServiceData(roles, email, id);

      authenticatedService.controller.authenticate();

      this.redirectByRole(roles);
    } catch (error: any) {
      console.log("error", error);
    }

    this.state.isLoaded.next(false);
  };

  public readonly getEmailAndPasswordValidData = (): EmailAndPasswordValidData => {
    return {
      emailValid: this.state.emailService.state.valid.value,
      passwordValid: this.state.passwordService.state.valid.value,
    };
  };

  public readonly getEmailAndPasswordValueData = (): SignInDto => {
    return new SignInDto(this.state.emailService.state.value.value, this.state.passwordService.state.value.value);
  };

  public readonly setUserServiceData = (roles: RolesType, id: string, email: string): void => {
    userService.setRoles(roles);
    userService.setId(id.toString());
    userService.name = email;
  };

  public readonly redirectByRole = (roles: RolesType): void => {
    const { redirect, rolesResult } = this.getRolesRedirect(roles);

    if (rolesResult) {
      redirectService.controller.setCurrentRedirectPage(redirect);
    } else {
      toastContainerService.controller.createErrorToast(t("signInFormService.accessDeniedTitle"));
    }
  };

  public readonly checkUserHasNotReadRoles = async (roles: RolesType): Promise<boolean> => {
    if (!this.checkReadRoles(roles)) {
      await client.post(apiPaths.logout);
      this.state.isLoaded.next(false);
      toastContainerService.controller.createErrorToast(t("signInFormService.systemUserAccessDenied"));
      return true;
    }

    return false;
  };

  public readonly getUserInfoResponse = async (): Promise<UserInfoResponseType> => {
    const userInfoResponse = await client.get(apiPaths.userInfo);
    return userInfoResponse.data;
  };

  public readonly clearForm = (): void => {
    this.state.emailService.controller.clearForm();
    this.state.passwordService.controller.clearForm();
  };

  private readonly checkReadRoles = (roles: RolesType): boolean => {
    return roles.some((role) => this.state.readRoles.indexOf(role) !== -1);
  };

  private readonly getRolesRedirect = (roles: RolesType): { rolesResult: boolean; redirect: string } => {
    for (let index = 0; index < roles.length; index++) {
      const path = this.state.rolesRedirectArray[roles[index]];

      if (path) {
        return { rolesResult: true, redirect: path };
      }
    }

    return { rolesResult: false, redirect: "" };
  };
}

export const signInFormController = new Controller();
