import { Row } from "components/Row/Row.component";
import { createComponent } from "utils/libExtend/createComponent";

import { TransferRowProps } from "./TransferRow.props";

export const TransferRow = createComponent<TransferRowProps>("TransferRow", (props) => {
  const { title, value } = props;

  return <Row title={title} value={value} type="big" />;
});
