import { ToastContainer } from 'components/ToastContainer/ToastContainer.component';
import { useDefaultObservableBoolean } from 'observables/BooleanObservable';
import { useDefaultObservableString } from 'observables/StringObservable';
import { useNavigate } from 'packages/react-router-dom';
import { authenticatedService } from 'service/common/authenticatedService/authenticated.service';
import { redirectService } from 'service/common/redirectService/redirect.service';
import { appService } from 'service/shared/singletones/appService/app.service';
import { createComponent } from 'utils/libExtend/createComponent';
import { useEffect } from 'utils/libReplace/useEffect';

import { AppAuthPart } from 'App/AppAuthPart.component';
import { SApp } from 'App/AppStyled/SApp.styled';
import { AppUnAuthPart } from 'App/AppUnAuthPart.component';

export const AppContent = createComponent('AppContent', () => {
  const userIsAuthenticated = useDefaultObservableBoolean(authenticatedService.state.userIsAuthenticated);
  const isRedirected = useDefaultObservableBoolean(redirectService.state.isRedirected);
  const currentRedirectPage = useDefaultObservableString(redirectService.state.currentRedirectPage);

  const history = useNavigate();

  useEffect(async () => {
    await appService.controller.onMount();
  }, [userIsAuthenticated]);

  useEffect(async () => {
    if (userIsAuthenticated) {
      await appService.controller.loadBankData();
    }
  }, [userIsAuthenticated]);

  useEffect(() => {
    if (!isRedirected) {
      history(currentRedirectPage);
      redirectService.controller.setIsRedirected();
    }
  }, [isRedirected, currentRedirectPage]);

  return (
    <SApp>
      {userIsAuthenticated ? <AppAuthPart /> : <AppUnAuthPart />}
      <ToastContainer />
    </SApp>
  );
});
