import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectOptionModelArray } from "observables/OptionModelArrayObservable";
import { BehaviorSubjectOptionModel } from "observables/OptionModelObservable";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { RefObject } from "types/libReplace/RefObject";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";
import { createRef } from "utils/libReplace/createRef";

export class FilterFetchedSelectDataModel {
  public readonly value: BehaviorSubjectOptionModel;

  public readonly filterModel: FilterModel;

  public readonly inputRef: RefObject<{ clearForm: () => void }>;

  public readonly optionList: BehaviorSubjectOptionModelArray;

  private readonly valid: BehaviorSubjectBoolean;

  private readonly loaded: BehaviorSubjectBoolean;

  private readonly fetchedOptionList: BehaviorSubjectOptionModelArray;

  private readonly startValue: OptionModel;

  private readonly removeActiveFilter: (filterModel: FilterModel) => void;

  public constructor(filterModel: FilterModel, removeActiveFilter: (filterModelParam: FilterModel) => void) {
    this.loaded = new BehaviorSubjectBoolean(false);
    this.fetchedOptionList = new BehaviorSubjectOptionModelArray([]);
    this.optionList = setCombinedBehaviorSubject(this.setOptionList, this.loaded, this.fetchedOptionList);

    this.filterModel = filterModel;
    this.removeActiveFilter = removeActiveFilter;

    this.inputRef = createRef<{
      clearForm: () => void;
    }>();

    this.startValue = this.filterModel?.initialOptionValue || new OptionModel("", "");
    this.value = new BehaviorSubjectOptionModel(this.startValue);

    this.valid = setCombinedBehaviorSubject(this.setValid, this.value);
  }

  public readonly onMount = async (): Promise<void> => {
    await this.fetchData();
  };

  public readonly onChange = (value: OptionModel): void => {
    this.value.next(value);
  };

  public readonly onClickTrash = (): void => {
    this.removeActiveFilter(this.filterModel);
  };

  public readonly onClickClear = (): void => {
    this.resetValue();
    this.inputRef.current?.clearForm();
  };

  private readonly resetValue = (): void => {
    this.value.next(this.startValue);
  };

  private readonly setValid = (value: OptionModel): boolean => {
    return this.filterModel.selectTitle !== value.value;
  };

  private readonly setOptionList = (loaded: boolean, fetchedOptionList: OptionModel[]): OptionModel[] => {
    if (loaded) {
      return fetchedOptionList;
    }

    return [new OptionModel("filterFetchedSelectDataModel.loadedTitle", t("filterFetchedSelectDataModel.loadedTitle"))];
  };

  private readonly fetchData = async (): Promise<void> => {
    if (this.filterModel?.optionListCallBack) {
      try {
        const optionList = await this.filterModel?.optionListCallBack();
        this.fetchedOptionList.next(optionList);
        this.loaded.next(true);
      } catch (error: any) {
        const { message } = error.response.data;
        toastContainerService.controller.createErrorToast(message);
      }
    }
  };
}
