/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const PaginationRightArrow = createIcon("PaginationRightArrow", (props) => {
  const { width = 9, height = 14, color = "#69809A" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 9 14" fill="none">
      <path
        d="M7.74519 6.81685L2.15538 1.22723C1.79981 0.871479 1.2233 0.87148 0.867889 1.22723C0.512454 1.58267 0.512454 2.15915 0.86789 2.51455L5.814 7.46051L0.868038 12.4063C0.512603 12.7619 0.512603 13.3383 0.868038 13.6937C1.22347 14.0493 1.79996 14.0493 2.15553 13.6937L7.74533 8.10402C7.92305 7.92622 8.01181 7.69344 8.01181 7.46054C8.01181 7.22752 7.92288 6.99457 7.74519 6.81685Z"
        fill={color}
      />
    </svg>
  );
});
