import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setFlexDFF1 } from "theme/setter/setFlexValues/setFlexDFF1";

export const STerminalsListTableBodyRow = styled.div`
  padding: 0 20px;

  border-bottom: 1px solid ${color_a0b9d2};

  ${setFlexDFF1};
`;
