import { OptionModel } from "model/Option.model";
import { BehaviorSubjectOptionModel } from "observables/OptionModelObservable";
import { initialOptionList } from "service/shared/others/ControlsSelectService/initialOptionList";

export class ControlsSelectState {
  public readonly optionList: OptionModel[];

  public readonly activeOption: BehaviorSubjectOptionModel;

  public constructor() {
    this.optionList = initialOptionList;
    this.activeOption = new BehaviorSubjectOptionModel(this.optionList[0]);
  }
}
