import { useTranslation } from "react-i18next";
import { invoiceDetailsPageService } from "service/shared/singletones/invoiceDetailsPageService/invoiceDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SDeleteButton } from "./DeleteButtonStyled/SDeleteButton.styled";

export const DeleteButton = createComponent("DeleteButton", () => {
  const { t } = useTranslation();
  return <SDeleteButton onClick={invoiceDetailsPageService.controller.onClickDeleteButton}>{t("invoiceDetailsPage.deleteButton")}</SDeleteButton>;
});
