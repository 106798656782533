import { Loader } from 'components/Loader/Loader.component';
import { useFiltersContext } from 'context/Filter.context';
import { useDefaultObservableNumber } from 'observables/NumberObservable';
import { useState } from 'packages/react';
import { useTranslation } from 'react-i18next';
import { toastContainerService } from 'service/shared/singletones/toastContainerService/toastContainer.service';
import { createComponent } from 'utils/libExtend/createComponent';

import { SDownloadButton } from './DownloadButtonStyled/SDownloadButton.styled';
import { DownloadLink } from './DownloadLink.component';
import { getCurrentDate } from './getCurrentDate';
import { userService } from 'api/userService';

export const DownloadButton = createComponent('DownloadButton', () => {
  const { t } = useTranslation();
  const { csvName, csvCallback, totalElements } = useFiltersContext();

  if (!totalElements) {
    return null;
  }

  const currentDate = getCurrentDate();

  const totalElementsValue = useDefaultObservableNumber(totalElements);

  const { fileExportLimit } = userService.getAppSettingsConfig();

  const [loading, setLoading] = useState<boolean>(false);
  const [fetched, setFetched] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  const onClick = async (): Promise<void> => {
    setLoading(true);

    if (totalElementsValue <= fileExportLimit) {
      const newData = await csvCallback!();
      setData(newData);
      setFetched(true);
    } else {
      toastContainerService.controller.createErrorToast(t('filters.downloadErrorMax', { count: fileExportLimit }));
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SDownloadButton
        disabled={totalElementsValue === 0}
        onClick={onClick}>
        {t('filters.downloadButtonTitle')}
      </SDownloadButton>
      {fetched && (
        <DownloadLink
          csvName={csvName}
          currentDate={currentDate}
          data={data}
        />
      )}
    </>
  );
});
