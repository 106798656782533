import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";

export class ChangePasswordInputState {
  public readonly value: BehaviorSubjectString;

  public readonly valid: BehaviorSubjectBoolean;

  public readonly capsLockState: BehaviorSubjectBoolean;

  public readonly digitsState: BehaviorSubjectBoolean;

  public readonly lengthState: BehaviorSubjectBoolean;

  public readonly lowercaseLettersState: BehaviorSubjectBoolean;

  public readonly russianLetterState: BehaviorSubjectBoolean;

  public readonly specialSymbolsState: BehaviorSubjectBoolean;

  public readonly uppercaseLettersState: BehaviorSubjectBoolean;

  public readonly showError: BehaviorSubjectBoolean;

  public constructor() {
    this.value = new BehaviorSubjectString("");
    this.valid = new BehaviorSubjectBoolean(false);
    this.showError = new BehaviorSubjectBoolean(false);
    this.capsLockState = new BehaviorSubjectBoolean(false);
    this.russianLetterState = new BehaviorSubjectBoolean(false);
    this.digitsState = new BehaviorSubjectBoolean(true);
    this.lengthState = new BehaviorSubjectBoolean(true);
    this.lowercaseLettersState = new BehaviorSubjectBoolean(true);
    this.specialSymbolsState = new BehaviorSubjectBoolean(true);
    this.uppercaseLettersState = new BehaviorSubjectBoolean(true);
  }
}
