import { useDefaultObservableStatisticPartArray } from "observables/StatisticPartArrayObservable";
import ReactECharts from "packages/echarts-for-react";
import { useTranslation } from "react-i18next";
import { statisticPageService } from "service/shared/singletones/statisticPageService/statisticPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { getRatioOptions } from "./getRatioOptions";
import { produceRatioStatistic } from "./produceRatioStatistic/produceRatioStatistic";
import { SStatisticConversionsContent } from "./StatisticConversionsContentStyled/SStatisticConversionsContent.styled";
import { SStatisticConversionsContentTitle } from "./StatisticConversionsContentStyled/SStatisticConversionsContentTitle.styled";

export const StatisticConversionsContent = createComponent("StatisticConversionsContent", () => {
  const { t } = useTranslation();
  const statistics = useDefaultObservableStatisticPartArray(statisticPageService.state.statistics);

  const { dateArray, successQuantityArray, failQuantityArray } = produceRatioStatistic(statistics);

  const option = getRatioOptions(dateArray, successQuantityArray, failQuantityArray);

  return (
    <SStatisticConversionsContent>
      <SStatisticConversionsContentTitle>{t("statisticPage.conversionsTitle")}</SStatisticConversionsContentTitle>
      <ReactECharts option={option} style={{ height: 400 }} opts={{ renderer: "svg" }} />
    </SStatisticConversionsContent>
  );
});
