import { css, setProps, styled } from "theme";

interface SFilterSelectDeleteProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterSelectDeleteProps>(({ isArabic }) => isArabic)(css`
  margin-right: 8px;
`);

const isNotArabicLang = setProps<SFilterSelectDeleteProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 8px;
`);

export const SFilterSelectDelete = styled.button<SFilterSelectDeleteProps>`
  height: 20px;
  ${isArabicLang};
  ${isNotArabicLang};
`;
