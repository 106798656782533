import { createComponent } from "utils/libExtend/createComponent";

import { LoaderProps } from "./Loader.props";
import { SLoader } from "./LoaderStyled/SLoader.styled";

export const Loader = createComponent<LoaderProps>("Loader", (props) => {
  const { small = false, className = "" } = props;

  return <SLoader className={className} small={small} />;
});
