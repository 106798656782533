import { createComponent } from "utils/libExtend/createComponent";

import { UsersChangeRowGroupProps } from "./UsersChangeRowGroup.props";
import { UsersChangeRowGroupCommon } from "./UsersChangeRowGroupCommon/UsersChangeRowGroupCommon.component";
import { UsersChangeRowGroupInput } from "./UsersChangeRowGroupInput/UsersChangeRowGroupInput.component";

export const UsersChangeRowGroup = createComponent<UsersChangeRowGroupProps>("UsersChangeRowGroup", (props) => {
  const { service, hasChange } = props;

  if (hasChange) {
    return <UsersChangeRowGroupInput service={service} />;
  }

  return <UsersChangeRowGroupCommon service={service} />;
});
