import { createUserRolesSelectFormController } from "./createUserRolesSelectForm.controller";
import { createUserRolesSelectFormState } from "./createUserRolesSelectForm.state";

class Service {
  public readonly state = createUserRolesSelectFormState;

  public readonly controller = createUserRolesSelectFormController;
}

export const createUserRolesSelectFormService = new Service();
