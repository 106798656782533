/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const ArrowRight = createIcon("ArrowRight", (props) => {
  const { width = 12, height = 13, color = "#208CEB" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 12 13" fill="none">
      <path
        d="M11.0008 6.13095C11.3341 6.3234 11.3341 6.80453 11.0008 6.99698L1.25079 12.6261C0.91746 12.8186 0.500794 12.578 0.500794 12.1931L0.500794 0.934799C0.500794 0.549899 0.91746 0.309337 1.25079 0.501787L11.0008 6.13095Z"
        fill={color}
      />
    </svg>
  );
});
