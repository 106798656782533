import { borderRadiusValues } from "theme/values/borderRadiusValues";
import { colorsOpacityValues } from "theme/values/colorsOpacityValues";
import { fontSizeValues } from "theme/values/fontSizeValues";
import { fontWeightValues } from "theme/values/fontWeightValues";
import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";

export const themeValuesFin = {
  colors: {
    ...colorsValuesFin,
    ...colorsOpacityValues,
  },
  fontSize: {
    ...fontSizeValues,
  },
  borderRadius: {
    ...borderRadiusValues,
  },
  fontWeightValues: {
    ...fontWeightValues,
  },
};
