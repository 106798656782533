import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";

export const operationTypeFilterModel = new FilterModel({
  id: 15,
  type: "select",
  title: t("operationTypeFilterModel.title"),
  name: "PAYMENT_TYPE",
  options: {
    optionList: [
      new OptionModel("operationTypeFilterModel.MIR_PAY", "MIR_PAY"),
      new OptionModel("operationTypeFilterModel.APPLE_PAY", "APPLE_PAY"),
      new OptionModel("operationTypeFilterModel.CARD", "CARD"),
      new OptionModel("operationTypeFilterModel.GOOGLE_PAY", "GOOGLE_PAY"),
      new OptionModel("operationTypeFilterModel.PAYMENT_TOKEN", "PAYMENT_TOKEN"),
      new OptionModel("operationTypeFilterModel.SAMSUNG_PAY", "SAMSUNG_PAY"),
      new OptionModel("operationTypeFilterModel.SBP", "SBP"),
    ],
  },
  langPath: "operationTypeFilterModel.title",
});
