import { FiveNewNineType } from "./FiveNewNineType";

export const getDateAndRationArray = (
  statistic: FiveNewNineType[]
): {
  dateArray: string[];
  successQuantityArray: number[];
  failQuantityArray: number[];
} => {
  const dateArray: string[] = [];
  const successQuantityArray: number[] = [];
  const failQuantityArray: number[] = [];

  statistic.forEach((el: FiveNewNineType) => {
    dateArray.push(el.datePart);
    successQuantityArray.push(el.successQuantity);
    failQuantityArray.push(el.failQuantity);
  });

  return {
    dateArray,
    successQuantityArray,
    failQuantityArray,
  };
};
