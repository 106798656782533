import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SApp = styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  ${setFlexDFFDC};
`;
