import { Row } from "components/Row/Row.component";
import { createComponent } from "utils/libExtend/createComponent";

import { RefundRowProps } from "./RefundRow.props";

export const RefundRow = createComponent<RefundRowProps>("RefundRow", (props) => {
  const { title, value } = props;

  return <Row title={title} value={value} type="big" />;
});
