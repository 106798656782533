import { useSupportArticleContext } from "context/SuportArticle.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SApplyChangesButton } from "./ApplyChangesButtonStyled/SApplyChangesButton.styled";

export const ApplyChangesButton = createComponent("ApplyChangesButton", () => {
  const { t } = useTranslation();
  const { service } = useSupportArticleContext();

  const formIsChanged = useDefaultObservableBoolean(service.state.formIsChanged);

  if (!formIsChanged) {
    return null;
  }

  return <SApplyChangesButton>{t("supportAdministrationPage.applyChangesButtonTitle")}</SApplyChangesButton>;
});
