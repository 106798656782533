import { supportPageController } from "service/shared/singletones/supportPageService/supportPage.controller";
import { supportPageState } from "service/shared/singletones/supportPageService/supportPage.state";

class Service {
  public readonly state = supportPageState;

  public readonly controller = supportPageController;
}

export const supportPageService = new Service();
