import { css, setFlexDFAIC, setProps, styled } from "theme";

interface SFetchedFilterSelectContainerProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFetchedFilterSelectContainerProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SFetchedFilterSelectContainer = styled.div<SFetchedFilterSelectContainerProps>`
  ${setFlexDFAIC};
  ${isArabicLang};
`;
