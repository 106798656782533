import { Filters } from "components/Filters/Filters.component";
import { TableNavigation } from "components/TableNavigation/TableNavigation.component";
import { useTranslation } from "react-i18next";
import { auditPageService } from "service/shared/singletones/auditPageService/auditPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { SAuditPage } from "./AuditPageStyled/SAuditPage.styled";
import { AuditTable } from "./AuditTable/AuditTable.component";

export const AuditPage = createComponent("AuditPage", () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    auditPageService.controller.mounted();

    return (): void => {
      auditPageService.controller.unMounted();
    };
  }, []);

  return (
    <SAuditPage isArabic={i18n.language === "ar"}>
      <Filters service={auditPageService.filterService} />
      <AuditTable />
      <TableNavigation service={auditPageService.state.filterNavigationService} />
    </SAuditPage>
  );
});
