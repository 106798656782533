import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { createArticleFormService } from "service/shared/singletones/createArticleFormService/createArticleForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SCreateArticleDescriptionInput } from "./CreateArticleDescriptionInputStyled/SCreateArticleDescriptionInput.styled";

export const CreateArticleDescriptionInput = createComponent("CreateArticleDescriptionInput", () => {
  const { t } = useTranslation();
  const descriptionValue = useDefaultObservableString(createArticleFormService.state.descriptionValue);
  const placeholder = t("supportAdministrationPage.createArticleDescriptionInputPlaceholder") || "";

  return (
    <SCreateArticleDescriptionInput
      placeholder={placeholder}
      rows={12}
      value={descriptionValue}
      onChange={createArticleFormService.controller.onDescriptionValueChange}
    />
  );
});
