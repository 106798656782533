import { userService } from "api/userService";
import { routerPaths } from "appConstants/routerPaths";
import { t } from "i18n";
import { MenuIconModel } from "model/MenuIcon.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { navigationMenuState } from "service/shared/singletones/navigationMenuService/navigationMenu.state";

class Controller {
  public readonly state = navigationMenuState;

  public readonly onMount = (): void => {
    const canGetPaymentInfo = userService.canGetPaymentInfo();
    const canGetInvoiceInfo = userService.canGetInvoiceInfo();
    const canGetRefundInfo = userService.canGetRefundInfo();
    const canGetTransferInfo = userService.canGetTransferInfo();
    const canGetStatisticPage = userService.canGetStatisticPage();
    const canGetRewardInfo = userService.canGetRewardInfo();
    const canGetSupportPage = userService.canGetSupportPage();
    const canGetH2HInfo = userService.canGetH2HInfo();

    const menuIconList = [];

    if (canGetPaymentInfo) {
      menuIconList.push(new MenuIconModel(0, "Payments", t("navigationMenuService.payments"), routerPaths.payments, "navigationMenuService.payments"));
    }

    if (canGetInvoiceInfo) {
      menuIconList.push(new MenuIconModel(1, "Invoices", t("navigationMenuService.invoices"), routerPaths.invoices, "navigationMenuService.invoices"));
    }

    if (canGetTransferInfo) {
      menuIconList.push(new MenuIconModel(2, "Transfers", t("navigationMenuService.transfers"), routerPaths.transfers, "navigationMenuService.transfers"));
    }

    if (canGetRefundInfo) {
      menuIconList.push(new MenuIconModel(3, "Refunds", t("navigationMenuService.refunds"), routerPaths.refunds, "navigationMenuService.refunds"));
    }

    if (canGetStatisticPage) {
      menuIconList.push(new MenuIconModel(5, "Statistic", t("navigationMenuService.statistic"), routerPaths.statistic, "navigationMenuService.statistic"));
      menuIconList.push(new MenuIconModel(4, "Dynamic", t("navigationMenuService.dynamic"), routerPaths.dynamic, "navigationMenuService.dynamic"));
    }

    if (canGetSupportPage) {
      menuIconList.push(new MenuIconModel(6, "Support", t("navigationMenuService.support"), routerPaths.support, "navigationMenuService.support"));
    }

    if (canGetRewardInfo) {
      menuIconList.push(new MenuIconModel(7, "Reward", t("navigationMenuService.reward"), routerPaths.rewards, "navigationMenuService.reward"));
    }

    if (canGetH2HInfo) {
      menuIconList.push(new MenuIconModel(8, "H2H", t("navigationMenuService.h2h"), routerPaths.h2h, "navigationMenuService.h2h"));
    }

    this.state.menuIconList.next([...menuIconList]);
    this.state.open = new BehaviorSubjectBoolean(false);
    this.state.loaded.next(true);
  };

  public readonly onUnMount = (): void => {
    this.state.menuIconList.next([]);
  };

  public readonly toggle = (): void => {
    this.state.open.next(!this.state.open.value);
  };
}

export const navigationMenuController = new Controller();
