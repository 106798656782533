import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { statisticPageService } from "service/shared/singletones/statisticPageService/statisticPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SStatisticTable } from "./StatisticTableStyled/SStatisticTable.styled";
import { SStatisticTableContainer } from "./StatisticTableStyled/SStatisticTableContainer.styled";

export const StatisticTable = createComponent("StatisticTable", () => {
  const loaded = useDefaultObservableBoolean(statisticPageService.state.loaded);
  const tableService = useDefaultObservableTableService(statisticPageService.state.tableService);

  return (
    <SStatisticTable>
      <SStatisticTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SStatisticTableContainer>
    </SStatisticTable>
  );
});
