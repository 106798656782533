import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModelArray } from "observables/OptionModelArrayObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { FetchedFilterMultipleSelectProps } from "./FetchedFilterMultipleSelect.props";
import { SFetchedFilterMultipleSelect } from "./FetchedFilterMultipleSelectStyled/SFetchedFilterMultipleSelect.styled";
import { SFetchedFilterMultipleSelectClear } from "./FetchedFilterMultipleSelectStyled/SFetchedFilterMultipleSelectClear.styled";
import { SFetchedFilterMultipleSelectContainer } from "./FetchedFilterMultipleSelectStyled/SFetchedFilterMultipleSelectContainer.styled";
import { SFetchedFilterMultipleSelectDelete } from "./FetchedFilterMultipleSelectStyled/SFetchedFilterMultipleSelectDelete.styled";
import { SFetchedFilterMultipleSelectSelect } from "./FetchedFilterMultipleSelectStyled/SFetchedFilterMultipleSelectSelect.styled";
import { SFetchedFilterMultipleSelectSelectContainer } from "./FetchedFilterMultipleSelectStyled/SFetchedFilterMultipleSelectSelectContainer.styled";
import { SFetchedFilterMultipleSelectTitle } from "./FetchedFilterMultipleSelectStyled/SFetchedFilterMultipleSelectTitle.styled";

export const FetchedFilterMultipleSelect = createComponent<FetchedFilterMultipleSelectProps>("FetchedFilterMultipleSelect", (props) => {
  const { t } = useTranslation();
  const { service } = props;
  const { service: filterService } = useFiltersContext();

  useEffect(async () => {
    await service.onMount();
  }, []);

  const optionList = useDefaultObservableOptionModelArray(service.optionList);
  const value = useDefaultObservableOptionModelArray(service.value);
  const isArabic = useDefaultObservableBoolean(filterService.state.isArabic);

  return (
    <SFetchedFilterMultipleSelect>
      <SFetchedFilterMultipleSelectTitle isArabic={isArabic}>{t(service.filterModel.langPath)}</SFetchedFilterMultipleSelectTitle>

      <SFetchedFilterMultipleSelectContainer isArabic={isArabic}>
        <SFetchedFilterMultipleSelectSelectContainer>
          <SFetchedFilterMultipleSelectSelect optionList={optionList} value={value} onClickAll={service.onClickAll} onChange={service.onChange} />

          <SFetchedFilterMultipleSelectClear isArabic={isArabic} onClick={service.onClickClear}>
            <SvgImage width={20} height={20} name="Close" />
          </SFetchedFilterMultipleSelectClear>
        </SFetchedFilterMultipleSelectSelectContainer>

        {filterService.state.showCloseIcon && (
          <SFetchedFilterMultipleSelectDelete isArabic={isArabic} onClick={service.onClickTrash}>
            <SvgImage width={20} height={20} name="DeleteFilter" />
          </SFetchedFilterMultipleSelectDelete>
        )}
      </SFetchedFilterMultipleSelectContainer>
    </SFetchedFilterMultipleSelect>
  );
});
