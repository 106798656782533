import { routerPaths } from "appConstants/routerPaths";
import { AuditDetailsCellProps } from "detailsCell/AuditDetailsCell/AuditDetailsCell.props";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";

export const AuditDetailsCell = createComponent<AuditDetailsCellProps>("AuditDetailsCell", (props) => {
  const { cellData } = props;

  if (cellData.paymentId) {
    const toPayment = replacePath(routerPaths.paymentDetails, { paymentId: cellData.paymentId });
    return <DetailsCell to={toPayment} />;
  }

  if (cellData.transferId) {
    const toTransfer = replacePath(routerPaths.transferDetails, { transferId: cellData.transferId });
    return <DetailsCell to={toTransfer} />;
  }

  return null;
});
