export class TopicOptionModel {
  public readonly id: string;

  public readonly bodyTemplate: string;

  public readonly subject: string;

  public constructor(dataItem: any) {
    this.id = dataItem.id ?? "0";
    this.bodyTemplate = dataItem.bodyTemplate ?? "0";
    this.subject = dataItem.subject ?? "0";
  }
}
