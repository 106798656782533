import { createArticleFormState } from "service/shared/singletones/createArticleFormService/createArticleForm.state";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

class Controller {
  public readonly state = createArticleFormState;

  public readonly onDescriptionValueChange = onChangeHelper((text: string): void => {
    this.state.descriptionValue.next(text);
  });

  public readonly onEmailValueChange = onChangeHelper((text: string): void => {
    this.state.emailValue.next(text);
  });

  public readonly onTypeValueChange = onChangeHelper((text: string): void => {
    this.state.typeValue.next(text);
  });

  public readonly onClickOpenFormButton = (): void => {
    this.state.open.next(true);
  };

  public readonly onCloseCallBack = (): void => {
    this.state.open.next(false);
  };
}

export const createArticleFormController = new Controller();
