import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTableContext } from "context/Table.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { DragDivType } from "types/libExtend/DragDivType";
import { MutableRefObject } from "types/libReplace/MutableRefObject";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useRef } from "utils/libReplace/useRef";

import { HeaderCellProps } from "./HeaderCell.props";
import { SHeaderCell } from "./HeaderCellStyled/SHeaderCell.styled";
import { SHeaderCellContainer } from "./HeaderCellStyled/SHeaderCellContainer.styled";
import { SHeaderCellDraggable } from "./HeaderCellStyled/SHeaderCellDraggable.styled";
import { SHeaderCellDraggableContainer } from "./HeaderCellStyled/SHeaderCellDraggableContainer.styled";
import { SHeaderCellDraggablePointer } from "./HeaderCellStyled/SHeaderCellDraggablePointer.styled";

export const HeaderCell = createComponent<HeaderCellProps>("HeaderCell", (props) => {
  const isFinOn = process.env.REACT_APP_FIN === "fin";
  const { headerCell } = props;
  const { t } = useTranslation();
  const { service } = useTableContext();

  const isArabic = useDefaultObservableBoolean(service.state.isArabic);

  const headerCellRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  useEffect(() => service.controller.onInitHeader(headerCell, headerCellRef), []);

  const style = headerCell.width ? { width: headerCell.width } : {};

  const onDrag = (event: DragDivType): void => service.controller.onDrag(event, headerCell);
  const onDragEnd = (event: DragDivType): void => service.controller.onDragEnd(event, headerCell);

  return (
    <SHeaderCell ref={headerCellRef}>
      {headerCell.callBack ? (
        <SHeaderCellContainer
          isArabic={isArabic}
          isCallBack
          isFirst={headerCell.isFirst}
          isLast={headerCell.isLast}
          style={style}
          onClick={headerCell.callBack}
        >
          {t(headerCell.langPath)}
          {headerCell.showAsc ? <SvgImage name="Asc" color={isFinOn ? colorsValuesFin.color_25476d : undefined} /> : null}
          {headerCell.showDesc ? <SvgImage name="Desc" color={isFinOn ? colorsValuesFin.color_25476d : undefined} /> : null}
        </SHeaderCellContainer>
      ) : (
        <SHeaderCellContainer isArabic={isArabic} isCallBack={false} isFirst={headerCell.isFirst} isLast={headerCell.isLast} style={style}>
          {t(headerCell.langPath)}
        </SHeaderCellContainer>
      )}

      {headerCell.isLast ? null : (
        <SHeaderCellDraggableContainer>
          <SHeaderCellDraggable />
          <SHeaderCellDraggablePointer draggable="true" onDrag={onDrag} onDragEnd={onDragEnd} />
        </SHeaderCellDraggableContainer>
      )}
    </SHeaderCell>
  );
});
