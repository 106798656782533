import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { StatisticChart } from "./StatisticChart/StatisticChart.component";
import { StatisticConversions } from "./StatisticConversions/StatisticConversions.component";
import { StatisticErrorMessage } from "./StatisticErrorMessage/StatisticErrorMessage.component";
import { StatisticHeader } from "./StatisticHeader/StatisticHeader.component";
import { SStatisticPage } from "./StatisticPageStyled/SStatisticPage.styled";
import { StatisticTable } from "./StatisticTable/StatisticTable.component";

export const StatisticPage = createComponent("StatisticPage", () => {
  const { i18n } = useTranslation();
  return (
    <SStatisticPage isArabic={i18n.language === "ar"}>
      <StatisticHeader />
      <StatisticErrorMessage />
      <StatisticTable />
      <StatisticConversions />
      <StatisticChart />
    </SStatisticPage>
  );
});
