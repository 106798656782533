import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { OptionModel } from "model/Option.model";
import { SupportQuestionModel } from "model/SupportQuestion.model";
import { TopicOptionModel } from "model/TopicOption.model";
import { supportPageState } from "service/shared/singletones/supportPageService/supportPage.state";
import { MouseCustomEvent } from "types/libExtend/MouseCustomEvent";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

class Controller {
  public readonly state = supportPageState;

  public readonly onChangeInput = onChangeHelper((text: string): void => {
    this.state.inputValue.next(text);
  });

  public readonly onMount = async (): Promise<void> => {
    await this.fetchQuestions();
    this.state.loadedContent.next(true);

    await this.fetchSelectOptions();
    this.state.loadedForm.next(true);
  };

  public readonly onChangeSelectValue = (newValue: OptionModel): void => {
    const foundTopic = this.state.topicOptions.find((topicOption) => topicOption.subject === newValue.value)!;
    this.state.inputValue.next(foundTopic.bodyTemplate);
    this.state.selectValue.next(newValue);
  };

  public readonly onClickSendButton = async (event: MouseCustomEvent): Promise<void> => {
    event.preventDefault();
    this.state.loadedForm.next(false);

    const foundTopic = this.state.topicOptions.find((topicOption) => topicOption.subject === this.state.selectValue.value.value)!;

    await client
      .post(apiPaths.assistRequest, {
        templateId: foundTopic.id,
        requestInfo: this.state.inputValue.value,
      })
      .catch(() => this.state.loadedForm.next(true));

    this.state.selectValue.next(new OptionModel("", ""));
    this.state.inputValue.next("");
    this.state.loadedForm.next(true);
  };

  private readonly produceQuestionModels = (data: any[]): SupportQuestionModel[] => {
    return data.map((dataItem) => {
      return new SupportQuestionModel(dataItem);
    });
  };

  private readonly fetchQuestions = async (): Promise<void> => {
    const { data } = await client.get(apiPaths.qna);
    const questionModels = this.produceQuestionModels(data);
    this.state.questionList.next(questionModels);
  };

  private readonly produceSelectOptions = (data: any[]): OptionModel[] => {
    return data.map((dataItem) => {
      const { subject } = dataItem;
      return new OptionModel(subject, subject);
    });
  };

  private readonly produceTopicOption = (data: any[]): TopicOptionModel[] => {
    return data.map((dataItem: any) => {
      return new TopicOptionModel(dataItem);
    });
  };

  private readonly fetchSelectOptions = async (): Promise<void> => {
    const { data } = await client.get(apiPaths.assistTemplate);
    this.state.topicOptions = this.produceTopicOption(data);
    const selectOptions = this.produceSelectOptions(data);
    this.state.selectOptionList.next(selectOptions);
  };
}

export const supportPageController = new Controller();
