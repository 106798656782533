import { setFlexDFFDC, setRowReverse, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SSupportPageQuestion = styled.div<Props>`
  gap: 8px;

  ${setFlexDFFDC};
  ${setRowReverse()};
`;
