import { Loader } from "components/Loader/Loader.component";
import { useTableContext } from "context/Table.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { Body } from "./Body/Body.component";
import { Header } from "./Header/Header.component";
import { STable } from "./TableContentStyled/STable.styled";
import { STableContainer } from "./TableContentStyled/STableContainer.styled";
import { STableEmpty } from "./TableContentStyled/STableEmpty.styled";
import { STableLoader } from "./TableContentStyled/STableLoader.styled";

export const TableContent = createComponent("TableContent", () => {
  const { t } = useTranslation();
  const { loaded, service } = useTableContext();

  const isEmpty = useDefaultObservableBoolean(service.state.isEmpty);

  if (!loaded) {
    return (
      <STable>
        <STableLoader>
          <Loader />
        </STableLoader>
      </STable>
    );
  }

  if (isEmpty) {
    return (
      <STable>
        <STableContainer>
          <Header />
          <STableEmpty>{t("tableContent.notDataTitle")}</STableEmpty>
        </STableContainer>
      </STable>
    );
  }

  return (
    <STable>
      <STableContainer>
        <Header />
        <Body />
      </STableContainer>
    </STable>
  );
});
