import { color_a0b9d2, css, setBorderRadius_4px, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SFeedbackFormTextField = styled.textarea<Props>`
  padding: 8px;

  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};
  ${isArabicLang};
`;
