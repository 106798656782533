import { SwitchedRow } from "components/SwitchedRow/SwitchedRow.component";
import { createComponent } from "utils/libExtend/createComponent";

import { PaymentSwitchedRowProps } from "./PaymentSwitchedRow.props";

export const PaymentSwitchedRow = createComponent<PaymentSwitchedRowProps>("PaymentSwitchedRow", (props) => {
  const { title, value } = props;

  return <SwitchedRow title={title} value={value} type="big" />;
});
