import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { ChangePasswordInputProps } from "./ChangePasswordInput.props";
import { SChangePasswordInput } from "./ChangePasswordInputStyled/SChangePasswordInput.styled";
import { SChangePasswordInputInput } from "./ChangePasswordInputStyled/SChangePasswordInputInput.styled";
import { SChangePasswordInputTitle } from "./ChangePasswordInputStyled/SChangePasswordInputTitle.styled";

export const ChangePasswordInput = createComponent<ChangePasswordInputProps>("ChangePasswordInput", (props) => {
  const { title, service } = props;

  const value = useDefaultObservableString(service.state.value);
  const showError = useDefaultObservableBoolean(service.state.showError);

  return (
    <SChangePasswordInput>
      <SChangePasswordInputTitle>{title}</SChangePasswordInputTitle>
      <div>
        <SChangePasswordInputInput
          showError={showError}
          onKeyUp={service.controller.onKeyUp}
          type="password"
          value={value}
          onChange={service.controller.onChange}
        />
      </div>
    </SChangePasswordInput>
  );
});
