import { css, setFlexDFFDCF1, setProps, styled } from "theme";

interface Props {
  isArabic: boolean;
}

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  padding-left: 80px;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  padding-right: 80px;
`);

export const SAuditPage = styled.div<Props>`
  width: 100%;

  ${isNotArabicLang};
  ${isArabicLang};
  ${setFlexDFFDCF1};
`;
