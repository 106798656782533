import { styled } from "theme/default/styled";
import { setColor_9f3a38 } from "theme/setter/setColorsValues/setColor_9f3a38";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const SInvoiceCreateSelectErrorMessage = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;

  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_9f3a38};
`;
