import { styled } from "theme/default/styled";
import { setColor_e74c3c } from "theme/setter/setColorsValues/setColor_e74c3c";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const SStatisticErrorMessageTitle = styled.div`
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_e74c3c};
`;
