import { styled } from "theme/default/styled";

import { PopUpContainer } from "../../../../components/PopUpContainer/PopUpContainer.component";
import { setBackgroundColor_rgba_0_0_0_50 } from "../../../../theme/setter/setBackgroundOpacityColorsValues/setBackgroundColor_rgba_0_0_0_50";
import { setFlexDF } from "../../../../theme/setter/setFlexValues/setFlexDF";

export const SContainer = styled(PopUpContainer)`
  ${setFlexDF}
  ${setBackgroundColor_rgba_0_0_0_50}
`;
