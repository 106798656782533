import { Link } from "components/Link/Link.component";
import { css } from "packages/styled-components";
import { styled } from "theme/default/styled";
import { setColor_1e70bf } from "theme/setter/setColorsValues/setColor_1e70bf";
import { setColor_4183c4 } from "theme/setter/setColorsValues/setColor_4183c4";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";

const isFinOn = process.env.REACT_APP_FIN === "fin";
const finOn = css`
  text-decoration: none;
  margin-bottom: 10px;
`;
const sngb = css`
  text-decoration: none;
`;
export const SSignInForgotPasswordButton = styled(Link)`
  ${isFinOn ? finOn : sngb}
  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_4183c4};

  &:hover {
    ${setColor_1e70bf};
  }
`;
