import { OptionModel } from "model/Option.model";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModelArray } from "observables/OptionModelArrayObservable";
import { useTranslation } from "react-i18next";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { CustomSelectOption } from "./CustomSelectOption/CustomSelectOption.component";
import { SelectOptionListProps } from "./SelectOptionList.props";
import { SSelectOptionList } from "./SelectOptionListStyled/SSelectOptionList.styled";

export const SelectOptionList = createComponent<SelectOptionListProps>("SelectOptionList", (props) => {
  const { className = "", service, value } = props;
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const filteredOptionList = useDefaultObservableOptionModelArray(service.state.filteredOptionList);
  const isFitContentList = useDefaultObservableBoolean(service.state.isFitContentList);
  const isDropDownOpened = useDefaultObservableBoolean(service.state.isDropDownOpened);

  if (!isDropDownOpened) {
    return null;
  }

  const renderOption: RenderFunctionType<OptionModel> = (option: OptionModel, index: number, array: OptionModel[]) => {
    const isFirst = index === 0;
    const isLast = index === array.length - 1;

    return <CustomSelectOption isFirst={isFirst} isLast={isLast} value={value} service={service} option={option} key={index} />;
  };
  return (
    <SSelectOptionList isArabic={isArabic} isFitContentList={isFitContentList} className={className}>
      {filteredOptionList.map(renderOption)}
    </SSelectOptionList>
  );
});
