import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectOptionModelArray } from "observables/OptionModelArrayObservable";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

export class CompareTerminalState {
  public readonly value: BehaviorSubjectOptionModelArray;

  public readonly valid: BehaviorSubjectBoolean;

  public readonly optionList: BehaviorSubjectOptionModelArray;

  public readonly loaded: BehaviorSubjectBoolean;

  public readonly showError: BehaviorSubjectBoolean;

  public constructor() {
    this.value = new BehaviorSubjectOptionModelArray([]);
    this.loaded = new BehaviorSubjectBoolean(false);
    this.showError = new BehaviorSubjectBoolean(false);
    this.optionList = new BehaviorSubjectOptionModelArray([]);

    this.valid = setCombinedBehaviorSubject(this.setValid, this.value);
  }

  private readonly setValid = (value: string[]): boolean => {
    return value.length !== 0;
  };
}
