import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { forgotPasswordFormService } from "service/shared/singletones/forgotPasswordFormService/forgotPasswordForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SForgotPasswordSendButton } from "./SendButtonStyled/SForgotPasswordSendButton.styled";
import { SForgotPasswordSendButtonLoader } from "./SendButtonStyled/SForgotPasswordSendButtonLoader.styled";

export const SendButton = createComponent("SendButton", () => {
  const isLoaded = useDefaultObservableBoolean(
    forgotPasswordFormService.state.isLoaded
  );
  const { t } = useTranslation();

  if (isLoaded) {
    return (
      <SForgotPasswordSendButtonLoader>
        <Loader small />
      </SForgotPasswordSendButtonLoader>
    );
  }

  return (
    <SForgotPasswordSendButton
      data-test-id="reset-password"
      onClick={forgotPasswordFormService.controller.sendForm}
    >
      {t("forgotPasswordPage.sendButtonTitle")}
    </SForgotPasswordSendButton>
  );
});
