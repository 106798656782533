import { userService } from "api/userService";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";

class State {
  public readonly userIsAuthenticated: BehaviorSubjectBoolean;

  public constructor() {
    const isLoggedFlag = userService.getIsLoggedFlag();

    this.userIsAuthenticated = new BehaviorSubjectBoolean(isLoggedFlag);
  }
}

export const authenticatedState = new State();
