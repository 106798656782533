import { css, setFlexDFFDC, setProps, styled } from "theme";

interface Props {
  isArabic: boolean;
}

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  padding-left: 80px;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  padding-right: 80px;
`);

export const SStatisticPage = styled.div<Props>`
  overflow: auto;
  width: 100%;
  margin-bottom: -16px;
  padding-bottom: 16px;

  ${isNotArabicLang};
  ${isArabicLang};
  ${setFlexDFFDC};
`;
