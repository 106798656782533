import { useDefaultObservableToastServiceArray } from "observables/ToastServiceArrayObservable";
import { ToastService } from "service/shared/others/ToastService/Toast.service";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { Toast } from "./Toast/Toast.component";
import { SToastContainer } from "./ToastContainerStyled/SToastContainer.styled";

export const ToastContainer = createComponent("ToastContainer", () => {
  const toasts = useDefaultObservableToastServiceArray(toastContainerService.state.toasts);

  const renderToast: RenderFunctionType<ToastService> = (service) => {
    return <Toast service={service} key={service.state.id} />;
  };

  return <SToastContainer>{toasts.map(renderToast)}</SToastContainer>;
});
