import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { routerPaths } from "appConstants/routerPaths";
import { t } from "i18n";
import { redirectService } from "service/common/redirectService/redirect.service";
import { activateInvitePageState } from "service/shared/singletones/activateInvitePageService/activateInvitePage.state";
import { ProducedStates } from "service/shared/singletones/activateInvitePageService/types/ProducedStates";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { MouseCustomEvent } from "types/libExtend/MouseCustomEvent";

class Controller {
  public readonly state = activateInvitePageState;

  public readonly checkToken = async (token: string): Promise<void> => {
    const url = `${apiPaths.invitationStatus}?invitationCode=${token}`;
    const { data } = await client.get(url);
    if (!data.active) {
      toastContainerService.controller.createErrorToast(t("activateInvitePageService.tokenErrorTitle"));
    }
  };

  public readonly onMount = async (search: string): Promise<void> => {
    try {
      const newSearch = search.slice(1, search.length);
      const token = newSearch.split("=")[1];

      await this.checkToken(token);

      if (token) {
        this.state.token = token;
      } else {
        toastContainerService.controller.createErrorToast(t("activateInvitePageService.tokenErrorTitle"));
        redirectService.controller.setCurrentRedirectPage(routerPaths.signIn);
      }
    } catch (error: any) {
      toastContainerService.controller.createErrorToast(t("activateInvitePageService.tokenErrorTitle"));
      redirectService.controller.setCurrentRedirectPage(routerPaths.signIn);
    }
  };

  public readonly produceStates: () => ProducedStates = () => {
    const passwordCapsLockState = this.state.passwordService.state.capsLockState.value;
    const repeatedPasswordCapsLockState = this.state.repeatedPasswordService.state.capsLockState.value;
    const passwordDigitsState = this.state.passwordService.state.digitsState.value;
    const repeatedPasswordDigitsState = this.state.repeatedPasswordService.state.digitsState.value;
    const passwordLengthState = this.state.passwordService.state.lengthState.value;
    const repeatedPasswordLengthState = this.state.repeatedPasswordService.state.lengthState.value;
    const passwordLowercaseLettersState = this.state.passwordService.state.lowercaseLettersState.value;
    const repeatedPasswordLowercaseLettersState = this.state.repeatedPasswordService.state.lowercaseLettersState.value;
    const passwordRussianLetterState = this.state.passwordService.state.russianLetterState.value;
    const repeatedPasswordRussianLetterState = this.state.repeatedPasswordService.state.russianLetterState.value;
    const passwordSpecialSymbolsState = this.state.passwordService.state.specialSymbolsState.value;
    const repeatedPasswordSpecialSymbolsState = this.state.repeatedPasswordService.state.specialSymbolsState.value;
    const passwordUppercaseLettersState = this.state.passwordService.state.uppercaseLettersState.value;
    const repeatedPasswordUppercaseLettersState = this.state.repeatedPasswordService.state.uppercaseLettersState.value;

    return {
      formCapsLockState: passwordCapsLockState || repeatedPasswordCapsLockState,
      formDigitsState: passwordDigitsState || repeatedPasswordDigitsState,
      formLengthState: passwordLengthState || repeatedPasswordLengthState,
      formLowercaseLettersState: passwordLowercaseLettersState || repeatedPasswordLowercaseLettersState,
      formRussianLetterState: passwordRussianLetterState || repeatedPasswordRussianLetterState,
      formSpecialSymbolsState: passwordSpecialSymbolsState || repeatedPasswordSpecialSymbolsState,
      formUppercaseLettersState: passwordUppercaseLettersState || repeatedPasswordUppercaseLettersState,
    };
  };

  public readonly produceShowError = (): boolean => {
    let flag = false;

    const activationCodeValid = this.state.activationCodeService.state.valid.value;

    const password = this.state.passwordService.state.value.value;
    const repeatedPassword = this.state.repeatedPasswordService.state.value.value;

    const passwordNotEqualRepeatedPasswordError = password !== repeatedPassword;

    const states = this.produceStates();

    if (states.formDigitsState) flag = true;
    if (states.formLengthState) flag = true;
    if (states.formLowercaseLettersState) flag = true;
    if (states.formRussianLetterState) flag = true;
    if (states.formSpecialSymbolsState) flag = true;
    if (states.formUppercaseLettersState) flag = true;
    if (passwordNotEqualRepeatedPasswordError) flag = true;
    if (!activationCodeValid) flag = true;

    this.state.showPasswordNotEqualRepeatedPasswordError.next(passwordNotEqualRepeatedPasswordError);

    this.state.showErrors.next(true);

    return flag;
  };

  public readonly sendForm = async (event: MouseCustomEvent): Promise<void> => {
    event.preventDefault();

    const hasErrors = this.produceShowError();

    if (hasErrors) {
      this.state.passwordService.state.showError.next(true);
      this.state.repeatedPasswordService.state.showError.next(true);
      this.state.activationCodeService.state.showError.next(true);
      return;
    }

    this.state.isLoaded.next(true);

    try {
      await client.post(apiPaths.activateUser, {
        invitationCode: this.state.token,
        otpCode: this.state.activationCodeService.state.value.value,
        password: this.state.passwordService.state.value.value,
      });
      toastContainerService.controller.createSuccessToast(t("activateInvitePage.successMessage"));
      redirectService.controller.setCurrentRedirectPage(routerPaths.signIn);
    } catch (e: any) {
      console.log("e", e);
    }

    this.state.isLoaded.next(false);
  };
}

export const activateInvitePageController = new Controller();
