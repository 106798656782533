import { borderRadius_8px, color_rgba_183_200_220_80, css, setBackgroundColor_ffffff, setProps, styled } from "theme";

interface SFilterMenuProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterMenuProps>(({ isArabic }) => isArabic)(css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`);

export const SFilterMenu = styled.div<SFilterMenuProps>`
  padding: 12px;

  border-top-right-radius: ${borderRadius_8px};
  border-bottom-right-radius: ${borderRadius_8px};
  border-bottom-left-radius: ${borderRadius_8px};
  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${setBackgroundColor_ffffff};
  ${isArabicLang};
`;
