import { Filters } from "components/Filters/Filters.component";
import { h2hPageService } from "service/shared/singletones/h2hPageService/h2hPage.service";
import { createComponent } from "utils/libExtend/createComponent";

export const H2HPageFilters = createComponent("H2HPageFilters", () => {
  return (
    <Filters
      totalElements={h2hPageService.state.filterNavigationService.state.totalElements}
      service={h2hPageService.filterService}
      csvCallback={h2hPageService.getTotalData}
      csvName={h2hPageService.csvName}
    />
  );
});
