import { ChangeEmailPhoneInputController } from "service/shared/others/ChangeEmailPhoneInputService/ChangeEmailPhoneInput.controller";
import { ChangeEmailPhoneInputState } from "service/shared/others/ChangeEmailPhoneInputService/ChangeEmailPhoneInput.state";

export class ChangeEmailPhoneInputService {
  public readonly state: ChangeEmailPhoneInputState;

  public readonly controller: ChangeEmailPhoneInputController;

  public constructor() {
    this.state = new ChangeEmailPhoneInputState();
    this.controller = new ChangeEmailPhoneInputController(this.state);
  }
}
