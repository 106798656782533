import { styled } from "theme/default/styled";

import { color_a0b9d2 } from "../../../../theme/setter/colorsValues/color_a0b9d2";
import { setFlexDF } from "../../../../theme/setter/setFlexValues/setFlexDF";

export const SDataContainer = styled.div`
  ${setFlexDF}
  padding: 4px 0;
  border-bottom: 1px solid ${color_a0b9d2};
`;
