import { DetailsLayout } from "components/DetailsLayout/DetailsLayout.component";
import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useParams } from "utils/libReplace/useParams";

import { UsersDetailsContent } from "./UsersDetailsContent/UsersDetailsContent.component";
import { SUsersDetailsPageLoader } from "./UsersDetailsPageStyled/SUsersDetailsPageLoader.styled";

export const UsersDetailsPage = createComponent("UsersDetailsPage", () => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();

  useEffect(async () => {
    await usersDetailsPageService.controller.mount(userId);
    return (): void => {
      usersDetailsPageService.controller.unMount();
    };
  }, []);

  const loaded = useDefaultObservableBoolean(usersDetailsPageService.state.loaded);

  return (
    <DetailsLayout title={t("userInfoPage.title")}>
      {loaded ? (
        <UsersDetailsContent />
      ) : (
        <SUsersDetailsPageLoader>
          <Loader />
        </SUsersDetailsPageLoader>
      )}
    </DetailsLayout>
  );
});
