import { FilterModel } from "model/Filter.model";
import { FilterCheckboxDataModel } from "model/FilterCheckboxData.model";
import { FilterFetchedMultipleSelectDataModel } from "model/FilterFetchedMultipleSelectData.model";
import { FilterFetchedSelectDataModel } from "model/FilterFetchedSelectData.model";
import { FilterInputDataModel } from "model/FilterInputData.model";
import { FilterRangeDataModel } from "model/FilterRangeData.model";
import { FilterSelectDataModel } from "model/FilterSelectData.model";
import { OptionModel } from "model/Option.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectFilterModelArray } from "observables/FilterModelArrayObservable";
import { BehaviorSubjectFilterModelTypeArray } from "observables/FilterModelTypeArrayObservable";
import { BehaviorSubjectOptionModelArray } from "observables/OptionModelArrayObservable";
import { FilterDataFields } from "types/business/FilterDataFields";
import { FilterModelType } from "types/business/FilterModelType";
import { Dictionary } from "types/commonExtend/Dictionary";
import { filterFormatDate } from "utils/business/filterFormatDate";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

type ParamsType = {
  showCloseIcon: boolean;
  initialOpen: boolean;
  initialArabic: boolean;
};

export class FiltersState {
  public readonly showCloseIcon: boolean;

  public readonly open: BehaviorSubjectBoolean;

  public readonly isArabic: BehaviorSubjectBoolean;

  public readonly activeFilterList: BehaviorSubjectFilterModelTypeArray;

  public readonly openFilterSelector: BehaviorSubjectBoolean;

  public readonly filterListIsEmpty: BehaviorSubjectBoolean;

  public readonly filterListOption: BehaviorSubjectOptionModelArray;

  public readonly applyCallBack: () => void;

  private readonly initialOpen: boolean;

  private readonly initialArabic: boolean;

  private readonly filterList!: BehaviorSubjectFilterModelArray;

  public constructor(filterConfig: FilterModel[], applyCallBack: () => void, params?: ParamsType) {
    this.showCloseIcon = params?.showCloseIcon ?? true;
    this.initialOpen = params?.initialOpen ?? false;
    this.initialArabic = params?.initialArabic ?? false;
    this.open = new BehaviorSubjectBoolean(this.initialOpen);
    this.isArabic = new BehaviorSubjectBoolean(this.initialArabic);
    this.activeFilterList = new BehaviorSubjectFilterModelTypeArray([]);
    this.openFilterSelector = new BehaviorSubjectBoolean(false);
    this.filterList = new BehaviorSubjectFilterModelArray(filterConfig);
    this.applyCallBack = applyCallBack;

    this.filterList.value.forEach((filterModel) => {
      if (filterModel.default) {
        this.addActiveFilter(filterModel);
        this.deleteFilter(filterModel);
      }
    });

    this.filterListIsEmpty = setCombinedBehaviorSubject(this.setFilterListIsEmpty, this.filterList);
    this.filterListOption = setCombinedBehaviorSubject(this.setFilterListOption, this.filterList);
  }

  public readonly onChange = (value: OptionModel): void => {
    const filterModel = this.filterList.value.find((filter) => filter.name === value.value)!;
    this.addActiveFilter(filterModel);
    this.deleteFilter(filterModel);
  };

  public readonly getProduceFilterData = (): Dictionary<string> => {
    return this.activeFilterList.value
      .map((filter: FilterModelType): Dictionary<FilterDataFields> | null => {
        if (filter.filterModel.type === "range") {
          return this.getRangeFilterDate(filter as FilterRangeDataModel);
        }
        if (filter.filterModel.type === "text") {
          return this.getTextFilterDate(filter as FilterInputDataModel);
        }
        if (filter.filterModel.type === "select") {
          return this.getSelectFilterDate(filter as FilterSelectDataModel);
        }
        if (filter.filterModel.type === "fetched-select") {
          return this.getFetchedSelectFilterDate(filter as FilterSelectDataModel);
        }
        if (filter.filterModel.type === "checkbox") {
          return this.getCheckboxFilterDate(filter as FilterCheckboxDataModel);
        }
        if (filter.filterModel.type === "fetched-multiple-select") {
          return this.getFetchedMultipleSelectFilterDate(filter as FilterFetchedMultipleSelectDataModel);
        }
        return null;
      })
      .reduce((acc: any, el: any) => {
        const key = Object.keys(el)[0];
        const item = Object.values(el)[0] as FilterDataFields | null;

        if (item && item.valid) {
          return Object.assign(acc, { [key]: item.value });
        }

        return Object.assign(acc, {});
      }, {});
  };

  public readonly removeActiveFilter = (filterModel: FilterModel): void => {
    this.deleteActiveFilter(filterModel);
    this.addFilter(filterModel);
  };

  private readonly setFilterListIsEmpty = (filterList: FilterModel[]): boolean => {
    return filterList.length === 0;
  };

  private readonly setFilterListOption = (filterList: FilterModel[]): OptionModel[] => {
    filterList.sort((a: FilterModel, b: FilterModel) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
    return filterList.map((filter: FilterModel) => {
      return new OptionModel(filter.title, filter.name);
    });
  };

  private readonly addTextFilter = (filterModel: FilterModel): void => {
    const filter = new FilterInputDataModel(filterModel, this.removeActiveFilter);
    this.activeFilterList.value.push(filter);
    this.activeFilterList.next([...this.activeFilterList.value]);
  };

  private readonly addCheckboxFilter = (filterModel: FilterModel): void => {
    const filter = new FilterCheckboxDataModel(filterModel, this.removeActiveFilter);
    this.activeFilterList.value.push(filter);
    this.activeFilterList.next([...this.activeFilterList.value]);
  };

  private readonly addRangeFilter = (filterModel: FilterModel): void => {
    const filter = new FilterRangeDataModel(filterModel, this.removeActiveFilter);
    this.activeFilterList.value.push(filter);
    this.activeFilterList.next([...this.activeFilterList.value]);
  };

  private readonly addSelectFilter = (filterModel: FilterModel): void => {
    const filter = new FilterSelectDataModel(filterModel, this.removeActiveFilter);
    this.activeFilterList.value.push(filter);
    this.activeFilterList.next([...this.activeFilterList.value]);
  };

  private readonly addFetchedSelectFilter = (filterModel: FilterModel): void => {
    const filter = new FilterFetchedSelectDataModel(filterModel, this.removeActiveFilter);
    this.activeFilterList.value.push(filter);
    this.activeFilterList.next([...this.activeFilterList.value]);
  };

  private readonly addFetchedMultipleSelectFilter = (filterModel: FilterModel): void => {
    const filter = new FilterFetchedMultipleSelectDataModel(filterModel, this.removeActiveFilter);
    this.activeFilterList.value.push(filter);
    this.activeFilterList.next([...this.activeFilterList.value]);
  };

  private readonly addActiveFilter = (filterModel: FilterModel): void => {
    if (filterModel.type === "text") {
      this.addTextFilter(filterModel);
    } else if (filterModel.type === "range") {
      this.addRangeFilter(filterModel);
    } else if (filterModel.type === "select") {
      this.addSelectFilter(filterModel);
    } else if (filterModel.type === "fetched-select") {
      this.addFetchedSelectFilter(filterModel);
    } else if (filterModel.type === "checkbox") {
      this.addCheckboxFilter(filterModel);
    } else if (filterModel.type === "fetched-multiple-select") {
      this.addFetchedMultipleSelectFilter(filterModel);
    }
  };

  private readonly deleteFilter = (filterModel: FilterModel): void => {
    const newFilterList = this.filterList.value.filter((filter) => filter !== filterModel);
    this.filterList.next(newFilterList);
  };

  private readonly getRangeFilterDate = (filter: FilterRangeDataModel): Dictionary<FilterDataFields> => {
    const { name } = filter.filterModel;
    const value = filterFormatDate(filter.startValue.value, filter.endValue.value);

    return {
      [name]: { value, valid: true },
    };
  };

  private readonly getTextFilterDate = (filter: FilterInputDataModel): Dictionary<FilterDataFields> => {
    const { name } = filter.filterModel;
    const { value } = filter.value;
    const valid = filter.valid.value;

    return {
      [name]: { value, valid },
    };
  };

  private readonly getSelectFilterDate = (filter: FilterSelectDataModel): Dictionary<FilterDataFields> => {
    const { name } = filter.filterModel;
    const { value } = filter.value.value;
    const valid = filter.valid.value;

    return {
      [name]: { value, valid },
    };
  };

  private readonly getFetchedSelectFilterDate = (filter: FilterSelectDataModel): Dictionary<FilterDataFields> => {
    const { name } = filter.filterModel;
    const { value } = filter.value.value;
    const valid = filter.valid.value;

    return {
      [name]: { value, valid },
    };
  };

  private readonly getFetchedMultipleSelectFilterDate = (filter: FilterFetchedMultipleSelectDataModel): Dictionary<FilterDataFields> => {
    const { name } = filter.filterModel;
    const value = filter.value.value.map((element) => element.value);
    const valid = filter.valid.value;

    return {
      [name]: { value, valid },
    };
  };

  private readonly getCheckboxFilterDate = (filter: FilterCheckboxDataModel): Dictionary<FilterDataFields> => {
    const { name } = filter.filterModel;
    const { value } = filter.value;

    return {
      [name]: { valid: true, value },
    };
  };

  private readonly deleteActiveFilter = (filterModel: FilterModel): void => {
    const filter = this.activeFilterList.value.filter((filterElement) => {
      return filterElement.filterModel !== filterModel;
    })!;

    this.activeFilterList.next(filter);
  };

  private readonly addFilter = (filterModel: FilterModel): void => {
    this.filterList.value.push(filterModel);
    this.filterList.next(this.filterList.value);
  };
}
