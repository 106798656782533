import { useEffect as commonUseEffect } from "packages/react";
import { DependencyList } from "react";

export const useEffect = (effect: () => void, deps?: DependencyList): void => {
  commonUseEffect(() => {
    (async (): Promise<void> => {
      await effect();
    })();
  }, deps);
};
