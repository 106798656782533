import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableStartNumberEndNumber } from "observables/StartNumberEndNumberObservable";
import { useTranslation } from "react-i18next";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { STerminalsList } from "./TerminalsListStyled/STerminalsList.styled";
import { STerminalsListContainer } from "./TerminalsListStyled/STerminalsListContainer.styled";
import { STerminalsListCount } from "./TerminalsListStyled/STerminalsListCount.styled";
import { STerminalsListCountBolder } from "./TerminalsListStyled/STerminalsListCountBolder.styled";
import { STerminalsListLoaderContainer } from "./TerminalsListStyled/STerminalsListLoaderContainer.styled";
import { STerminalsListTitle } from "./TerminalsListStyled/STerminalsListTitle.styled";
import { TerminalsListTable } from "./TerminalsListTable/TerminalsListTable.component";

export const TerminalsList = createComponent("TerminalsList", () => {
  const { t } = useTranslation();
  const loaded = useDefaultObservableBoolean(terminalsPageService.state.loaded);
  const terminalsCount = useDefaultObservableStartNumberEndNumber(terminalsPageService.state.terminalsCount);

  if (!loaded) {
    return (
      <STerminalsListLoaderContainer>
        <Loader />
      </STerminalsListLoaderContainer>
    );
  }

  return (
    <STerminalsList>
      <STerminalsListTitle>{t("terminalsPage.terminalListTitle")}</STerminalsListTitle>
      <STerminalsListContainer>
        <STerminalsListCount>
          {t("terminalsPage.countPreTitle")}
          <STerminalsListCountBolder>{` ${terminalsCount.start}-${terminalsCount.end} `}</STerminalsListCountBolder>
          {t("terminalsPage.countPostTitle")}
        </STerminalsListCount>
        <TerminalsListTable />
      </STerminalsListContainer>
    </STerminalsList>
  );
});
