import { borderRadius_8px, color_rgba_183_200_220_80, css, setBackgroundColor_ffffff, setFlexDFFDC, setProps, styled } from "theme";

type SNavigationMenuProps = {
  open: boolean;
  isArabic: boolean;
};

const isArabicLang = setProps<SNavigationMenuProps>(({ isArabic }) => isArabic)(css`
  border-top-left-radius: ${borderRadius_8px};
`);
const isNotArabicLang = setProps<SNavigationMenuProps>(({ isArabic }) => !isArabic)(css`
  border-top-right-radius: ${borderRadius_8px};
`);
const openSet = setProps<SNavigationMenuProps>(({ open }) => open)(css`
  width: 220px;
`);

const openNotSet = setProps<SNavigationMenuProps>(({ open }) => !open)(css`
  width: 62px;
`);

export const SNavigationMenu = styled.div<SNavigationMenuProps>`
  margin-bottom: -50px;

  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${isArabicLang};
  ${isNotArabicLang};
  ${setFlexDFFDC};
  ${setBackgroundColor_ffffff};
  ${openSet};
  ${openNotSet};
`;
