import { CustomSelect } from "components/CustomSelect/CustomSelect.component";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setFlexF1 } from "theme/setter/setFlexValues/setFlexF1";

export const SSupportArticleSelect = styled(CustomSelect)`
  border: 1px solid ${color_a0b9d2};

  ${setFlexF1};
  ${setBorderRadius_4px};
`;
