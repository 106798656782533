import { FiltersNavigationController } from "service/shared/others/FiltersNavigationService/FiltersNavigation.controller";
import { FiltersNavigationState } from "service/shared/others/FiltersNavigationService/FiltersNavigation.state";

type ConstructorParams = {
  showCountElements?: boolean;
  initialArabic: boolean;
};

export class FiltersNavigationService {
  public readonly state: FiltersNavigationState;

  public readonly controller: FiltersNavigationController;

  public constructor(params?: ConstructorParams) {
    this.state = new FiltersNavigationState(params);
    this.controller = new FiltersNavigationController(this.state);
  }
}
