import { StatisticDataItem } from "types/business/StatisticDataItem";

import { getFromStatisticDayAndValue } from "./getFromStatisticDayAndValue";
import { mapOnFullMonthLength } from "./mapOnFullMonthLength";

export const getStatisticData = (
  firstStatistics: StatisticDataItem[],
  secondStatistics: StatisticDataItem[]
): {
  firstValueArray: string[];
  secondValueArray: string[];
} => {
  const firstStatisticMap = getFromStatisticDayAndValue(firstStatistics);
  const firstValueArray = mapOnFullMonthLength(firstStatisticMap);

  const secondStatisticMap = getFromStatisticDayAndValue(secondStatistics);
  const secondValueArray = mapOnFullMonthLength(secondStatisticMap);

  return {
    firstValueArray,
    secondValueArray,
  };
};
