import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { RefundDetailsModel } from "model/RefundDetails.model";
import { TableService } from "service/shared/others/TableService/Table.service";
import { getInitialHeaderCellList } from "service/shared/singletones/refundDetailsPageService/getInitialHeaderCellList";
import { refundDetailsPageState } from "service/shared/singletones/refundDetailsPageService/refundDetailsPage.state";
import { PaymentModelWithPaymentId } from "types/business/PaymentModelWithPaymentId";
import { replacePath } from "utils/commonExtend/replacePath";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class Controller {
  public readonly state = refundDetailsPageState;

  public readonly onMounted = async (refundId: string): Promise<void> => {
    this.state.initialHeaderCellList = getInitialHeaderCellList();
    this.state.payments = setCombinedBehaviorSubject(this.setPayments, this.state.refund);
    this.state.tableService = setCombinedBehaviorSubject(this.setTableService, this.state.payments);
    this.setRefundId(refundId);
    await this.getRefund();
  };

  public readonly unMounted = (): void => {
    this.state.refund.next(null);
    this.state.loaded.next(false);
  };

  private readonly getRefund = async (): Promise<void> => {
    this.state.loaded.next(false);
    const data: any[] = await this.fetchRefund();
    const produceData = this.produceRefund(data);
    this.state.refund.next(produceData);
    this.state.loaded.next(true);
  };

  private readonly setRefundId = (refundId: string): void => {
    this.state.refundId = refundId;
  };

  private readonly setPayments = (refund: RefundDetailsModel | null): PaymentModelWithPaymentId[] => {
    return [{ paymentId: refund?.paymentId || "" }];
  };

  private readonly produceRefund = (dataItem: any): RefundDetailsModel => {
    return new RefundDetailsModel(dataItem);
  };

  private readonly fetchRefund = async (): Promise<any> => {
    const { data } = await client.get(
      replacePath(apiPaths.refundInfoId, {
        refundId: this.state.refundId,
      })
    );
    return data;
  };

  private readonly setTableService = (payments: PaymentModelWithPaymentId[]): TableService => {
    return new TableService(payments, this.state.initialHeaderCellList);
  };
}

export const refundDetailsPageController = new Controller();
