import { convertFetchedDateToTableShowDate } from "utils/business/convertFetchedDateToTableShowDate";

export class RefundDetailsModel {
  public readonly amount: string;

  public readonly currency: string;

  public readonly paymentSystem: string;

  public readonly maskedPan: string;

  public readonly externalId: string;

  public readonly resultCode: string;

  public readonly authId: string;

  public readonly rrn: string;

  public readonly terminalId: string;

  public readonly creationDate: string;

  public readonly status: string;

  public readonly requestId: string;

  public readonly paymentId: string;

  public constructor(dataItem: any) {
    this.requestId = dataItem?.requestId || "";
    this.amount = dataItem?.amount || "";
    this.currency = dataItem?.currency || "";
    this.paymentSystem = dataItem?.details?.paymentSystem || "";
    this.maskedPan = dataItem?.details?.maskedPan || "";
    this.externalId = dataItem?.details?.requisitesTrxId || "";
    this.resultCode = dataItem?.details?.resultCode || "";
    this.authId = dataItem?.details?.authId || "";
    this.rrn = dataItem?.details?.rrn || "";
    this.terminalId = dataItem?.terminalId || "";
    this.creationDate = dataItem?.creationDate ? convertFetchedDateToTableShowDate(dataItem?.creationDate) : "";
    this.status = dataItem?.status || "";
    this.paymentId = dataItem?.paymentId || "";
  }
}
