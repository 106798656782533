import { OptionModel } from "model/Option.model";
import { InvoiceSelectState } from "service/shared/others/InvoiceSelectService/InvoiceSelect.state";

export class InvoiceSelectController {
  public readonly state: InvoiceSelectState;

  public constructor(state: InvoiceSelectState) {
    this.state = state;
  }

  public readonly resetField = (): void => {
    this.state.value.next(this.state.titleOption);
    this.state.required && this.state.valid.next(false);
  };

  public readonly onChange = (value: OptionModel): void => {
    this.state.isSelected.next(true);
    this.state.value.next(value);
  };
}
