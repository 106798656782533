import { supportAdministrationPageController } from "service/shared/singletones/supportAdministrationPageService/supportAdministrationPage.controller";
import { supportAdministrationPageState } from "service/shared/singletones/supportAdministrationPageService/supportAdministrationPage.state";

class Service {
  public readonly state = supportAdministrationPageState;

  public readonly controller = supportAdministrationPageController;
}

export const supportAdministrationPageService = new Service();
