import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

type InputServiceParams = {
  regexp?: RegExp;
  required?: true;
};

export class SignInInputState {
  public readonly digitsState: BehaviorSubjectBoolean;

  public readonly lengthState: BehaviorSubjectBoolean;

  public readonly lowercaseLettersState: BehaviorSubjectBoolean;

  public readonly russianLetterState: BehaviorSubjectBoolean;

  public readonly specialSymbolsState: BehaviorSubjectBoolean;

  public readonly uppercaseLettersState: BehaviorSubjectBoolean;

  public readonly capsLockState: BehaviorSubjectBoolean;

  public readonly showError: BehaviorSubjectBoolean;

  public readonly value: BehaviorSubjectString;

  public readonly valid: BehaviorSubjectBoolean;

  private readonly regexp?: RegExp;

  private readonly required?: true;

  public constructor(params?: InputServiceParams) {
    this.regexp = params?.regexp;
    this.required = params?.required;

    this.value = new BehaviorSubjectString("");
    this.capsLockState = new BehaviorSubjectBoolean(false);
    this.russianLetterState = new BehaviorSubjectBoolean(false);
    this.digitsState = new BehaviorSubjectBoolean(true);
    this.lengthState = new BehaviorSubjectBoolean(true);
    this.lowercaseLettersState = new BehaviorSubjectBoolean(true);
    this.specialSymbolsState = new BehaviorSubjectBoolean(true);
    this.uppercaseLettersState = new BehaviorSubjectBoolean(true);
    this.showError = new BehaviorSubjectBoolean(false);
    this.valid = setCombinedBehaviorSubject(this.validate, this.value);
  }

  public readonly validate = (value: string): boolean => {
    if (this.regexp) {
      return this.regexp.test(value);
    }

    if (this.required) {
      return value.trim() !== "";
    }

    return true;
  };
}
