import { OptionModel } from "model/Option.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";

export class MultipleCustomSelectState {
  public readonly isDropDownOpened: BehaviorSubjectBoolean;

  public readonly isFitContentList: boolean;

  public readonly optionList: OptionModel[];

  public readonly onChange: (value: OptionModel) => void;

  public readonly onClickAll: () => void;

  public constructor(optionList: OptionModel[], onChange: (value: OptionModel) => void, onClickAll: () => void) {
    this.onChange = onChange;
    this.onClickAll = onClickAll;
    this.optionList = optionList;
    this.isDropDownOpened = new BehaviorSubjectBoolean(false);
    this.isFitContentList = this.optionList.length < 5;
  }
}
