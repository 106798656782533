import { userService } from "api/userService";
import { routerPaths } from "appConstants/routerPaths";
import { PopUpContainer } from "components/PopUpContainer/PopUpContainer.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { menuService } from "service/shared/singletones/menuService/menu.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SMenuList } from "./ListStyled/SMenuList.styled";
import { SMenuListHeader } from "./ListStyled/SMenuListHeader.styled";
import { SMenuListLink } from "./ListStyled/SMenuListLink.styled";
import { SMenuListLogOut } from "./ListStyled/SMenuListLogOut.styled";

export const List = createComponent("List", () => {
  const { t, i18n } = useTranslation();
  const open = useDefaultObservableBoolean(menuService.state.open);
  const canGetAuditPage = userService.canGetAuditPage();
  const canTerminalsPage = userService.canTerminalsPage();
  const canGetUsersInfo = userService.canGetUsersInfo();
  const canGetH2HInfo = userService.canGetH2HInfo();
  // const canSupportAdministration = userService.canSupportAdministration();

  if (!open) {
    return null;
  }

  return (
    <PopUpContainer closeCallBack={menuService.controller.closeMenu}>
      <SMenuList isArabic={i18n.language === "ar"}>
        <SMenuListHeader>{t("mainLayout.title")}</SMenuListHeader>
        <SMenuListLink to={routerPaths.userInfoPage} onClick={menuService.controller.closeMenu}>
          {t("mainLayout.logoutTitle")}
        </SMenuListLink>
        {/* {canSupportAdministration && ( */}
        {/*  <SMenuListLink to={routerPaths.supportAdministration} onClick={menuService.closeMenu}> */}
        {/*    {t("mainLayout.supportAdministration()} */}
        {/*  </SMenuListLink> */}
        {/* )} */}
        {canTerminalsPage && (
          <SMenuListLink to={routerPaths.terminals} onClick={menuService.controller.closeMenu}>
            {t("mainLayout.terminalsTitle")}
          </SMenuListLink>
        )}
        {canGetAuditPage && (
          <SMenuListLink to={routerPaths.auditPage} onClick={menuService.controller.closeMenu}>
            {t("mainLayout.auditTitle")}
          </SMenuListLink>
        )}
        {canGetUsersInfo && (
          <SMenuListLink to={routerPaths.usersInfoPage} onClick={menuService.controller.closeMenu}>
            {t("mainLayout.userTitle")}
          </SMenuListLink>
        )}
        <SMenuListLogOut onClick={menuService.controller.logout}>{t("mainLayout.profileTitle")}</SMenuListLogOut>
      </SMenuList>
    </PopUpContainer>
  );
});
