import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { routerPaths } from "appConstants/routerPaths";
import { t } from "i18n";
import { CreateInvoiceDtoModel } from "model/CreateInvoiceDto.model";
import { OptionModel } from "model/Option.model";
import { redirectService } from "service/common/redirectService/redirect.service";
import { InvoiceSelectService } from "service/shared/others/InvoiceSelectService/InvoiceSelect.service";
import { initialFields } from "service/shared/singletones/invoiceCreateFormService/initialFields";
import { invoiceCreateFormState } from "service/shared/singletones/invoiceCreateFormService/invoiceCreateForm.state";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { CreateFieldService } from "types/business/CreateFieldService";
import { CreateInvoiceDto } from "types/business/CreateInvoiceDto";
import { Dictionary } from "types/commonExtend/Dictionary";
import { replacePath } from "utils/commonExtend/replacePath";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class Controller {
  public readonly state = invoiceCreateFormState;

  public terminalList: OptionModel[] = [];

  public readonly mounted = async (): Promise<void> => {
    this.state.mount.next(true);

    const terminalList = await this.getTerminalList();
    this.terminalList = terminalList;

    this.state.terminalField = new InvoiceSelectService(
      0,
      t('invoiceCreateFormService.terminalTitle'),
      t('invoiceCreateFormService.terminalErrorMessage'),
      'terminal',
      'invoiceCreateFormService.terminalTitle',
      'invoiceCreateFormService.terminalErrorMessage',
      terminalList,
      true
    );

    this.state.terminalOption = this.state.terminalField.state.value;

    this.state.fields = setCombinedBehaviorSubject(this.setFields, this.state.terminalOption);

    this.state.loaded.next(true);
  };

  public readonly unMounted = (): void => {
    this.state.open.next(false);
    this.state.loaded.next(false);
    this.state.mount.next(false);
    this.state.fields.value.forEach((field) => {
      field.state;
      field.controller.resetField();
    });
    this.state.terminalField.controller.resetField();
  };

  public readonly close = (): void => {
    this.state.open.next(false);
  };

  public readonly onClickOpen = (): void => {
    this.state.open.next(true);
  };

  public readonly onClickCancel = (): void => {
    this.state.open.next(false);
  };

  public readonly onClickCreate = async (): Promise<void> => {
    const formIsValid = this.checkDataValid();

    if (formIsValid) {
      const data: CreateInvoiceDtoModel = this.getData();
      const dto = data.getQuery();
      await this.createInvoice(dto);
    }
  };

  private readonly setTerminalOptions = (currencies: string[]): OptionModel[] => {
    return currencies.map((currency) => {
      return new OptionModel(currency, currency);
    });
  };

  private readonly checkDataValid = (): boolean => {
    const fields = this.state.fields.value;

    const validData = [];

    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];
      if (!field.state.valid.value) {
        field.state.showError.next(true);
      }
      validData.push(field.state.valid.value);
    }

    return validData.every((el) => el);
  };

  private readonly getData = (): CreateInvoiceDtoModel => {
    const data: Dictionary<string | OptionModel> = this.state.fields.value.reduce(
      (acc, field) =>
        Object.assign(acc, {
          [field.state.name]: field.state.value.value,
        }),
      {}
    );

    return new CreateInvoiceDtoModel(
      +data.amount,
      +data.phone,
      data.paymentWay as OptionModel,
      data.description as string,
      data.terminal as OptionModel,
      data.title as string,
      data.currency as OptionModel,
      data.email as string
    );
  };

  private readonly getTerminalList = async (): Promise<OptionModel[]> => {
    const { data } = await client.post(apiPaths.terminalList);

    return data.map((element: any) => {
      const currencies = element.currencies || [];
      const merchantName = element.merchantName || "";
      const terminalId = element.terminalId || "";
      return new OptionModel(`${terminalId} - ${merchantName}`, `${terminalId}`, { data: currencies });
    });
  };

  private readonly setFields = (terminalOption: OptionModel): CreateFieldService[] => {
    const uniqCurrencies: string[] = [];
    if (this.terminalList.length) {
      this.terminalList.forEach((currenciesModel) => {
        if (currenciesModel.data.length) {
          currenciesModel.data.forEach((currenciesItem) => {
            if (!uniqCurrencies.includes(currenciesItem)) {
              uniqCurrencies.push(currenciesItem);
            }
          });
        }
      });
    }
    return [
      this.state.terminalField,
      ...initialFields,
      new InvoiceSelectService(
        7,
        t("invoiceCreateFormService.currencyTitle"),
        t("invoiceCreateFormService.currencyErrorMessage"),
        "currency",
        "invoiceCreateFormService.currencyTitle",
        "invoiceCreateFormService.currencyErrorMessage",
        this.setTerminalOptions(uniqCurrencies.length ? uniqCurrencies : terminalOption.data),
        true
      ),
    ];
  };

  private readonly createInvoice = async (dto: CreateInvoiceDto): Promise<void> => {
    try {
      this.state.loaded.next(false);
      const { data } = await client.post(apiPaths.invoiceCreate, dto);
      const invoiceId = data.orderId;
      toastContainerService.controller.createSuccessToast(t("invoiceCreateFormService.createInvoiceMessage"));
      redirectService.controller.setCurrentRedirectPage(replacePath(routerPaths.invoiceDetails, { invoiceId }));
      this.state.open.next(false);
      this.state.loaded.next(true);
    } catch (error: any) {
      const { message } = error.response.data;
      toastContainerService.controller.createErrorToast(message);
      this.state.loaded.next(true);
    }
  };
}

export const invoiceCreateFormController = new Controller();
