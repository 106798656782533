import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { FilterInputProps } from "./FilterInput.props";
import { SFilterInput } from "./FilterInputStyled/SFilterInput.styled";
import { SFilterInputClear } from "./FilterInputStyled/SFilterInputClear.styled";
import { SFilterInputContainer } from "./FilterInputStyled/SFilterInputContainer.styled";
import { SFilterInputDelete } from "./FilterInputStyled/SFilterInputDelete.styled";
import { SFilterInputInput } from "./FilterInputStyled/SFilterInputInput.styled";
import { SFilterInputInputContainer } from "./FilterInputStyled/SFilterInputInputContainer.styled";
import { SFilterInputTitle } from "./FilterInputStyled/SFilterInputTitle.styled";

export const FilterInput = createComponent<FilterInputProps>("FilterInput", (props) => {
  const { service } = props;
  const { service: filterService } = useFiltersContext();

  const { t } = useTranslation();
  const value = useDefaultObservableString(service.value);
  const isArabic = useDefaultObservableBoolean(filterService.state.isArabic);

  return (
    <SFilterInput>
      <SFilterInputTitle isArabic={isArabic}>{t(service.filterModel.langPath)}</SFilterInputTitle>

      <SFilterInputContainer isArabic={isArabic}>
        <SFilterInputInputContainer>
          <SFilterInputInput isArabic={isArabic} type={service.filterModel.isNumber ? "number" : "text"} value={value} onChange={service.onChange} />

          <SFilterInputClear isArabic={isArabic} onClick={service.onClickClear}>
            <SvgImage width={20} height={20} name="Close" />
          </SFilterInputClear>
        </SFilterInputInputContainer>

        <SFilterInputDelete isArabic={isArabic} onClick={service.onClickDelete}>
          <SvgImage width={20} height={20} name="DeleteFilter" />
        </SFilterInputDelete>
      </SFilterInputContainer>
    </SFilterInput>
  );
});
