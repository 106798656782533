import { styled } from "theme/default/styled";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";
import { setColor_rgba_0_0_0_87 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_87";

export const SUsersChangeRowCommonTitle = styled.div`
  width: 200px;

  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_rgba_0_0_0_87};
`;
