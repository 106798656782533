/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const Asc = createIcon("Asc", (props) => {
  const { width = 25, height = 16, color = "#25476d" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 25 16" fill="none">
      <path d="M17.834 6.26025L21.0968 6.26025" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
      <line x1="18.684" y1="1.754" x2="18.011" y2="1.754" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
      <line x1="18.0141" y1="10.5919" x2="23.5697" y2="10.5919" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
    </svg>
  );
});
