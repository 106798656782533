import { redirectController } from "service/common/redirectService/redirect.controller";
import { redirectState } from "service/common/redirectService/redirect.state";

class Service {
  public readonly state = redirectState;

  public readonly controller = redirectController;
}

export const redirectService = new Service();
