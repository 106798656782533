import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { activateInvitePageService } from "service/shared/singletones/activateInvitePageService/activateInvitePage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SActivateButton } from "./ActivateButtonStyled/SActivateButton.styled";
import { SActivateButtonLoader } from "./ActivateButtonStyled/SActivateButtonLoader.styled";

export const ActivateButton = createComponent("ActivateButton", () => {
  const { t } = useTranslation();
  const isLoaded = useDefaultObservableBoolean(activateInvitePageService.state.isLoaded);

  if (isLoaded) {
    return (
      <SActivateButtonLoader>
        <Loader small />
      </SActivateButtonLoader>
    );
  }

  return <SActivateButton onClick={activateInvitePageService.controller.sendForm}>{t("activateInvitePage.activate")}</SActivateButton>;
});
