import { css, setFlexDFAIC, setProps, styled } from "theme";

interface SFetchedFilterMultipleSelectContainerProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFetchedFilterMultipleSelectContainerProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SFetchedFilterMultipleSelectContainer = styled.div<SFetchedFilterMultipleSelectContainerProps>`
  ${setFlexDFAIC};
  ${isArabicLang};
`;
