import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";

export const operationStatusFilterModel = new FilterModel({
  id: 16,
  type: "select",
  title: t("operationStatusFilterModel.title"),
  name: "STATUS",
  options: {
    optionList: [
      new OptionModel("operationStatusFilterModel.AUTHENTICATED", "AUTHENTICATED"),
      new OptionModel("operationStatusFilterModel.AUTHENTICATION_FAILED", "AUTHENTICATION_FAILED"),
      new OptionModel("operationStatusFilterModel.AUTHENTICATION_REQUIRED", "AUTHENTICATION_REQUIRED"),
      new OptionModel("operationStatusFilterModel.AUTHENTICATION_STARTED", "AUTHENTICATION_STARTED"),
      new OptionModel("operationStatusFilterModel.CREATED", "CREATED"),
      new OptionModel("operationStatusFilterModel.ERROR", "ERROR"),
      new OptionModel("operationStatusFilterModel.EXPIRED", "EXPIRED"),
      new OptionModel("operationStatusFilterModel.FORM_SHOWED", "FORM_SHOWED"),
      new OptionModel("operationStatusFilterModel.PROCESSING", "PROCESSING"),
      new OptionModel("operationStatusFilterModel.THREE_DS_METHOD_CALL_REQUIRED", "THREE_DS_METHOD_CALL_REQUIRED"),
      new OptionModel("operationStatusFilterModel.FAILED", "FAILED"),
      new OptionModel("operationStatusFilterModel.SUCCESS", "SUCCESS"),
    ],
  },
  langPath: "operationStatusFilterModel.title",
});
