import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { dynamicPageState } from "service/shared/singletones/dynamicPageService/dynamicPage.state";
import { StatisticBody } from "types/business/StatisticBody";
import { StatisticDataItem } from "types/business/StatisticDataItem";
import { monthToNumberObject } from "utils/business/monthToNumberObject";

class Controller {
  public readonly state = dynamicPageState;

  public readonly getNextMonth = (month: string): string => {
    const nextMonthList = {
      "01": "02",
      "02": "03",
      "03": "04",
      "04": "05",
      "05": "06",
      "06": "07",
      "07": "08",
      "08": "09",
      "09": "10",
      "10": "11",
      "11": "12",
      "12": "01",
    };

    return nextMonthList[month as keyof typeof nextMonthList];
  };

  public readonly onClick = async (): Promise<void> => {
    this.state.fetched.next(false);

    const formValidArray = this.getFormValidArray();

    if (formValidArray) {
      const firstGroupTerminal = this.state.firstGroupCompareTerminalService.state.value.value.map((element) => element.value);
      const secondGroupTerminal = this.state.secondGroupCompareTerminalService.state.value.value.map((element) => element.value);

      const firstGroupYear = this.state.firstGroupCompareYearService.state.value.value.value;
      const firstGroupMonth = this.state.firstGroupCompareMonthService.state.value.value;
      const convertedFirstGroupMonth = this.convertMonthToNumber(firstGroupMonth.value);
      const firstBody = this.convertBody(firstGroupTerminal, firstGroupYear, convertedFirstGroupMonth);
      const firstStatistics = await this.fetchStatistics(firstBody);

      const secondGroupYear = this.state.secondGroupCompareYearService.state.value.value.value;
      const secondGroupMonth = this.state.secondGroupCompareMonthService.state.value.value;
      const convertedSecondGroupMonth = this.convertMonthToNumber(secondGroupMonth.value);
      const secondBody = this.convertBody(secondGroupTerminal, secondGroupYear, convertedSecondGroupMonth);
      const secondStatistics = await this.fetchStatistics(secondBody);

      this.state.firstFetchedGroupTerminal.next(firstGroupTerminal);
      this.state.secondFetchedGroupTerminal.next(secondGroupTerminal);

      this.state.firstStatistics.next(firstStatistics);
      this.state.secondStatistics.next(secondStatistics);
      this.state.fetched.next(true);
      this.state.loaded.next(true);
      this.state.showError.next(false);
    } else {
      this.state.firstGroupCompareTerminalService.state.showError.next(true);
      this.state.firstGroupCompareYearService.state.showError.next(true);
      this.state.firstGroupCompareMonthService.state.showError.next(true);
      this.state.secondGroupCompareTerminalService.state.showError.next(true);
      this.state.secondGroupCompareYearService.state.showError.next(true);
      this.state.secondGroupCompareMonthService.state.showError.next(true);
      this.state.showError.next(true);
    }
  };

  private readonly convertMonthToNumber = (month: string): string => {
    const currentObjectMonths = monthToNumberObject;
    const typedMonth = month as keyof typeof currentObjectMonths;
    return monthToNumberObject[typedMonth];
  };

  private readonly convertBody = (terminalIds: string[], year: string, month: string): StatisticBody => {
    return {
      statisticType: this.state.statisticType,
      statisticStep: this.state.statisticStep,
      startDate: `${year}-${month}-01T00:00:00Z`,
      endDate: `${year}-${this.getNextMonth(month)}-01T00:00:00Z`,
      terminalIds,
    };
  };

  private readonly getFormValidArray = (): boolean => {
    const firstGroupTerminalValid = this.state.firstGroupCompareTerminalService.state.valid.value;
    const firstGroupYearValid = this.state.firstGroupCompareYearService.state.valid.value;
    const firstGroupMonthValid = this.state.firstGroupCompareMonthService.state.valid.value;

    const secondGroupTerminalValid = this.state.secondGroupCompareTerminalService.state.valid.value;
    const secondGroupYearValid = this.state.secondGroupCompareYearService.state.valid.value;
    const secondGroupMonthValid = this.state.secondGroupCompareMonthService.state.valid.value;

    const formValidArray = [
      firstGroupTerminalValid,
      firstGroupYearValid,
      firstGroupMonthValid,
      secondGroupTerminalValid,
      secondGroupYearValid,
      secondGroupMonthValid,
    ];

    return formValidArray.every((valid) => valid);
  };

  private readonly fetchStatistics = async (body: StatisticBody): Promise<StatisticDataItem[]> => {
    const { data } = await client.post(apiPaths.statistics, body);

    return data.statistics;
  };
}

export const dynamicPageController = new Controller();
