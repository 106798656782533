import { css, setFlexDF, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  margin-left: 28px;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  margin-right: 28px;
`);

export const SControls = styled.div<Props>`
  ${setFlexDF};
  ${isNotArabicLang};
  ${isArabicLang};
`;
