import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const refundPaymentFilterModel = new FilterModel({
  id: 10,
  type: "text",
  title: t("refundPaymentFilterModel.title"),
  name: "PAYMENT",
  options: {},
  langPath: "refundPaymentFilterModel.title",
});
