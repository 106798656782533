import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { navigationMenuService } from "service/shared/singletones/navigationMenuService/navigationMenu.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { NavigationMenuContent } from "./NavigationMenuContent/NavigationMenuContent.component";

export const NavigationMenu = createComponent("NavigationMenu", () => {
  useEffect(() => {
    navigationMenuService.controller.onMount();

    return (): void => {
      navigationMenuService.controller.onUnMount();
    };
  }, []);

  const loaded = useDefaultObservableBoolean(navigationMenuService.state.loaded);

  if (!loaded) {
    return <Loader />;
  }

  return <NavigationMenuContent />;
});
