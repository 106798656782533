import { createComponent } from "utils/libExtend/createComponent";

import { FormOpenButton } from "./FormOpenButton/FormOpenButton.component";
import { InvoiceCreateForm } from "./InvoiceCreateForm/InvoiceCreateForm.component";
import { SInvoicesHeader } from "./InvoicesHeaderStyled/SInvoicesHeader.styled";
import { SInvoicesHeaderFilter } from "./InvoicesHeaderStyled/SInvoicesHeaderFilter.styled";
import { InvoicesPageFilters } from "./InvoicesPageFilters/InvoicesPageFilters.component";

export const InvoicesHeader = createComponent("InvoicesHeader", () => {
  return (
    <SInvoicesHeader>
      <SInvoicesHeaderFilter>
        <InvoicesPageFilters />
      </SInvoicesHeaderFilter>
      <FormOpenButton />
      <InvoiceCreateForm />
    </SInvoicesHeader>
  );
});
