import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { ChangePasswordButton } from "./ChangePasswordButton/ChangePasswordButton.component";
import { SChangePasswordForm } from "./ChangePasswordFormStyled/SChangePasswordForm.styled";
import { SChangePasswordFormTitle } from "./ChangePasswordFormStyled/SChangePasswordFormTitle.styled";
import { ChangePasswordInput } from "./UsersChangePasswordInput/UsersChangePasswordInput.component";

export const ChangePasswordForm = createComponent("ChangePasswordForm", () => {
  const { t } = useTranslation();
  return (
    <SChangePasswordForm>
      <SChangePasswordFormTitle>{t("usersDetailsPage.changePasswordFormTitle")}</SChangePasswordFormTitle>
      <ChangePasswordInput service={usersDetailsPageService.state.currentPasswordService} />
      <ChangePasswordInput service={usersDetailsPageService.state.newPasswordService} />
      <ChangePasswordButton />
    </SChangePasswordForm>
  );
});
