import { styled } from "theme/default/styled";
import { setFlexF1 } from "theme/setter/setFlexValues/setFlexF1";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";

export const STerminalsListTableBodyTitle = styled.div`
  margin: 10px 0;

  ${setFlexF1};
  ${setFontSize_14px};
  ${setFontWeight400};
`;
