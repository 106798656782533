import { SupportQuestionModel } from "model/SupportQuestion.model";
import { useDefaultObservableSupportQuestionModelArray } from "observables/SupportQuestionModelArrayObservable";
import { useTranslation } from "react-i18next";
import { supportPageService } from "service/shared/singletones/supportPageService/supportPage.service";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { SupportPageQuestion } from "./SupportPageQuestion/SupportPageQuestion.component";
import { SSupportPageQuestionList } from "./SupportPageQuestionListStyled/SSupportPageQuestionList.styled";
import { SSupportPageQuestionListList } from "./SupportPageQuestionListStyled/SSupportPageQuestionListList.styled";
import { SSupportPageQuestionListTitle } from "./SupportPageQuestionListStyled/SSupportPageQuestionListTitle.styled";

export const SupportPageQuestionList = createComponent("SupportPageQuestionList", () => {
  const { t, i18n } = useTranslation();
  const questionList = useDefaultObservableSupportQuestionModelArray(supportPageService.state.questionList);
  const isArabic = i18n.language === "ar";

  const renderSupportQuestion: RenderFunctionType<SupportQuestionModel> = (questionModel, index) => {
    return <SupportPageQuestion questionModel={questionModel} key={index} />;
  };

  return (
    <SSupportPageQuestionList isArabic={isArabic}>
      <SSupportPageQuestionListTitle isArabic={isArabic}>{t("supportPage.listTitle")}</SSupportPageQuestionListTitle>

      <SSupportPageQuestionListList isArabic={isArabic}>{questionList.map(renderSupportQuestion)}</SSupportPageQuestionListList>
    </SSupportPageQuestionList>
  );
});
