import { useTranslation } from "react-i18next";
import { createArticleFormService } from "service/shared/singletones/createArticleFormService/createArticleForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { CreateSupportArticleForm } from "./CreateSupportArticleForm/CreateSupportArticleForm.component";
import { SOpenCreateSupportButton } from "./OpenCreateSupportButtonStyled/SOpenCreateSupportButton.styled";

export const OpenCreateSupportButton = createComponent("OpenCreateSupportButton", () => {
  const { t } = useTranslation();
  return (
    <>
      <SOpenCreateSupportButton onClick={createArticleFormService.controller.onClickOpenFormButton}>
        {t("supportAdministrationPage.openCreateSupportButtonTitle")}
      </SOpenCreateSupportButton>
      <CreateSupportArticleForm />
    </>
  );
});
