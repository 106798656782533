import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { OptionModel } from "model/Option.model";
import { UsersChangeRowGroupState } from "service/shared/others/UsersChangeRowGroupService/UsersChangeRowGroup.state";

export class UsersChangeRowGroupController {
  public readonly state: UsersChangeRowGroupState;

  public constructor(state: UsersChangeRowGroupState) {
    this.state = state;
  }

  public readonly produceData = (data: any[]): OptionModel[] => {
    return data.map((element: any) => {
      return new OptionModel(`${element.description}`, `${element.id}`);
    });
  };

  public readonly onMount = async (): Promise<void> => {
    const { data } = await client.post(apiPaths.adminGroupSubs);
    const producedData = this.produceData(data.groups);
    this.state.optionList.next(producedData);
  };

  public readonly onClickEdit = (): void => {
    if (this.state.active.value) {
      if (!this.state.valueIsEqual.value) {
        this.state.initialValue.next(this.state.value.value);
        this.state.updateUserCallBack();
      }
    }

    this.state.active.next(!this.state.active.value);
  };

  public readonly onChange = (value: OptionModel): void => {
    this.state.value.next(value);
  };
}
