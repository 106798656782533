/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const Desc = createIcon("Desc", (props) => {
  const { width = 25, height = 16, color = "#25476d" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 25 16" fill="none">
      <path d="M18.0117 5.76562L21.2745 5.76562" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
      <line x1="0.85" y1="-0.85" x2="0.17699" y2="-0.85" transform="matrix(1 0 0 -1 18.0117 9.42188)" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
      <line x1="0.85" y1="-0.85" x2="6.40564" y2="-0.85" transform="matrix(1 0 0 -1 17.3418 0.583984)" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
    </svg>
  );
});
