import { userService } from "api/userService";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { invoiceCreateFormService } from "service/shared/singletones/invoiceCreateFormService/invoiceCreateForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SFormOpenButton } from "./FormOpenButtonStyled/SFormOpenButton.styled";

export const FormOpenButton = createComponent("FormOpenButton", () => {
  const { t, i18n } = useTranslation();
  const canCreateInvoice = userService.canCreateInvoice();
  const isArabic = i18n.language === "ar";

  if (!canCreateInvoice) {
    return null;
  }

  return (
    <SFormOpenButton isArabic={isArabic} onClick={invoiceCreateFormService.controller.onClickOpen}>
      <SvgImage name="Plus" />
      {t("formOpenButton.title")}
    </SFormOpenButton>
  );
});
