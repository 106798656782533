import { Filters } from "components/Filters/Filters.component";
import { refundsPageService } from "service/shared/singletones/refundsPageService/refundsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

export const RefundsPageFilters = createComponent("RefundsPageFilters", () => {
  return (
    <Filters
      totalElements={refundsPageService.state.filterNavigationService.state.totalElements}
      service={refundsPageService.filterService}
      csvCallback={refundsPageService.getTotalData}
      csvName={refundsPageService.csvName}
    />
  );
});
