import { useTranslation } from "react-i18next";
import { activateInvitePageService } from "service/shared/singletones/activateInvitePageService/activateInvitePage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SInviteRepeatedPasswordInput } from "./InviteRepeatedPasswordInputStyled/SInviteRepeatedPasswordInput.styled";

export const InviteRepeatedPasswordInput = createComponent("InviteRepeatedPasswordInput", () => {
  const { t } = useTranslation();
  return (
    <SInviteRepeatedPasswordInput
      type="password"
      name="confirmPassword"
      placeholder={t("activateInvitePage.repeatedPassword")}
      service={activateInvitePageService.state.repeatedPasswordService}
    />
  );
});
