import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { transfersPageService } from "service/shared/singletones/transfersPageService/transfersPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { STransfersTable } from "./TransfersTableStyled/STransfersTable.styled";
import { STransfersTableContainer } from "./TransfersTableStyled/STransfersTableContainer.styled";

export const TransfersTable = createComponent("TransfersTable", () => {
  const loaded = useDefaultObservableBoolean(transfersPageService.state.loaded);
  const tableService = useDefaultObservableTableService(transfersPageService.tableService);

  return (
    <STransfersTable>
      <STransfersTableContainer>
        <Table loaded={loaded} service={tableService} />
      </STransfersTableContainer>
    </STransfersTable>
  );
});
