import { useRolesFormContext } from "context/RolesForm.context";
import { RolesSelectOptionModel } from "model/RolesSelectOption.model";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";

import { SRolesSelectFormList } from "./RolesSelectFormListStyled/SRolesSelectFormList.styled";
import { RolesSelectOption } from "./RolesSelectOption/RolesSelectOption.component";

export const RolesSelectFormList = createComponent("RolesSelectFormList", () => {
  const { service } = useRolesFormContext();

  const rolesSelectOptionArray = useDefaultObservable<RolesSelectOptionModel[]>(service.state.rolesSelectOptionArray);

  const renderRolesSelectOption: RenderFunctionType<RolesSelectOptionModel> = (rolesSelectOptionModel, index) => {
    const isFirst = index === 0;
    return <RolesSelectOption isFirst={isFirst} key={index} rolesSelectOptionModel={rolesSelectOptionModel} />;
  };

  return <SRolesSelectFormList>{rolesSelectOptionArray.map(renderRolesSelectOption)}</SRolesSelectFormList>;
});
