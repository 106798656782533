export const colorsOpacityValues = {
  color_rgba_196_237_255_40: "rgba(196, 237, 255, 0.4)",
  color_rgba_0_0_0_87: "rgba(0, 0, 0, 0.87)",
  color_rgba_246_181_122_40: "rgba(246, 181, 122, 0.4)",
  color_rgba_0_0_0_50: "rgba(0, 0, 0, 0.5)",
  color_rgba_0_0_0_4: "rgba(0, 0, 0, 0.4)",
  color_rgba_0_0_0_95: "rgba(0, 0, 0, 0.95)",
  color_rgba_0_0_0_05: "rgba(0, 0, 0, 0.05)",
  color_rgba_0_131_245_67: "rgba(0, 131, 245, 0.67)",
  color_rgba_183_200_220_80: "rgba(183, 200, 220, 0.80)",
  color_rgba_0_0_0_07: "rgba(0, 0, 0, 0.07)",
  color_rgba_34_36_38_15: "rgba(34, 36, 38, 0.15)",
  setColor_rgba_0_0_0_08: "rgba(0, 0, 0, 0.08)",
  setColor_rgba_0_0_0_10: "rgba(0, 0, 0, 0.10)",
};
