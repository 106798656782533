import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const rrnFilterModel = new FilterModel({
  id: 7,
  type: "text",
  title: t("rrnFilterModel.title"),
  name: "RRN",
  options: {},
  langPath: "rrnFilterModel.title",
});
