import { OptionModel } from "model/Option.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectOptionModelArray } from "observables/OptionModelArrayObservable";
import { BehaviorSubjectOptionModel } from "observables/OptionModelObservable";
import { initialOptionList } from "service/shared/others/CreateUserFetchedSelectService/initialOptionList";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

export class CreateUserFetchedSelectState {
  public readonly title: string;

  public readonly message: string;

  public readonly optionList: BehaviorSubjectOptionModelArray;

  public readonly value: BehaviorSubjectOptionModel;

  public readonly showError: BehaviorSubjectBoolean;

  public readonly valid: BehaviorSubjectBoolean;

  public readonly fetchDataFunction: () => Promise<any[]>;

  public constructor(fetchDataFunction: () => Promise<any[]>, title: string, message: string) {
    this.fetchDataFunction = fetchDataFunction;
    this.title = title;
    this.message = message;
    this.optionList = new BehaviorSubjectOptionModelArray(initialOptionList);
    this.value = new BehaviorSubjectOptionModel(initialOptionList[0]);
    this.showError = new BehaviorSubjectBoolean(false);
    this.valid = setCombinedBehaviorSubject(this.setValid, this.value);
  }

  private readonly setValid = (value: OptionModel): boolean => {
    return Boolean(value.value) && value.value.trim() !== "";
  };
}
