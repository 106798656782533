import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { InputProps } from "./Input.props";
import { SResetPasswordFormInput } from "./InputStyled/SResetPasswordFormInput.styled";

export const Input = createComponent<InputProps>("Input", (props) => {
  const { service, placeholder, className = "", name, type = "text" } = props;

  const value = useDefaultObservableString(service.state.value);
  const showError = useDefaultObservableBoolean(service.state.showError);

  return (
    <SResetPasswordFormInput
      className={className}
      showError={showError}
      type={type}
      value={value}
      name={name}
      onChange={service.controller.onChange}
      placeholder={placeholder}
    />
  );
});
