import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { createArticleFormService } from "service/shared/singletones/createArticleFormService/createArticleForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SCreateArticleEmailInput } from "./CreateArticleEmailInputStyled/SCreateArticleEmailInput.styled";

export const CreateArticleEmailInput = createComponent("CreateArticleEmailInput", () => {
  const { t } = useTranslation();
  const emailValue = useDefaultObservableString(createArticleFormService.state.emailValue);
  const placeholder = t("supportAdministrationPage.emailInputPlaceholder") || "";

  return <SCreateArticleEmailInput placeholder={placeholder} value={emailValue} onChange={createArticleFormService.controller.onEmailValueChange} />;
});
