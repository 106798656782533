import { styled } from "theme/default/styled";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexF1 } from "theme/setter/setFlexValues/setFlexF1";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";

export const STransferDetailsPageLoader = styled.div`
  margin-top: 100px;

  ${setFlexDFAIC};
  ${setFlexF1};
  ${setFlexJCC};
`;
