import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { userInfoPageService } from "service/shared/singletones/userInfoPageService/userInfoPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SChangePasswordSectionRussianLetterErrorForm } from "./ChangePasswordSectionRussianLetterErrorFormStyled/SChangePasswordSectionRussianLetterErrorForm.styled";
import { SChangePasswordSectionRussianLetterErrorFormTitle } from "./ChangePasswordSectionRussianLetterErrorFormStyled/SChangePasswordSectionRussianLetterErrorFormTitle.styled";

export const ChangePasswordSectionRussianLetterErrorForm = createComponent("ChangePasswordSectionRussianLetterErrorForm", () => {
  const { t } = useTranslation();
  const showRussianLetterError = useDefaultObservableBoolean(userInfoPageService.state.showRussianLetterError);

  const hideForm = !showRussianLetterError;

  if (hideForm) {
    return null;
  }

  return (
    <SChangePasswordSectionRussianLetterErrorForm>
      <SChangePasswordSectionRussianLetterErrorFormTitle>{t("changePasswordSection.russianLetterError")}</SChangePasswordSectionRussianLetterErrorFormTitle>
    </SChangePasswordSectionRussianLetterErrorForm>
  );
});
