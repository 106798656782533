import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { RenderFunctionType } from "../../../types/commonExtend/RenderFunctionType";
import { InformationPopUpProps } from "./InformationPopUp.props";
import { SContainer } from "./InformationPopUpStyled/SContainer.styled";
import { SContent } from "./InformationPopUpStyled/SContent.styled";
import { SDataContainer } from "./InformationPopUpStyled/SDataContainer.styled";
import { SDataKey } from "./InformationPopUpStyled/SDataKey.styled";
import { SDataValue } from "./InformationPopUpStyled/SDataValue.styled";

export const InformationPopUp = createComponent<InformationPopUpProps>("InformationPopUp", (props) => {
  const { t } = useTranslation();
  const { cellData, closeCallBack } = props;

  const dataItemArray = [
    { key: t("h2hPageConfig.terminalTzOperationTime"), value: cellData.terminalTzOperationTime },
    { key: t("h2hPageConfig.processingTzOperationTime"), value: cellData.processingTzOperationTime },
    { key: t("h2hPageConfig.merchantId"), value: cellData.merchantId },
    { key: t("h2hPageConfig.terminalId"), value: cellData.terminalId },
    { key: t("h2hPageConfig.amount"), value: cellData.amount },
    { key: t("h2hPageConfig.refundsAmount"), value: cellData.refundsAmount },
    { key: t("h2hPageConfig.messageCode"), value: cellData.messageCode },
    { key: t("h2hPageConfig.responseCode"), value: cellData.responseCode },
    { key: t("h2hPageConfig.maskedPan"), value: cellData.maskedPan },
    { key: t("h2hPageConfig.rrn"), value: cellData.rrn },
    { key: t("h2hPageConfig.transactionType"), value: cellData.transactionType },
    { key: t("h2hPageConfig.authId"), value: cellData.authId },
    { key: t("h2hPageConfig.pointOfServiceEntryMode"), value: cellData.pointOfServiceEntryMode },
    { key: t("h2hPageConfig.eci"), value: cellData.eci },
    { key: t("h2hPageConfig.mcc"), value: cellData.mcc },
    { key: t("h2hPageConfig.terminalName"), value: cellData.terminalName },
    { key: t("h2hPageConfig.terminalCity"), value: cellData.terminalCity },
    { key: t("h2hPageConfig.terminalRegion"), value: cellData.terminalRegion },
    { key: t("h2hPageConfig.terminalCountry"), value: cellData.terminalCountry },
    { key: t("h2hPageConfig.currency"), value: cellData.currency },
    { key: t("h2hPageConfig.acquireId"), value: cellData.acquireId },
    { key: t("h2hPageConfig.paymentSystem"), value: cellData.paymentSystem },
  ];

  const renderDataItem: RenderFunctionType<{ key: string; value: string }> = ({ key, value }) => {
    return (
      <SDataContainer>
        <SDataKey>{key}</SDataKey>
        <SDataValue>{value}</SDataValue>
      </SDataContainer>
    );
  };

  return (
    <SContainer closeCallBack={closeCallBack}>
      <SContent>{dataItemArray.map(renderDataItem)}</SContent>
    </SContainer>
  );
});
