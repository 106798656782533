import { css, setFlexDFAIC, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SSupportPageQuestionHeader = styled.button<Props>`
  gap: 8px;

  ${setFlexDFAIC};
  ${isArabicLang};
`;
