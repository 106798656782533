import { styled } from "theme/default/styled";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";

export const SMenuListLogOut = styled.button`
  height: 50px;

  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBackgroundColor_ffffff};
`;
