import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { dynamicPageService } from "service/shared/singletones/dynamicPageService/dynamicPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { ApplyCompareButton } from "./ApplyCompareButton/ApplyCompareButton.component";
import { CompareGroup } from "./CompareGroup/CompareGroup.component";
import { SComparisonSection } from "./ComparisonSectionStyled/SComparisonSection.styled";
import { SComparisonSectionContainer } from "./ComparisonSectionStyled/SComparisonSectionContainer.styled";
import { SComparisonSectionContent } from "./ComparisonSectionStyled/SComparisonSectionContent.styled";
import { SComparisonSectionGroups } from "./ComparisonSectionStyled/SComparisonSectionGroups.styled";
import { SComparisonSectionMessage } from "./ComparisonSectionStyled/SComparisonSectionMessage.styled";
import { SComparisonSectionTitle } from "./ComparisonSectionStyled/SComparisonSectionTitle.styled";

export const ComparisonSection = createComponent("ComparisonSection", () => {
  const { t, i18n } = useTranslation();
  const showError = useDefaultObservableBoolean(dynamicPageService.state.showError);
  const isArabic = i18n.language === "ar";

  return (
    <SComparisonSection>
      <SComparisonSectionContainer isArabic={isArabic}>
        <SComparisonSectionContent>
          <SComparisonSectionTitle isArabic={isArabic}>{t("dynamicPage.compareParamTitle")}</SComparisonSectionTitle>
          <SComparisonSectionGroups isArabic={isArabic}>
            <CompareGroup
              compareTerminalService={dynamicPageService.state.firstGroupCompareTerminalService}
              compareYearService={dynamicPageService.state.firstGroupCompareYearService}
              compareMonthService={dynamicPageService.state.firstGroupCompareMonthService}
              title={t("dynamicPage.firstGroupTitle")}
            />
            <CompareGroup
              compareTerminalService={dynamicPageService.state.secondGroupCompareTerminalService}
              compareYearService={dynamicPageService.state.secondGroupCompareYearService}
              compareMonthService={dynamicPageService.state.secondGroupCompareMonthService}
              title={t("dynamicPage.secondGroupTitle")}
            />
          </SComparisonSectionGroups>
        </SComparisonSectionContent>

        <ApplyCompareButton />
      </SComparisonSectionContainer>

      {showError && <SComparisonSectionMessage isArabic={isArabic}>{t("dynamicPage.notEnoughDataErrorMessageTitle")}</SComparisonSectionMessage>}
    </SComparisonSection>
  );
});
