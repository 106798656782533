import { t } from "i18n";

export const getErrorMessage = (merchantError: boolean): string => {
  const errorMessage = t("h2hPage.errorMessage");

  const errorMessageArray = [];

  if (merchantError) errorMessageArray.push(t("h2hPage.errorMessageMerchant"));

  return `${errorMessage}${errorMessageArray.join(", ")}`;
};
