import { client } from "api/client";
import { userService } from "api/userService";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18n";
import { OptionModel } from "model/Option.model";
import { RolesSelectOptionModel } from "model/RolesSelectOption.model";
import { UserModel } from "model/User.model";
import { UsersChangeRowGroupService } from "service/shared/others/UsersChangeRowGroupService/UsersChangeRowGroup.service";
import { UsersChangeRowRolesService } from "service/shared/others/UsersChangeRowRolesService/usersChangeRowRoles.service";
import { UsersChangeRowService } from "service/shared/others/UsersChangeRowService/UsersChangeRow.service";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { usersDetailsPageState } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.state";
import { replacePath } from "utils/commonExtend/replacePath";

class Controller {
  public readonly state = usersDetailsPageState;

  public readonly closeCallBack = (): void => {
    this.state.open.next(false);
  };

  public readonly onClickApplyButton = async (): Promise<void> => {
    try {
      await client.post(apiPaths.invitationCreateForce, {
        email: this.state.emailService.state.value.value,
        invitedCredentialsId: this.state.user.value?.id,
      });
      this.state.open.next(false);
      toastContainerService.controller.createSuccessToast(t("usersDetailsPageService.passwordWasReset"));
    } catch (e) {
      console.log(e);
    }
  };

  public readonly onClickResetBackButton = (): void => {
    this.state.open.next(false);
  };

  public readonly onClickResetPasswordButton = (): void => {
    this.state.open.next(true);
  };

  public readonly onClickUnBlockUserButton = async (): Promise<void> => {
    try {
      this.state.loaded.next(false);
      await client.get(replacePath(apiPaths.unBlockUserById, { userId: this.state.userId }));
      await this.setUser();
    } catch (e) {
      console.log(e);
      this.state.loaded.next(true);
    }
  };

  public readonly onClickBlockUserButton = async (): Promise<void> => {
    try {
      this.state.loaded.next(false);
      await client.get(replacePath(apiPaths.blockUserById, { userId: this.state.userId }));
      await this.setUser();
      await this.onClickDropSessionButton();
    } catch (e) {
      console.log(e);
      this.state.loaded.next(true);
    }
  };

  public readonly mount = async (userId: string): Promise<void> => {
    this.state.userId = userId;

    const currentUserId = userService.getId();

    this.state.showUserForm.next(currentUserId === userId);

    await this.setUser();
  };

  public readonly produceRoles = (roles: string[]): RolesSelectOptionModel[] => {
    return roles.map((role) => {
      return new RolesSelectOptionModel(role, role, role);
    });
  };

  public readonly unMount = (): void => {
    this.state.loaded.next(false);
  };

  public readonly findSessionInSessionList = async (): Promise<boolean> => {
    const { data } = await client.get(apiPaths.sessionList);
    const sessionList: UserModel[] = data;

    const foundUser = sessionList.find((user) => user.id === this.state.user.value!.id);

    return !!foundUser;
  };

  public readonly onClickDropSessionButton = async (): Promise<void> => {
    try {
      const email = this.state.user.value?.email!;

      const result = await this.findSessionInSessionList();

      if (result) {
        await client.post(replacePath(apiPaths.dropSession, { email }), {});
        toastContainerService.controller.createSuccessToast(t("usersDetailsPageService.sessionWasBreak"));
      } else {
        toastContainerService.controller.createInfoToast(t("usersDetailsPageService.sessionIsNotExist"));
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  private readonly setUser = async (): Promise<void> => {
    const { data } = await client.get(replacePath(apiPaths.userAdminInfoById, { userId: this.state.userId }));
    const user = this.getUserModel(data);
    this.state.user.next(user);

    this.state.nameService = new UsersChangeRowService(user.name, this.nameServiceCallBack);
    this.state.merchantGroupNameService = new UsersChangeRowGroupService(
      new OptionModel(user.merchantGroupName, user.merchantGroupId),
      this.merchantGroupIdServiceCallBack
    );
    this.state.rolesService = new UsersChangeRowRolesService(this.produceRoles(user.roles), this.rolesServiceCallBack);
    this.state.emailService = new UsersChangeRowService(user.email, this.emailServiceCallBack, { type: "email" });
    this.state.phoneNumberService = new UsersChangeRowService(user.phoneNumber, this.phoneNumberServiceCallBack, { type: "phoneNumber" });

    this.state.loaded.next(true);
  };

  private readonly produceCallBack = (callBack: () => Promise<void>) => async (): Promise<void> => {
    this.state.loaded.next(false);
    await callBack();
    await this.setUser();
  };

  public readonly onClickChangePasswordButton = this.produceCallBack(async (): Promise<void> => {
    const currentPassword = this.state.currentPasswordService.state.value.value;
    const newPassword = this.state.newPasswordService.state.value.value;

    await client.post(apiPaths.userPasswordChange, {
      currentPassword,
      newPassword,
    });
  });

  private readonly nameServiceCallBack = this.produceCallBack(async (): Promise<void> => {
    await client.post(replacePath(apiPaths.userAdminUpdateById, { userId: this.state.userId }), { name: this.state.nameService.state.value.value });
  });

  private readonly merchantGroupIdServiceCallBack = this.produceCallBack(async (): Promise<void> => {
    await client.post(replacePath(apiPaths.userAdminUpdateById, { userId: this.state.userId }), {
      merchantGroupId: +this.state.merchantGroupNameService.state.value.value.value,
    });
  });

  private readonly rolesServiceCallBack = this.produceCallBack(async (): Promise<void> => {
    await client.post(replacePath(apiPaths.userAdminUpdateById, { userId: this.state.userId }), {
      roles: this.state.rolesService.state.selectedOptions.value.map((element) => element.title),
    });
  });

  private readonly emailServiceCallBack = this.produceCallBack(async (): Promise<void> => {
    try {
      await client.post(replacePath(apiPaths.userAdminUpdateById, { userId: this.state.userId }), {
        email: this.state.emailService.state.value.value,
      });
    } catch (e) {
      console.log("e", e);
    }
  });

  private readonly phoneNumberServiceCallBack = this.produceCallBack(async (): Promise<void> => {
    try {
      await client.post(replacePath(apiPaths.userAdminUpdateById, { userId: this.state.userId }), {
        phoneNumber: this.state.phoneNumberService.state.value.value,
      });
    } catch (e) {
      console.log("e", e);
    }
  });

  private readonly getUserModel = (data: any): UserModel => {
    return new UserModel(data);
  };
}

export const usersDetailsPageController = new Controller();
