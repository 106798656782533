import { createComponent } from "utils/libExtend/createComponent";

import { OpenUserFormButton } from "./OpenUserFormButton/OpenUserFormButton.component";
import { UsersInfoPageLayoutProps } from "./UsersInfoPageLayout.props";
import { SUsersInfoPageLayout } from "./UsersInfoPageLayoutStyled/SUsersInfoPageLayout.styled";
import { SUsersInfoPageLayoutContainer } from "./UsersInfoPageLayoutStyled/SUsersInfoPageLayoutContainer.styled";
import { SUsersInfoPageLayoutContent } from "./UsersInfoPageLayoutStyled/SUsersInfoPageLayoutContent.styled";

export const UsersInfoPageLayout = createComponent<UsersInfoPageLayoutProps>("UsersInfoPageLayout", (props) => {
  const { children } = props;

  return (
    <SUsersInfoPageLayout>
      <OpenUserFormButton />

      <SUsersInfoPageLayoutContent>
        <SUsersInfoPageLayoutContainer>{children}</SUsersInfoPageLayoutContainer>
      </SUsersInfoPageLayoutContent>
    </SUsersInfoPageLayout>
  );
});
