import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { STerminalsFilterSelect } from "./TerminalsFilterSelectStyled/STerminalsFilterSelect.styled";
import { STerminalsFilterSelectInput } from "./TerminalsFilterSelectStyled/STerminalsFilterSelectInput.styled";
import { STerminalsFilterSelectTitle } from "./TerminalsFilterSelectStyled/STerminalsFilterSelectTitle.styled";

export const TerminalsFilterSelect = createComponent("TerminalsFilterSelect", () => {
  const { t } = useTranslation();
  const filterValue = useDefaultObservableString(terminalsPageService.state.filterValue);

  return (
    <STerminalsFilterSelect>
      <STerminalsFilterSelectTitle>{t("terminalsPage.terminalsFilterSelectTitle")}</STerminalsFilterSelectTitle>
      <STerminalsFilterSelectInput value={filterValue} onChange={terminalsPageService.controller.onChangeFilter} />
    </STerminalsFilterSelect>
  );
});
