import { resetPasswordFormController } from "service/shared/singletones/resetPasswordFormService/resetPasswordForm.controller";
import { resetPasswordFormState } from "service/shared/singletones/resetPasswordFormService/resetPasswordForm.state";

class Service {
  public readonly state = resetPasswordFormState;

  public readonly controller = resetPasswordFormController;
}

export const resetPasswordFormService = new Service();
