import { tableHeaderInitialCell } from "config/filterConfig/tableHeaderInitialCell";
import { HeaderCellModel } from "model/HeaderCell.model";
import { TransferCancel, TransferDetailsModel } from "model/TransferDetails.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { BehaviorSubjectTransferDetailsModelNull } from "observables/TransferDetailsModelNullObservable";
import { TableService } from "service/shared/others/TableService/Table.service";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class State {
  public readonly loaded: BehaviorSubjectBoolean;

  public readonly cancelsIsEmpty: BehaviorSubjectBoolean;

  public readonly tableService: BehaviorSubjectTableService;

  public readonly transfer: BehaviorSubjectTransferDetailsModelNull;

  public transferId!: string;

  private readonly initialHeaderCellList: HeaderCellModel[];

  public constructor() {
    this.transfer = new BehaviorSubjectTransferDetailsModelNull(null);
    this.loaded = new BehaviorSubjectBoolean(false);
    this.cancelsIsEmpty = setCombinedBehaviorSubject(this.setCancelsIsEmpty, this.transfer);
    this.initialHeaderCellList = tableHeaderInitialCell.transferDetailsPage();
    this.tableService = setCombinedBehaviorSubject(this.setTableService, this.transfer);
  }

  private readonly setCancelsIsEmpty = (transfer: TransferDetailsModel | null): boolean => {
    const cancels: TransferCancel[] = transfer?.cancels || [];
    return cancels.length === 0;
  };

  private readonly setTableService = (transfer: TransferDetailsModel | null): TableService => {
    const cancels: TransferCancel[] = transfer?.cancels || [];
    return new TableService(cancels, this.initialHeaderCellList);
  };
}

export const transferDetailsPageState = new State();
