import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { ResetPasswordInputService } from "service/shared/others/ResetPasswordInputService/ResetPasswordInput.service";

class State {
  public readonly isLoaded: BehaviorSubjectBoolean;

  public readonly passwordService: ResetPasswordInputService;

  public readonly confirmPasswordService: ResetPasswordInputService;

  public token!: string;

  public constructor() {
    this.isLoaded = new BehaviorSubjectBoolean(false);
    this.passwordService = new ResetPasswordInputService({ required: true });
    this.confirmPasswordService = new ResetPasswordInputService({ required: true });
  }
}

export const resetPasswordFormState = new State();
